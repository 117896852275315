import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";
import { setGlobal, GlobalKey, getGlobal } from "../utils/GlobalUtil";
import { fetchAPI } from "../utils/APIUtil";
import Truncate from "react-truncate";
import {
  SeminarLecture,
  SeminarLectureAttribute,
} from "../models/Model.Seminar";
import SeminarSelect from "./component/seminar/SeminarSelect";
import SeminarEdit from "./component/seminar/SeminarEdit";
import IngredientSelect from "./component/IngredientSelect";
import { Ingredient } from "../models/Model.Medicine";
import { UIPopupType } from "../store/ui/types";
import { STATUS_CODES } from "http";
import SeminarStatistics from "./component/seminar/SeminarStatisticsView";
import SeminarCategorySelect from "./component/seminar/SeminarCategorySelect";
import SeminarSeriesManage from "./component/seminar/SeminarSeriesManage";
import BizCouponSelect from "./component/biz/BizCouponSelect";
import {
  BizUserInfo,
  CouponInfo,
  EMPTY_COUPON,
  MOU_COUPON_30,
} from "../models/Mode.Biz";
import BizUserSelect from "./component/biz/BizUserSelect";
import BizCouponEdit from "./component/biz/BizCouponEdit";
import { UserInfo, UserLevel } from "../models/Model.User";
import UserSelect from "./component/user/UserSelect";
import AllInOnePartnerSelect from "./component/allinone/AllInOnePartnerSelect";

const AdminAPI = {
  GET_LIST: {
    method: "POST",
    path: "/admin/seminar/lectures",
    contentType: "application/json",
  },
};

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

export enum BizCouponManageViewOption {
  // LIST = 0x01,
  // NEWCOUPON = 0x02,
  NONE = 0x00,
  BIZ_USERS = 0x100,
  USERS = 0x200,
  MAIN_VIEW_MASK = 0xff00,
}

type State = {
  viewOption: BizCouponManageViewOption;
  selectedUser: any;
  selectedCoupon: any;
};

class BizCouponManage extends Component<Props, State> {
  userList = null;
  couponList = null;
  editor = null;
  state = {
    selectedUser: null,
    selectedCoupon: null,
    viewOption: BizCouponManageViewOption.NONE,
  };

  constructor(props: Props) {
    super(props);
    document.title = "구인구직 쿠폰관리";
  }

  componentDidMount() {}

  // onSelect = (lecture: SeminarLecture) => {
  //   log(LogLevel.UI_EVENT, "onSelect", lecture);
  //   this.setState({selected:lecture});
  // }

  // onChange = (lecture: SeminarLecture) => {
  //   log(LogLevel.UI_EVENT, "onChange", lecture);
  //   if(this.lecturesList)
  //     this.lecturesList.refresh();
  // }

  // onPreview = (lecture: SeminarLecture) => {
  //   log(LogLevel.UI_EVENT, "onPreview", lecture);
  //   this.setState({preview:lecture, previewId:lecture.id, viewOption:SeminarManageSecondViewOption.PREVIEW});
  // }

  // onPreviewById = (lectureId: number) => {
  //   log(LogLevel.UI_EVENT, "onPreviewId", lectureId);
  //   this.setState({preview:null, previewId:lectureId, viewOption:SeminarManageSecondViewOption.PREVIEW});
  // }

  // onSearchIngredient = () => {
  //   log(LogLevel.UI_EVENT, "onSearchIngredient");
  //   this.setState({viewOption:SeminarManageSecondViewOption.INGREDIENT});
  // }

  // onSelectIngredient = (ingredient:Ingredient) => {
  //   log(LogLevel.UI_EVENT, "onSelectIngredient", this.lectureEditor, ingredient);
  //   if(this.lectureEditor)
  //     this.lectureEditor.addIngredient(ingredient);
  // }

  // onSearchCategories = () => {
  //   log(LogLevel.UI_EVENT, "onSearchCategories");
  //   this.setState({viewOption:SeminarManageSecondViewOption.CATEGORY});
  // }

  // onSelectCategory = (category:any) => {
  //   log(LogLevel.UI_EVENT, "onSelectCategory", this.lectureEditor, category);
  //   if(this.lectureEditor)
  //     this.lectureEditor.addCategory(category);
  // }

  onSelectCoupon = (coupon: CouponInfo) => {
    this.setState({ selectedCoupon: coupon });
  };
  onSelectBizUser = (user: BizUserInfo) => {
    if (!this.state.selectedCoupon && this.couponList) {
      this.couponList.onSelectBizUser(user);
    } else if (this.state.selectedCoupon && this.editor) {
      this.editor.onSelectBizUser(user);
    }

    this.setState({ selectedUser: user });
  };

  onClickSelectBizUser = () => {
    let viewOption = this.state.viewOption;
    if (viewOption & BizCouponManageViewOption.BIZ_USERS) {
      viewOption = viewOption & ~BizCouponManageViewOption.BIZ_USERS;
    } else {
      viewOption = viewOption | BizCouponManageViewOption.BIZ_USERS;
    }
    log(
      LogLevel.UI_EVENT,
      "BizCouponManage:onClickSelectUser",
      this.state.viewOption,
      viewOption,
      viewOption & ~BizCouponManageViewOption.BIZ_USERS,
      viewOption | BizCouponManageViewOption.BIZ_USERS
    );

    this.setState({ viewOption });
  };

  onCloseSelectBizUser = () => {
    let viewOption =
      this.state.viewOption & ~BizCouponManageViewOption.BIZ_USERS;
    this.setState({ viewOption });
  };

  onSelectUser = (user: UserInfo) => {
    if (!this.state.selectedCoupon && this.couponList) {
      this.couponList.onSelectUser(user);
    } else if (this.state.selectedCoupon && this.editor) {
      this.editor.onSelectUser(user);
    }

    this.setState({ selectedUser: user });
  };

  onClickSelectUser = () => {
    let viewOption = this.state.viewOption;
    if (viewOption & BizCouponManageViewOption.USERS) {
      viewOption = viewOption & ~BizCouponManageViewOption.USERS;
    } else {
      viewOption = viewOption | BizCouponManageViewOption.USERS;
    }
    log(
      LogLevel.UI_EVENT,
      "BizCouponManage:onClickSelectUser",
      this.state.viewOption,
      viewOption,
      viewOption & ~BizCouponManageViewOption.USERS,
      viewOption | BizCouponManageViewOption.USERS
    );

    this.setState({ viewOption });
  };

  onCloseSelectUser = () => {
    let viewOption = this.state.viewOption & ~BizCouponManageViewOption.USERS;
    this.setState({ viewOption });
  };

  onStartCouponEdit = () => {};

  onDoneCouponEdit = () => {
    if (this.couponList) this.couponList.refresh();
    this.setState({ selectedCoupon: null });
  };

  onCancelCouponEdit = () => {
    this.setState({ selectedCoupon: null });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "BizCouponManage.render", this.props);
    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">구인구직 쿠폰관리</IonText>
          <IonButtons slot="end">
            <IonButton onClick={this.onClickSelectBizUser}>
              법인회원보기
            </IonButton>
            <IonButton onClick={this.onClickSelectUser}>회원보기</IonButton>
          </IonButtons>
        </IonHeader>

        <IonContent>
          <div className="admin-main-container">
            {this.state.selectedCoupon ? (
              <div
                id="admin-sub-conainer"
                className={
                  "admin-container" +
                  (this.state.selectedCoupon ? "" : " admin-container-hidden")
                }
              >
                <BizCouponEdit
                  ref={(ref) => {
                    this.editor = ref;
                  }}
                  onDone={this.onDoneCouponEdit}
                  onCancel={this.onCancelCouponEdit}
                  coupon={this.state.selectedCoupon}
                />
              </div>
            ) : (
              <div className="admin-container">
                <BizCouponSelect
                  ref={(ref) => {
                    this.couponList = ref;
                  }}
                  onSelect={this.onSelectCoupon}
                />
              </div>
            )}
            <div
              id="admin-sub-conainer"
              className={
                "admin-container" +
                (this.state.viewOption & BizCouponManageViewOption.BIZ_USERS
                  ? ""
                  : " admin-container-hidden")
              }
            >
              <AllInOnePartnerSelect
                showPartners={false}
                noActions={true}
                onDone={this.onSelectBizUser}
                onClose={this.onCloseSelectBizUser}
              />
            </div>
            <div
              id="admin-sub-conainer"
              className={
                "admin-container" +
                (this.state.viewOption & BizCouponManageViewOption.USERS
                  ? ""
                  : " admin-container-hidden")
              }
            >
              <UserSelect
                ref={(ref) => {
                  this.userList = ref;
                }}
                onDone={this.onSelectUser}
                level={UserLevel.NORMAL_USER}
                onClose={this.onCloseSelectUser}
              />
            </div>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  lectures: state.seminar.lectures,
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  filePath: state.board.filePath,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadLecture: (lectureId: number) =>
    actions.seminar.getSeminarLecture(lectureId),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BizCouponManage)
);
