import React, { Component } from "react";
import "./../../Admin.scss";
import "./../../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  AllInOnePartnerLevel,
  CompanyTypeName,
  PARTNERS_TEMPLATE,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import ProfileImage from "../../../components/ProfileImage";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import StringUtil from "../../../utils/StringUtil";
import { RootState } from "../../../store";
import { UIPopupType } from "../../../store/ui/types";
import { connect } from "react-redux";
import ToggleSwitch from "../../../component/atom/ToggleSwitch";
import {
  BizUserInfo,
  CASH_COUPON,
  EMPTY_COUPON,
} from "../../../models/Mode.Biz";
import { COLOR_SYSTEM } from "../../../components/design/design-system";
import { Absolute, Flex, Static } from "../../../components/atoms/Layout";
import styled from "styled-components";
import Text from "../../../components/atoms/Text";
import ReactHtmlParser from "react-html-parser";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import DownloadUtil from "../../../utils/DownloadUtil";
import Tag from "../../../components/atoms/Tag";
import ToggleButton from "../../../components/atoms/ToggleButton/ToggleButton";
import { stat } from "fs";
import Button from "../../../components/atoms/Button";
import Input from "../../../components/atoms/Input";
import Icon from "../../../components/atoms/Icon";
import {
  MessageType,
  SCHEDULE_EMAIL_TEMPLATE,
  ScheduleMessageInfo,
  ScheduleState,
} from "../../../models/Model.User.Message";
import Badge from "../../../components/atoms/Badge";

const AdminAPI = {
  EMAIL_LIST: {
    method: "GET",
    path: "/admin/notification/messages",
    contentType: "application/json",
  },
};

const EmailStateColor = {
  [ScheduleState.NONE]: "Gray",
  [ScheduleState.SCHEDULED]: "Yellow",
  [ScheduleState.WAITING]: "Yellow",
  [ScheduleState.SENDING]: "Green",
  [ScheduleState.FINISHED]: "Blue",
  [ScheduleState.STOPPED]: "Red",
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onSelect: (email: ScheduleMessageInfo) => void;
    onClose?: () => void;
    email: ScheduleMessageInfo;
    type: MessageType;
  };

type State = {
  emails: ScheduleMessageInfo[];
  finished: boolean;
  count: number;
  keyword: string;
};

class EmailSelect extends Component<Props, State> {
  loading = false;

  state = {
    emails: [],
    finished: false,
    count: 100,
    keyword: "",
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  refresh = (email?: ScheduleMessageInfo) => {
    if (email) {
      if (email.id == 0 || email.type != this.props.type) return;
      const index = this.state.emails.findIndex(
        (item: ScheduleMessageInfo) => item.id === email.id
      );
      if (index >= 0) {
        let newQuizs = [...this.state.emails];
        newQuizs[index] = email;

        this.setState({ emails: newQuizs });
      } else {
        const emails = [email, ...this.state.emails];

        this.setState({ emails });
      }
    } else {
      this.fetchData();
    }
  };

  fetchData = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:EmailSelect:fetchData");
    this.loading = true;

    let parameter: any = {
      count: this.state.count,
      start: 0,
      type: this.props.type,
    };

    let finished = false;

    fetchAPI(
      AdminAPI.EMAIL_LIST,
      "",
      parameter,
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      if (result && !result.error) {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:EmailSelect:fetchData result",
          result
        );
        if (result.data && result.data.length < this.state.count)
          finished = true;
        const emails = result.data;
        this.setState({ emails, finished });
        this.loading = false;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:EmailSelect:fetchData result",
          result
        );
        finished = true;
        this.setState({ emails: [], finished });
      }
    });
  };

  fetchDataMore = async () => {
    log(
      LogLevel.UI_DATA_LOAD,
      "Admin:EmailSelect:fetchDataMore",
      this.state.finished
    );
    this.loading = true;

    let parameter: any = {
      count: this.state.count,
      start: this.state.emails.length,
      type: this.props.type,
    };

    let finished = false;

    if (!this.state.finished) {
      fetchAPI(
        AdminAPI.EMAIL_LIST,
        "",
        parameter,
        null,
        getGlobal(GlobalKey.TOKEN)
      ).then((result) => {
        if (result && !result.error) {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:EmailSelect:fetchData result",
            result
          );

          if (result.data && result.data.length < this.state.count)
            finished = true;

          const emails = [...this.state.emails, ...result.data];
          this.setState({
            emails,

            finished,
          });
          this.loading = false;
        } else {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:EmailSelect:fetchData result",
            result
          );
          finished = true;
          this.setState({
            finished,
          });
          this.loading = false;
        }
      });
    }
  };

  clearSearch = (key: string) => () => {
    let newState = {};
    if (key == "searchContentId") newState = { searchContentId: 0 };
    this.setState(newState);
  };

  onSelect = (email: ScheduleMessageInfo) => {
    log(LogLevel.UI_EVENT, "Admin:EmailSelect:onSelect", email);
    this.props.onSelect(email);
  };

  endAtStyle = (endAt: string) => {
    const endDate = new Date(endAt);
    const currentDate = new Date();

    const diffInTime = endDate.getTime() - currentDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    log(
      LogLevel.ALL,
      "AllInOneOrderList:diffInDays",
      diffInDays,
      endAt,
      currentDate,
      endDate,
      diffInTime
    );

    if (diffInDays < 0) {
      return {
        backgroundColor: COLOR_SYSTEM.get("Red")[50],
      };
    }
    return {};
  };

  render() {
    log(LogLevel.UI_DATA_LOAD, "Admin:EmailSelect:render", this.state.emails);
    return (
      <div className="admin-container">
        {this.props.onClose && (
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  class="common-header-toolbar-button"
                  onClick={this.props.onClose}
                >
                  <IonIcon name="arrow-back" mode="ios" />
                </IonButton>
                <IonLabel
                  class="common-header-title"
                  onClick={this.props.onClose}
                >
                  이메일
                  {this.props.type == MessageType.EMAIL_TEMPLATE
                    ? " 템플릿 "
                    : " "}
                  목록
                </IonLabel>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <div className="common-container common-flex-row common-flex-align-center">
          검색어
          <Input
            style={{ width: "50%" }}
            placeholder="검색어를 입력하세요"
            value={this.state.keyword}
            onChange={(e) => {
              this.setState({ keyword: e.target.value });
            }}
            onClear={() => {
              this.setState({ keyword: "" });
            }}
          />
        </div>
        <Flex gap="8px" customStyle={{ marginTop: "8px" }}>
          <Button
            onClick={() => {
              this.refresh();
            }}
            size={"Medium"}
            type={"Text"}
            variant={"Contained"}
            color={"Primary"}
          >
            새로불러오기
          </Button>
          <Button
            onClick={() => {
              this.onSelect({ ...SCHEDULE_EMAIL_TEMPLATE });
            }}
            size={"Medium"}
            type={"Text"}
            variant={"Contained"}
            color={"Negative"}
          >
            새 탬플릿
          </Button>
        </Flex>

        <div className="common-container">
          {this.state.emails.length > 0 && !this.state.finished && (
            <IonButton
              color="primary"
              onClick={this.fetchDataMore}
              style={{ width: "100%" }}
            >
              더 불러오기
            </IonButton>
          )}
          <IonList>
            {this.state.emails.map(
              (item: ScheduleMessageInfo, index: number) => {
                return (
                  <EmailWrapper
                    key={index.toString()}
                    onClick={() => this.onSelect(item)}
                    state={item.state}
                  >
                    <Flex
                      gap="8px"
                      direction="column"
                      customStyle={{ width: "100%" }}
                    >
                      <Flex alignItems="center" gap="5px">
                        <Tag
                          variant="Tinted"
                          color={EmailStateColor[item.state]}
                        >
                          {ScheduleState[item.state]}
                        </Tag>
                        <Text
                          textType="CaptionBold"
                          color={COLOR_SYSTEM.get("Blue")[700]}
                        >
                          ({item.id} -{" "}
                          {getDateStringFromToday({
                            date: item.createdAt,
                            showTime: true,
                          })}
                          )
                        </Text>
                      </Flex>
                      <Text
                        textType="Body1SemiBold"
                        color={COLOR_SYSTEM.get("Gray")[900]}
                      >
                        {item.name}
                      </Text>
                    </Flex>
                  </EmailWrapper>
                );
              }
            )}
          </IonList>
          {this.state.emails.length > 0 && !this.state.finished && (
            <IonButton
              color="primary"
              onClick={this.fetchDataMore}
              style={{ width: "100%" }}
            >
              더 불러오기
            </IonButton>
          )}
        </div>
      </div>
    );
  }
}

const EmailWrapper = styled.div<{}>`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${COLOR_SYSTEM.get("Gray")[200]};
  margin-bottom: 8px;
  position: relative;
`;

const mapStateToProps = (state: RootState) => ({
  // filePath: state.board.filePath,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(EmailSelect);
