import React, { Component } from "react";
import "./RegisterStage12.css";
import "./RegisterStage2.css";
import "./RegisterStage.css";
import { withRouter, RouteComponentProps } from "react-router";
import { IonButton, IonInput, IonIcon, IonContent } from "@ionic/react";
import DynamicChecker, { CheckStatus } from "./../DynamicChecker";
import { fetchAPI } from "./../../utils/APIUtil";
import * as API from "./../../API.json";
import { UserInfo } from "../../models/Model.User";
import { getGlobal, GlobalKey } from "./../../utils/GlobalUtil";
import { log, LogLevel } from "../../utils/LogUtil";
import { timerStart, TimerId } from "../../utils/TimeUtil";
import { UIPopupType } from "../../store/ui/types";
import { RootState } from "../../store";
import { connect } from "react-redux";
import DynamicCheckerInput from "../DynamicCheckerInput";
import StringUtil from "../../utils/StringUtil";

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    private: boolean;
    user: any;
    onDone: (data: any) => void;
    onBack: (data: any) => void;
  };

type State = {
  emailCheckStatus: number;
  emailWarning: string;
  licenseCheckStatus: number;
  email: string;
  workPlace: string;
  license: string;
  licenseImageBase64: any;
  licenseUrl: string;
};

const windowAny: any = window;

class RegisterStage12 extends Component<Props, State> {
  contentScroll = null;

  constructor(props) {
    super(props);
    this.state = {
      emailCheckStatus: CheckStatus.UNCHECKED,
      emailWarning: "",
      licenseCheckStatus: CheckStatus.UNCHECKED,
      email: this.props.user.email,
      workPlace: this.props.user.workPlaceName || "",
      license: this.props.user.license,
      licenseUrl: this.props.user.licenseUrl,
      licenseImageBase64: null,
    };
    this.canPass = this.canPass.bind(this);

    if (this.state.email) this.validateEmail(this.state.email);
    if (this.state.license) this.validateLicense();
  }

  canPass() {
    return (
      this.state.license &&
      this.state.workPlace &&
      (this.state.licenseUrl || this.state.licenseImageBase64) &&
      this.state.emailCheckStatus == CheckStatus.CHECKED
    );
  }

  onDone = () => {
    if (!this.state.license) {
      this.props.toast.show("학번을 확인해주세요.");
      return;
    }
    if (!this.state.workPlace) {
      this.props.toast.show("학교명을 확인해주세요.");
      return;
    }
    if (this.state.emailCheckStatus != CheckStatus.CHECKED) {
      this.props.toast.show("이메일을 확인해주세요.");
      return;
    }
    if (!this.state.licenseUrl && !this.state.licenseImageBase64) {
      this.props.toast.show("학생증사진을 추가하셔야합니다.");
      return;
    }

    let rvalue: UserInfo = {
      email: this.state.email,
      workPlaceName: this.state.workPlace,
      license: this.state.license,
      licenseImageBase64: this.state.licenseImageBase64,
      licenseUrl: this.state.licenseUrl,
    };
    this.props.onDone(rvalue);
  };

  onBack = () => {
    let rvalue: UserInfo = {
      email: this.state.email,
      workPlaceName: this.state.workPlace,
      license: this.state.license,
      licenseImageBase64: this.state.licenseImageBase64,
      licenseUrl: this.state.licenseUrl,
    };
    this.props.onBack(rvalue);
  };

  validateEmail = (email: string) => {
    // let body = {
    //   value: this.state.email,
    // };
    // fetchAPI(API.USER_FIELD_VALIDATE_OPEN, "email", null, body)
    //   .then((result) => {
    //     if (result && !result.error && result.data.value == this.state.email) {
    //       log(LogLevel.UI_ACTION, "RegisterStage12: pass", result);
    //       this.setState({ emailCheckStatus: CheckStatus.CHECKED });
    //     } else if (
    //       result &&
    //       result.error &&
    //       result.data.value == this.state.email
    //     ) {
    //       log(LogLevel.UI_ACTION, "RegisterStage12: duplicated", result);
    //       this.setState({ emailCheckStatus: CheckStatus.WARNING });
    //     } else {
    //       log(LogLevel.UI_ACTION, "RegisterStage12: validateEmail", result);
    //     }
    //   })
    //   .catch((e) => {
    //     log(LogLevel.UI_ACTION, e);
    //   });

    log(
      LogLevel.UI_DATA_LOAD,
      "RegisterStage12:validateEmail",
      this.state.email,
      this.state.emailCheckStatus
    );
    if (!email) {
      this.setState({
        emailCheckStatus: CheckStatus.UNCHECKED,
        emailWarning: "",
      });
      return;
    } else if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      this.setState({
        emailCheckStatus: CheckStatus.CHECKING,
        emailWarning: "",
      });
      if (this.props.private) {
        fetchAPI(
          API.USER_FIELD_VALIDATE,
          "email",
          null,
          { value: email },
          getGlobal(GlobalKey.TOKEN)
        )
          .then((result) => this.onEmailChecked(result))
          .catch((e) => {
            log(LogLevel.UI_EXCEPTION, e);
          });
      } else {
        fetchAPI(API.USER_FIELD_VALIDATE_OPEN, "email", null, { value: email })
          .then((result) => this.onEmailChecked(result))
          .catch((e) => {
            log(LogLevel.UI_EXCEPTION, e);
          });
      }
    } else {
      if (email.length > 10) {
        this.setState({ emailWarning: "이메일주소가 유효하지 않습니다." });
      }
      this.setState({ emailCheckStatus: CheckStatus.WARNING });
    }
  };

  onEmailChange = (e) => {
    // log(LogLevel.NONE, e);
    this.setState({
      email: e.target.value,
      emailCheckStatus: CheckStatus.CHECKING,
    });
    this.validateEmail(e.target.value);
  };

  onEmailChecked = (result) => {
    if (result && !result.error && result.data.value == this.state.email) {
      this.setState({
        emailCheckStatus: CheckStatus.CHECKED,
      });
    } else if (
      result &&
      result.error &&
      result.data.value == this.state.email
    ) {
      this.setState({
        emailCheckStatus: CheckStatus.WARNING,
        emailWarning: "이미 사용중인 이메일주소입니다.",
      });
    } else {
      log(LogLevel.UI_DATA_LOAD, result);
    }
    log(
      LogLevel.UI_DATA_LOAD,
      "RegisterStage2:onEmailChecked",
      result,
      this.state.email,
      this.state.emailCheckStatus
    );
  };

  validateLicense = () => {
    if (this.state.license)
      this.setState({ licenseCheckStatus: CheckStatus.CHECKED });
    else this.setState({ licenseCheckStatus: CheckStatus.UNCHECKED });
  };

  onLicenseChange = (e: CustomEvent) => {
    log(LogLevel.NONE, e);
    this.setState({ license: e.detail.value });
    this.validateLicense();
  };

  onClickCamera = () => {
    log(LogLevel.UI_ACTION, "RegisterStage12:onClickCamera");
    let os = getGlobal(GlobalKey.OS);
    if (!os || os == "browser") return;

    if (
      windowAny.navigator &&
      windowAny.navigator.camera &&
      windowAny.navigator.camera.getPicture
    ) {
      windowAny.navigator.camera.getPicture(
        this.onCameraSuccess,
        this.onCameraFail,
        {
          quality: 80,
          destinationType: windowAny.Camera.DestinationType.DATA_URL,
          encodingType: windowAny.Camera.EncodingType.JPEG,
          mediaType: windowAny.Camera.MediaType.PICTURE,
          correctOrientation: true,
          targetWidth: 1920,
          targetHeight: 1920,
        }
      );
    }
  };

  onCameraSuccess = (imageURI) => {
    this.setState({ licenseImageBase64: "data:image/jpeg;base64," + imageURI });
    setTimeout(() => {
      this.contentScroll.scrollToPoint(null, this.contentScroll.clientHeight);
    }, 300);
  };

  onCameraFail = (message) => {
    log(
      LogLevel.UI_EXCEPTION,
      "RegisterStage12::onCameraFail Failed because: ",
      message
    );
  };

  onImageSelected = (e) => {
    // console.dir(e);
    console.dir(e.target.files[0]);
    console.dir(e.target);
    if (e.target.files && e.target.files[0]) {
      var reader = new FileReader();
      reader.onload = this.onImageLoad;
      reader.readAsDataURL(e.target.files[0]);
      // e.target.files.clear();
    }
  };

  onImageLoad = (e) => {
    this.setState({ licenseImageBase64: e.target.result });
    setTimeout(() => {
      this.contentScroll.scrollToPoint(null, this.contentScroll.clientHeight);
    }, 300);
  };

  render() {
    let attachmenet;
    let os = getGlobal(GlobalKey.OS);
    if (os && os != "browser") {
      attachmenet = (
        <div className="register2-upload-button-container">
          <IonButton
            class="register2-upload-button"
            fill="clear"
            onClick={this.onClickCamera}
          >
            <IonIcon class="register2-upload-button-icon" name="camera" />
            <div className="register2-upload-button-text">촬영하기</div>
          </IonButton>
        </div>
      );
    }

    let attachmentViewer;
    if (this.state.licenseImageBase64) {
      attachmentViewer = (
        <div className="register2-upload-viewer-container">
          <img
            className="register2-upload-viewer"
            src={this.state.licenseImageBase64}
          />
        </div>
      );
    } else if (this.props.user.licenseUrl) {
      attachmentViewer = (
        <div className="register2-upload-viewer-container">
          <img
            className="register2-upload-viewer"
            src={StringUtil.convertFilePath(this.props.user.licenseUrl)}
          />
        </div>
      );
    }

    return (
      <IonContent
        class="register-container"
        ref={(ref) => {
          this.contentScroll = ref;
        }}
      >
        <div className="register2-container">
          {/* <RegisterAddressPopup ref={ref => {this.popupLocationInput = ref;}} onSelected={this.onPharmacySelected}/> */}
          <div className="register-progress-container">
            <div className="register-progress-done">1</div>
            <div className="register-progress-done">2</div>
            <div className="register-progress-undone">3</div>
            <div className="register-progress-undone">4</div>
          </div>
          <div className="register2-service-description">
            회원정보는 약사인증, 맞춤형 정보제공을 위한 정보이며, 타회원에게
            공개되지 않습니다.
          </div>
          <div className="register2-body-container">
            <div className="register-title">회원정보</div>
            <div className="register2-item-container">
              <div className="register2-item-name">이름</div>
              <div className="register2-item-value-diabled">
                {this.props.user.name}
              </div>
            </div>
            <div className="register2-item-container">
              <div className="register2-item-name">연락처</div>
              <div className="register2-item-value-diabled">
                {this.props.user.phone}
              </div>
            </div>
            <DynamicCheckerInput
              title="이메일"
              status={this.state.emailCheckStatus}
              warnings={this.state.emailWarning}
              input={this.state.email}
              onChange={this.onEmailChange}
              type="email"
            />
          </div>
          <div className="register2-body-container">
            <div className="register-title">학교정보</div>
            <div className="register2-title-description">
              학교정보는{" "}
              <span className="register2-title-description-highlight">
                약대생 재학생 인증을 위해
              </span>
              로 사용됩니다.
              <br />
              <br />
              약사면허 취득 가능 학과(약학부, 약학과, 제약학과) 6학년만 가입이
              가능합니다.
            </div>
            <div className="register2-item-container">
              <div className="register2-item-name">학교명</div>

              <IonInput
                class="register2-item-input"
                value={this.state.workPlace}
                onIonChange={(e) => {
                  this.setState({ workPlace: e.detail.value });
                }}
              />
            </div>
            <div style={{ height: "20px" }} />
            <div className="register-title">학생정보</div>
            <div className="register2-item-container">
              <div className="register2-item-name">학번</div>
              <IonInput
                class="register2-item-input"
                value={this.state.license}
                onIonChange={this.onLicenseChange}
              />
            </div>
            <div className="register2-title-description">
              학번은 도용방지를 위해 서버에 보관되며{" "}
              <span className="register2-title-description-highlight">
                암호화
              </span>
              되어 식별이 불가합니다.
            </div>
            <div className="register2-item-container">
              <div className="register2-item-name">
                학생증 사진 / 재학증명서
              </div>
            </div>
            <div className="register2-title-description">
              촬영된 학생증/재학증명서 은{" "}
              <span className="register2-title-description-highlight">
                가입승인 즉시 폐기
              </span>
              됩니다.
            </div>
            {attachmentViewer}
            <div className="register2-upload-container">
              {attachmenet}
              <div className="register2-upload-button-container">
                <IonButton class="register2-upload-button" fill="clear">
                  <IonIcon class="register2-upload-button-icon" name="image" />
                  <div className="register2-upload-button-text">첨부하기</div>
                </IonButton>
                <input
                  className="register2-upload-file"
                  type="file"
                  accept="image/*"
                  onChange={this.onImageSelected}
                />
              </div>
            </div>
          </div>
          <div className="register-navigate-container">
            <IonButton color="register-navigate-inactive" onClick={this.onBack}>
              &lt; 이전 단계로
            </IonButton>
            <div className="register-navigate-space" />
            <IonButton
              color={
                this.canPass()
                  ? "register-navigate-active"
                  : "register-navigate-inactive"
              }
              onClick={this.onDone}
            >
              다음 단계로 &gt;
            </IonButton>
          </div>
        </div>
      </IonContent>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterStage12);
