import React, { useCallback, useEffect } from "react";
import {
  Absolute,
  Fixed,
  Flex,
  Relative,
  Static,
  Sticky,
} from "../../atoms/Layout";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";

import styled from "styled-components";
import Button from "../../atoms/Button";
import Separator from "../../atoms/Separator";
import Tag from "../../atoms/Tag";
import Popup from "../../molecules/Popup";
import Icon from "../../atoms/Icon";
import Input from "../../atoms/Input";
import {
  BoardContentQuiz,
  BoardContentQuizResult,
  BoardContentQuizType,
  BoardContentSummary,
  convertToBoardContentQuizAnswers,
} from "../../../models/Model.Board";
import ReactHtmlParser from "react-html-parser";
import Spinner from "../../atoms/Spinner";
import { LogLevel, log } from "../../../utils/LogUtil";
import { SHADOW } from "../../design/effects";

const QustionWrapper = styled.div`
  margin-top: 13px;
`;

const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 24px 0px;

  .option-button {
    margin: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${COLOR_SYSTEM.get("Gray")[800]};
    background: ${COLOR_SYSTEM.get("Gray")[15]};
    border-radius: 16px;
    overflow: hidden;
    transform: translateZ(0);
  }
  button:disabled {
    opacity: 0.3;
  }
  button:disabled.correct-option,
  button[disabled].correct-option,
  button:disabled.correct-answer,
  button[disabled].correct-answer {
    font-weight: 700;
    color: ${COLOR_SYSTEM.get("Skyblue")[400]};
    background: ${COLOR_SYSTEM.get("Skyblue")[10]};
    border: 1px solid ${COLOR_SYSTEM.get("Skyblue")[400]};
    opacity: 1;
  }
  button:disabled.incorrect-option,
  button[disabled].incorrect-option {
    font-weight: 700;
    color: ${COLOR_SYSTEM.get("Red")[300]};
    background: ${COLOR_SYSTEM.get("Red")[10]};
    border: 1px solid ${COLOR_SYSTEM.get("Red")[300]};
    opacity: 1;
  }
  &.list-view {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &.list-view .option-button {
    width: 100%;
    padding: 12px 32px;
    height: 88px;
    font-size: 1rem;
    line-height: 1.3rem;
    box-sizing: border-box;
  }
  &.grid-view {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }
  &.grid-view .option-button {
    padding: 12px; /* 원하는 패딩 값 */
    height: 140px;
    box-sizing: border-box;
  }
  .option-number {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 0.7rem;
    line-height: 20px;
    color: ${COLOR_SYSTEM.get("Gray")[0]};
    background: ${COLOR_SYSTEM.get("Gray")[700]};
    border-radius: 1000px;
    overflow: hidden;
    transform: translateZ(0);
  }

  button:disabled.correct-option .option-number,
  button[disabled].correct-option .option-number {
    color: ${COLOR_SYSTEM.get("Gray")[0]};
    background: ${COLOR_SYSTEM.get("Skyblue")[400]};
  }
  button:disabled.incorrect-option .option-number,
  button[disabled].incorrect-option .option-number {
    color: ${COLOR_SYSTEM.get("Gray")[0]};
    background: ${COLOR_SYSTEM.get("Red")[300]};
  }
`;

const OptionWrapper = styled.button<{ style: React.CSSProperties }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${({ style }) => style}
`;

const OrderDisplay = styled.div<{ style: React.CSSProperties }>`
  margin-top: 40px;
  padding: 24px 28px;
  width: 100%;
  height: 112px;
  display: flex;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  flex-wrap: wrap;
  box-sizing: border-box;
  border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
  border-radius: 16px;
  overflow: hidden;
  transform: translateZ(0);
  span {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    color: ${COLOR_SYSTEM.get("Skyblue")[400]};
    background: ${COLOR_SYSTEM.get("Gray")[0]};
  }
  span::after {
    display: inline-block;
    margin: 0 16px;
    width: 24px; /* SVG 이미지의 너비 */
    height: 24px; /* SVG 이미지의 높이 */
    content: "";
    background-image: url("/images/quiz/chevron_right.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  span:last-child::after {
    content: none;
  }
`;

const FeedbackWrapper = styled.div<{ style: React.CSSProperties }>`
  margin-bottom: 20px;
  padding: 12px 24px 24px;
  width: 100%;
  text-align: left;
  color: ${COLOR_SYSTEM.get("Gray")[0]};
  background: ${COLOR_SYSTEM.get("Gray")[900]};
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
  transform: translateZ(0);

  .feedback-title {
    padding-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    padding-bottom: 12px;
    text-align: left;
    font-weight: 300;
    color: ${COLOR_SYSTEM.get("Gray")[50]};
  }
  .feedback-fail {
    color: ${COLOR_SYSTEM.get("Red")[300]};
  }

  ${({ style }) => style}
`;

export interface BoardQuizViewProps {
  quiz: BoardContentQuiz;
  onDone?: (quiz: BoardContentQuiz) => void;
  onClickReference?: (contentId: number) => void;
  canReset?: boolean;
  onNext?: () => void;
  quizResult?: BoardContentQuizResult;
}

const BoardQuizView: React.FC<BoardQuizViewProps> = ({
  quiz,
  onDone,
  onClickReference,
  canReset,
  onNext,
  quizResult,
}) => {
  const quizAnswers = convertToBoardContentQuizAnswers(quiz, quizResult);
  const [selects, setSelects] = React.useState<
    { text?: string; score?: number; idx?: number }[]
  >(quizAnswers.answers || []);

  const [result, setResult] = React.useState<string>(quizAnswers.result || "");

  log(
    LogLevel.UI_ACTION,
    "BoardQuizView:render",
    quizResult,
    quizAnswers,
    selects,
    result
  );

  useEffect(() => {
    setSelects(quizAnswers.answers || []);
    setResult(quizAnswers.result || "");
  }, [quiz]);

  if (!quiz)
    return (
      <QustionWrapper>
        <Spinner />
      </QustionWrapper>
    );

  const onSelectOption = useCallback(
    (option: { text?: string; score?: number; idx?: number }) => {
      const newSelects = [...selects, option];
      setSelects(newSelects);

      switch (quiz.type) {
        case BoardContentQuizType.CHOICE:
        case BoardContentQuizType.BLANK:
        case BoardContentQuizType.OX:
          checkAnswer(newSelects);
          break;
        case BoardContentQuizType.ORDER:
          break;
      }
    },
    [selects]
  );

  const checkAnswer = (
    selects: { text?: string; score?: number; idx?: number }[]
  ) => {
    switch (quiz.type) {
      case BoardContentQuizType.CHOICE:
      case BoardContentQuizType.BLANK:
      case BoardContentQuizType.OX:
        const select = selects[0];
        const newResult = select.score == 0 ? "fail" : "pass";
        setResult(newResult);
        if (onDone) {
          quiz.answers = selects;
          quiz.result = newResult;
          onDone(quiz);
        }
        break;
      case BoardContentQuizType.ORDER:
        for (let i = 0; i < selects.length - 1; i++) {
          if (selects[i].score > selects[i + 1].score) {
            const resultFail = "fail";
            setResult(resultFail);
            if (onDone) {
              quiz.answers = selects;
              quiz.result = resultFail;
              onDone(quiz);
            }
            return;
          }
        }
        const resultPass = "pass";
        setResult(resultPass);
        if (onDone) {
          quiz.answers = selects;
          quiz.result = resultPass;
          onDone(quiz);
        }
        break;
    }
  };

  const renderOptions = () => {
    if (!quiz.options) return null;

    const optionLengths = quiz.options.map((option) =>
      option.text ? option.text.length : 0
    );
    const longestOptionLength = Math.max(...optionLengths);

    let layoutClass = "grid-view"; // 기본값 설정

    // 하나라도 10글자 이상인 옵션이 있다면 list-view 선택
    if (longestOptionLength >= 15) {
      layoutClass = "list-view";
    } else {
      layoutClass = "grid-view";
    }

    switch (quiz.type) {
      case BoardContentQuizType.CHOICE:
      case BoardContentQuizType.BLANK:
        return (
          <OptionsWrapper className={layoutClass}>
            {quiz.options.map((option, index) => (
              <OptionWrapper
                key={index.toString()}
                disabled={result}
                className={
                  "option-button " +
                  (selects.includes(option) &&
                    (option.score == 1
                      ? " correct-option"
                      : " incorrect-option"))
                }
                onClick={() => onSelectOption(option)}
              >
                <Text textType="Body1Bold" color="inherit">
                  {option.text}
                </Text>
                <Text
                  element="div"
                  textType="Body2SemiBold"
                  color="inherit"
                  className="option-number"
                >
                  {index + 1}
                </Text>
              </OptionWrapper>
            ))}
          </OptionsWrapper>
        );
      case BoardContentQuizType.OX:
        return (
          <OptionsWrapper className={layoutClass}>
            {quiz.options.map((option, index) => {
              if (option.text == "X")
                return (
                  <OptionWrapper
                    key={index.toString()}
                    disabled={result}
                    className={
                      "option-button " +
                      (selects.includes(option) &&
                        (option.score == 1
                          ? " correct-option"
                          : " incorrect-option"))
                    }
                    onClick={() => onSelectOption(option)}
                  >
                    <img
                      src="/images/quiz/icon-X.png"
                      style={{ width: "28px", height: "28px" }}
                    />
                    <Text textType="Body1Bold" color="inherit">
                      아니다
                    </Text>
                  </OptionWrapper>
                );
              return (
                <OptionWrapper
                  key={index.toString()}
                  disabled={result}
                  className={
                    "option-button " +
                    (selects.includes(option) &&
                      (option.score == 1
                        ? " correct-option"
                        : " incorrect-option"))
                  }
                  onClick={() => onSelectOption(option)}
                >
                  <img
                    src="/images/quiz/icon-O.png"
                    style={{ width: "28px", height: "28px" }}
                  />
                  <Text textType="Body1Bold" color="inherit">
                    맞다
                  </Text>
                </OptionWrapper>
              );
            })}
          </OptionsWrapper>
        );
        return;
      case BoardContentQuizType.ORDER:
        return (
          <OptionsWrapper className={layoutClass}>
            {quiz.options.map((option, index) => (
              <OptionWrapper
                key={index.toString()}
                disabled={result || selects.includes(option)}
                className={"option-button"}
                onClick={() => onSelectOption(option)}
              >
                <Text textType="Body1Bold" color="inherit">
                  {!selects.includes(option) && option.text}
                </Text>
              </OptionWrapper>
            ))}
          </OptionsWrapper>
        );
      default:
        return <></>;
    }
  };

  let resultText = "";
  if (result == "pass") {
    resultText = "정답입니다!";
  } else if (result == "fail") {
    if (quiz.type == BoardContentQuizType.ORDER) {
      resultText = "오답입니다.";
    } else {
      for (let i = 0; i < quiz.options.length; i++) {
        if (quiz.options[i].score == 1) {
          if (quiz.type == BoardContentQuizType.OX) {
            resultText = `정답은 "${quiz.options[i].text}" 입니다.`;
          } else resultText = `정답은 "${i + 1}" 입니다.`;
          break;
        }
      }
    }
  }

  return (
    <Flex
      direction="column"
      customStyle={{
        width: "100%",
        flexGrow: 1,
        minHeight: "100%",
        padding: "20px 20px 80px 20px",
      }}
    >
      <Flex alignItems="center" gap="10px">
        <Tag variant={"Outlined"} color={"Black"} size="Medium">
          {quiz.type}
        </Tag>
        {canReset && result && (
          <Button
            size={"Small"}
            type={"Icon"}
            variant={"Outlined"}
            color={"Negative"}
            icon="Refresh"
            onClick={() => {
              setSelects([]);
              setResult("");
            }}
          />
        )}
      </Flex>
      {quiz.question && (
        <QustionWrapper>
          <Text textType="H2" color={COLOR_SYSTEM.get("Gray")[900]}>
            {getSummaryHtml(quiz.question.replace(/\\n|\n/gi, "<br/>"))}
          </Text>
        </QustionWrapper>
      )}

      {quiz.type == BoardContentQuizType.ORDER && (
        <OrderDisplay style={{}}>
          {selects.map((option, index) => (
            <Text
              element="span"
              textType="Body1Bold"
              color="inherit"
              key={index}
            >
              {option.text}
            </Text>
          ))}
        </OrderDisplay>
      )}
      {renderOptions()}
      {quiz.type == BoardContentQuizType.ORDER &&
        selects.length > 0 &&
        !result && (
          <Button
            size={"Large"}
            variant={"Tinted"}
            color={"Secondary"}
            onClick={() => {
              setSelects([]);
            }}
            type={"Text"}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            다시 선택
          </Button>
        )}
      {quiz.type == BoardContentQuizType.ORDER &&
        !result &&
        selects.length == quiz.options.length && (
          <Button
            size={"Large"}
            variant={"Contained"}
            color={"Primary"}
            onClick={() => checkAnswer(selects)}
            type={"Text"}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            선택 완료
          </Button>
        )}
      {result && (
        <FeedbackWrapper>
          <div
            className={
              "feedback-title " + (result == "fail" && "feedback-fail")
            }
          >
            <Text textType="H2" color="inherit">
              {resultText}
            </Text>
            <img
              src={
                result == "pass"
                  ? "/images/quiz/emoji-correct.png"
                  : "/images/quiz/emoji-incorrect.png"
              }
              style={{ width: "60px", height: "60px" }}
            />
          </div>
          <Text element="p" textType="Body1" color="inherit">
            {quiz.comment}
          </Text>
          <Static
            style={{ marginTop: "8px" }}
            onClick={() => onClickReference(quiz.contentId)}
          >
            <Text
              element="p"
              textType="Body2"
              color={COLOR_SYSTEM.get("Blue")[300]}
              style={{ textDecoration: "underline" }}
            >
              자세히 알아보기
            </Text>
          </Static>
        </FeedbackWrapper>
      )}
      {result && !!onNext && (
        <Button
          size={"Large"}
          variant={"Contained"}
          color={"Primary"}
          onClick={onNext}
          type={"Text"}
          style={{ width: "100%", marginBottom: "20px" }}
        >
          다음
        </Button>
      )}
    </Flex>
  );
};

export function getSummaryHtml(text: string) {
  let tempStr = text
    .replace(/<b>/g, "@@@TEMP_OPEN_TAG@@@")
    .replace(/<\/b>/g, "@@@TEMP_CLOSE_TAG@@@");

  // 단계 2: 모든 <를 &lt;로 변경
  tempStr = tempStr.replace(/</g, "&lt;");

  // 단계 3: 임시 문자열을 원래 태그로 복원
  tempStr = tempStr
    .replace(/@@@TEMP_OPEN_TAG@@@/g, "<b>")
    .replace(/@@@TEMP_CLOSE_TAG@@@/g, "</b>")
    .replace(/\n/gi, "<br/>")
    .replace(/\\\\n/gi, "<br/>")
    .replace(/\\n/gi, "<br/>");

  return ReactHtmlParser(tempStr, {});
}
export default BoardQuizView;
