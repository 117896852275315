import React, { useCallback, useEffect, useRef } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  ApplyMethod,
  JobOfferInfo,
  JobOfferName,
  JobOfferSalaryType,
  JobOfferTaxType,
  JobOfferType,
  JobPostRegistrationForm,
  machineNames,
  welfareTagsName,
} from "../../../models/Model.JobPost";
import { UserInfo, UserWorkType } from "../../../models/Model.User";
import { JobBizOfferName } from "../../../types/jobpost";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import {
  convertMultSelectToStr,
  convertWorkdayArrToStr,
  renderJobPostPay,
} from "../../../utils/JobPostUtil";
import { getDateTimeString } from "../../../utils/TimeUtil";
import Badge from "../../atoms/Badge";
import Button from "../../atoms/Button";
import Tag from "../../atoms/Tag";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import KakaoShareButton from "../../molecules/KakaoShareButton";
import {
  DetailMain,
  PharmacyAndMessageContainer,
  Table,
  TableData,
  TableRow,
  WelfareTagsContainer,
} from "./style";
import AdsUtil from "../../../utils/AdsUtil";

interface MainProps {
  preview?: boolean;
  form?: JobPostRegistrationForm;
  post?: JobOfferInfo;
  me?: UserInfo;
  isNew?: boolean;
  isMyPost?: boolean;
  isOwner?: boolean;
  onMapPath?: () => void;
  onMap?: () => void;
  handleOpenAfterTaxPopupClick?: () => void;
  onKakaoShare?: () => Promise<void>;
  onChat?: () => void;
  onClickApplyByMessage(): void;
}

const Main: React.FC<RouteComponentProps & MainProps> = ({
  location,
  preview = false,
  form,
  post,
  me,
  isNew = false,
  isMyPost,
  isOwner,
  onMapPath,
  onMap,
  handleOpenAfterTaxPopupClick,
  onKakaoShare,
  onChat,
  onClickApplyByMessage,
}) => {
  try {
    const isBiz = !form && post.companyType > 0;
    const welfareTags = useRef<string[]>([]);
    const welfareDetail = useRef<string>("");

    const convertMachineData = (machine: string) => {
      const splittedMachine = machine.split("▒");
      const machines = splittedMachine[0].split("#").filter((item) => item);
      if (splittedMachine[1]) machines.push(splittedMachine[1]);

      return `${machines.join(", ")}`;
    };

    const setTagColorByWorkType = (workType: JobOfferType) => {
      switch (workType) {
        case JobOfferType.FULL_TIME:
          return "Blue";
        case JobOfferType.PART_TIME:
          return "Green";
        case JobOfferType.TEMPORARY:
          return "Brown";
        default:
          return "Red";
      }
    };

    const renderMapImage = () => {
      if (!preview && !post.pharmacy) return null;

      const BASE_URL =
        "https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors";
      const { x, y } = preview ? form.position : post.pharmacy.position;

      return (
        <img
          src={`${BASE_URL}?w=600&h=350&center=${x},${y}&level=17&markers=type:d|size:mid|pos:${x}%20${y}&X-NCP-APIGW-API-KEY-ID=4n0skfcl2w`}
          alt="약국 지도"
          onClick={onMap}
        />
      );
    };

    const renderWelfare = () => {
      let welfare: string[] = [];

      if (post.welfare) {
        const welfares = post.welfare.split("▒");
        if (welfares.length > 1) {
          welfare = welfares[0].split("#").filter((item) => item);
          welfareDetail.current = welfares[1] || "";
        } else if (welfares.length > 0) {
          welfareDetail.current = welfares[0] || "";
        }
      }

      welfareTags.current = [
        ...welfare.filter((tag) => tag && !tag.startsWith("-")),
      ].map((item) => item.replace("+", ""));
    };

    const renderApplyMethod = useCallback(
      (applyMethod: ApplyMethod) => {
        return (
          <div className="apply-method__container">
            {(applyMethod === ApplyMethod.ALL ||
              applyMethod === ApplyMethod.Chat ||
              applyMethod === ApplyMethod.None) && (
              <div className="table__apply-method">
                <Text
                  element="pre"
                  textType="Body1"
                  color={COLOR_SYSTEM.get("Gray")[800]}
                >
                  약문약답 채팅 지원
                </Text>
                {!isOwner && !isMyPost && (
                  <Button
                    color="Primary"
                    size="Small"
                    type="Text"
                    variant="Outlined"
                    onClick={onChat}
                    disabled={me.workType == UserWorkType.UNDERGRADUATE}
                    disabledColor={me.workType == UserWorkType.UNDERGRADUATE}
                  >
                    지원하기
                  </Button>
                )}
              </div>
            )}
            {(applyMethod === ApplyMethod.ALL ||
              applyMethod === ApplyMethod.Message ||
              applyMethod === ApplyMethod.None) && (
              <div className="table__apply-method">
                <Text
                  element="pre"
                  textType="Body1"
                  color={COLOR_SYSTEM.get("Gray")[800]}
                >
                  문자 지원
                </Text>
                {!isOwner && !isMyPost && (
                  <Button
                    color="Primary"
                    size="Small"
                    type="Text"
                    variant="Outlined"
                    onClick={onClickApplyByMessage}
                    disabled={me.workType == UserWorkType.UNDERGRADUATE}
                    disabledColor={me.workType == UserWorkType.UNDERGRADUATE}
                  >
                    지원하기
                  </Button>
                )}
              </div>
            )}
          </div>
        );
      },
      [post]
    );

    useEffect(() => {
      if (post) renderWelfare();
    }, [post]);

    return (
      <DetailMain banner={AdsUtil.isAvailable()}>
        <section className="title-container">
          {!isBiz && (
            <div className="detail-title__top">
              {isNew && <Badge color="Red" content="NEW" />}
              <Tag
                color={setTagColorByWorkType(
                  preview ? form.workType : post.workType
                )}
                variant="Tinted"
              >
                {JobOfferName[preview ? form.workType : post.workType]}
              </Tag>
            </div>
          )}
          <Text
            element="h1"
            textType="H2"
            color={COLOR_SYSTEM.get("Gray")[800]}
          >
            {preview ? form.title : post.title}
          </Text>
          {isBiz && (
            <Text textType="Body1Medium" color={COLOR_SYSTEM.get("Gray")[800]}>
              {JobBizOfferName[post.workType]} ({post.contractPeriod}개월)
            </Text>
          )}
          <div className="detail-title__bottom">
            <div className="count-container">
              <Tag color="Gray" icon="EyeFilled" left variant="Tinted">
                {preview ? 0 : post.viewCnt}
              </Tag>
              <Tag color="Gray" icon="BookmarkFilled" left variant="Tinted">
                {preview ? 0 : post.likeCnt}
              </Tag>
            </div>
            {!isBiz && (
              <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[400]}>
                {getDateTimeString(preview ? Date.now() : post.createdAt)}
              </Text>
            )}
          </div>
        </section>
        <section>
          <Table>
            <tbody>
              {isOwner && (
                <TableRow>
                  <TableData rowTitle>
                    <Text
                      textType="InputMedium"
                      color={COLOR_SYSTEM.get("Gray")[500]}
                    >
                      지역
                    </Text>
                  </TableData>
                  <TableData rowDescription colSpan={2}>
                    <div>
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[800]}
                      >
                        {preview ? form.region : post.pharmacy.region}
                      </Text>
                    </div>
                  </TableData>
                </TableRow>
              )}
              <TableRow>
                <TableData rowTitle>
                  <Text
                    textType="InputMedium"
                    color={COLOR_SYSTEM.get("Gray")[500]}
                  >
                    급여
                  </Text>
                </TableData>
                <TableData rowDescription>
                  <div>
                    <Text
                      textType="Body1"
                      color={COLOR_SYSTEM.get("Gray")[800]}
                    >
                      {preview
                        ? renderJobPostPay(
                            form.salaryType,
                            form.taxType,
                            Number(form.salaryRangeLow.replace(/,/g, "")) /
                              10000,
                            Number(form.salaryRangeHigh.replace(/,/g, "")) /
                              10000
                          )
                        : renderJobPostPay(
                            post.salaryType,
                            post.taxType,
                            post.salaryRangeLow,
                            post.salaryRangeHigh
                          )}
                    </Text>
                  </div>
                  {(preview
                    ? form.taxType === JobOfferTaxType.PRE_TAX &&
                      form.salaryType === JobOfferSalaryType.MONTHLY
                    : post.taxType === JobOfferTaxType.PRE_TAX &&
                      post.salaryType === JobOfferSalaryType.MONTHLY) && (
                    <Button
                      color="Secondary"
                      size="Small"
                      variant="Outlined"
                      type="IconWithText"
                      icon="Calculator"
                      left
                      onClick={handleOpenAfterTaxPopupClick}
                      className="after-tax-button"
                    >
                      세후 급여 계산하기
                    </Button>
                  )}
                </TableData>
                <TableData rowAnnotation>
                  {preview
                    ? form.canNegoSalary && (
                        <Tag color="Gray" variant="Tinted">
                          협의 가능
                        </Tag>
                      )
                    : post.canNegoSalary && (
                        <Tag color="Gray" variant="Tinted">
                          협의 가능
                        </Tag>
                      )}
                </TableData>
              </TableRow>
              <TableRow>
                <TableData rowTitle>
                  <Text
                    textType="InputMedium"
                    color={COLOR_SYSTEM.get("Gray")[500]}
                  >
                    근무요일
                  </Text>
                </TableData>
                <TableData rowDescription>
                  <div>
                    <Text
                      textType="Body1"
                      color={COLOR_SYSTEM.get("Gray")[800]}
                    >
                      {preview
                        ? convertWorkdayArrToStr(form.workDayOfWeek)
                        : post.workDayOfWeek}
                    </Text>
                  </div>
                </TableData>
                <TableData rowAnnotation>
                  {preview
                    ? form.canNegoWorkDayOfWeek && (
                        <Tag color="Gray" variant="Tinted">
                          협의 가능
                        </Tag>
                      )
                    : post.canNegoWorkDayOfWeek && (
                        <Tag color="Gray" variant="Tinted">
                          협의 가능
                        </Tag>
                      )}
                </TableData>
              </TableRow>
              <TableRow>
                <TableData rowTitle>
                  <Text
                    textType="InputMedium"
                    color={COLOR_SYSTEM.get("Gray")[500]}
                  >
                    근무시간
                  </Text>
                </TableData>
                <TableData rowDescription colSpan={2}>
                  <div>
                    <Text
                      element="pre"
                      textType="Body1"
                      color={COLOR_SYSTEM.get("Gray")[800]}
                    >
                      {preview ? form.workTime : post.workTime}
                    </Text>
                    {preview
                      ? form.canNegoWorkTime && (
                          <Tag
                            color="Gray"
                            variant="Tinted"
                            className="negotiable-tag"
                          >
                            협의 가능
                          </Tag>
                        )
                      : // 근무시간 협의가능 여부
                        post.canNegoWorkTime && (
                          <Tag
                            color="Gray"
                            variant="Tinted"
                            className="negotiable-tag"
                          >
                            협의 가능
                          </Tag>
                        )}
                  </div>
                </TableData>
              </TableRow>
              <TableRow>
                <TableData rowTitle>
                  <Text
                    textType="InputMedium"
                    color={COLOR_SYSTEM.get("Gray")[500]}
                  >
                    면허
                  </Text>
                </TableData>
                <TableData rowDescription colSpan={2}>
                  <div>
                    {preview ? (
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[800]}
                      >
                        {form.needLicense
                          ? "심평원 등록 필요"
                          : "심평원 등록 불필요"}
                      </Text>
                    ) : (
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[800]}
                      >
                        {post.needLicense
                          ? "심평원 등록 필요"
                          : "심평원 등록 불필요"}
                      </Text>
                    )}
                  </div>
                </TableData>
              </TableRow>
              <TableRow last={!isOwner}>
                <TableData rowTitle>
                  <Text
                    textType="InputMedium"
                    color={COLOR_SYSTEM.get("Gray")[500]}
                  >
                    지원 방법
                  </Text>
                </TableData>
                <TableData rowDescription colSpan={2}>
                  {renderApplyMethod(
                    preview ? form.applyMethod : post.applyMethod
                  )}
                </TableData>
              </TableRow>
            </tbody>
          </Table>
        </section>
        {preview || (!preview && me && (!isOwner || me.id === post.ownerId)) ? (
          <PharmacyAndMessageContainer>
            {renderMapImage()}
            <div className="detail__pharmacy-name">
              <Text
                element="h4"
                textType="H4"
                color={COLOR_SYSTEM.get("Gray")[900]}
              >
                {preview ? form.name : post.pharmacy.name}
              </Text>
              <Button
                color="Secondary"
                icon="CaretRight"
                onClick={() => {
                  onMapPath();
                }}
                right
                size="Small"
                type="IconWithText"
                variant="Outlined"
              >
                교통편 확인하기
              </Button>
            </div>
            <Text
              textType="Body1"
              color={COLOR_SYSTEM.get("Gray")[500]}
              className="detail__pharmacy-address"
            >
              {preview ? form.address : post.pharmacy.address}
            </Text>
            <Text
              element="pre"
              textType="Body1"
              color={COLOR_SYSTEM.get("Gray")[800]}
              className="detail__message"
            >
              {preview ? form.message.trim() : post.message.trim()}
            </Text>
          </PharmacyAndMessageContainer>
        ) : null}
        <section>
          <Table>
            <tbody>
              <TableRow>
                <TableData rowTitle>
                  <Text
                    textType="InputMedium"
                    color={COLOR_SYSTEM.get("Gray")[500]}
                  >
                    전산S/W
                  </Text>
                </TableData>
                <TableData rowDescription colSpan={2}>
                  <div>
                    <Text
                      textType="Body1"
                      color={COLOR_SYSTEM.get("Gray")[800]}
                    >
                      {preview
                        ? form.sw === "기타"
                          ? form.swEtc
                          : form.sw
                        : post.pharmacy.sw}
                    </Text>
                  </div>
                </TableData>
              </TableRow>
              <TableRow>
                <TableData rowTitle>
                  <Text
                    textType="InputMedium"
                    color={COLOR_SYSTEM.get("Gray")[500]}
                  >
                    자동조제기
                  </Text>
                </TableData>
                <TableData rowDescription colSpan={2}>
                  <div>
                    <Text
                      textType="Body1"
                      color={COLOR_SYSTEM.get("Gray")[800]}
                    >
                      {preview ? form.atc : post.pharmacy.atc}
                    </Text>
                  </div>
                </TableData>
              </TableRow>
              <TableRow>
                <TableData rowTitle>
                  <Text
                    textType="InputMedium"
                    color={COLOR_SYSTEM.get("Gray")[500]}
                  >
                    자동화기기
                  </Text>
                </TableData>
                <TableData rowDescription colSpan={2}>
                  <div>
                    <Text
                      textType="Body1"
                      color={COLOR_SYSTEM.get("Gray")[800]}
                    >
                      {preview
                        ? convertMultSelectToStr(
                            form.machine,
                            form.machineEtc,
                            machineNames
                          )
                        : convertMachineData(post.pharmacy.machine)}
                    </Text>
                  </div>
                </TableData>
              </TableRow>
              {!!(
                (preview &&
                  form &&
                  (Number(form.pharmacistCnt) || Number(form.employeeCnt))) ||
                (!preview &&
                  post &&
                  post.pharmacy &&
                  (Number(post.pharmacy.pharmacistCnt) ||
                    Number(post.pharmacy.employeeCnt)))
              ) && (
                <TableRow>
                  <TableData rowTitle>
                    <Text
                      textType="InputMedium"
                      color={COLOR_SYSTEM.get("Gray")[500]}
                    >
                      근무인원
                    </Text>
                  </TableData>
                  <TableData rowDescription colSpan={2}>
                    <div>
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[800]}
                      >
                        {preview
                          ? `약사 ${Number(form.pharmacistCnt) || 0}명 / 직원 ${
                              Number(form.employeeCnt) || 0
                            }명`
                          : `약사 ${
                              Number(post.pharmacy.pharmacistCnt) || 0
                            }명 / 직원 ${
                              Number(post.pharmacy.employeeCnt) || 0
                            }명`}
                      </Text>
                    </div>
                    <div>
                      <Text
                        textType="Caption"
                        color={COLOR_SYSTEM.get("Skyblue")[500]}
                      >
                        함께 일할 근무자 수 입니다.
                      </Text>
                    </div>
                  </TableData>
                </TableRow>
              )}
              <TableRow last>
                <TableData rowTitle>
                  <Text
                    textType="InputMedium"
                    color={COLOR_SYSTEM.get("Gray")[500]}
                  >
                    복리후생
                  </Text>
                </TableData>
                <TableData rowDescription colSpan={2}>
                  <WelfareTagsContainer>
                    {preview
                      ? form.welfare.map((item, index) => {
                          if (item) {
                            return (
                              <Tag
                                key={index.toString()}
                                color="Gray"
                                variant="Tinted"
                              >
                                {welfareTagsName[index]}
                              </Tag>
                            );
                          }
                        })
                      : welfareTagsName.map((item, index) => {
                          if (welfareTags.current.includes(item)) {
                            return (
                              <Tag
                                key={index.toString()}
                                color="Gray"
                                variant="Tinted"
                              >
                                {item}
                              </Tag>
                            );
                          }
                        })}
                  </WelfareTagsContainer>
                  <Text
                    element="pre"
                    textType="Body1"
                    color={COLOR_SYSTEM.get("Gray")[800]}
                  >
                    {preview
                      ? form.welfareDetail.replace(/undefined/g, "")
                      : welfareDetail.current
                      ? welfareDetail.current.replace(/undefined/g, "")
                      : ""}
                  </Text>
                </TableData>
              </TableRow>
            </tbody>
          </Table>
        </section>
        {!isOwner && !isMyPost && (
          <section className="kakao-share">
            <Text textType="Body1Medium" color={COLOR_SYSTEM.get("Gray")[800]}>
              이 공고에 적합한 지인이 있다면
              <br />
              카카오톡으로 공유해주세요!
            </Text>
            <KakaoShareButton
              size="Medium"
              onClick={() => {
                onKakaoShare();
              }}
            />
          </section>
        )}
      </DetailMain>
    );
  } catch (error) {
    console.error(error);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_Detail_Error",
      "에러",
      {
        jobPostId: post ? post.id : "new",
        page: "DetailMain",
        errorMessage: error,
        url: `${location.pathname}${location.search}`,
      }
    );
  }
};

export default withRouter(Main);
