import React, { Component } from "react";
import "./../../../Common.css";
import "./../../Admin.scss";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonCheckbox,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout, async } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  AllInOnePartnerInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  UserPermanentType,
  UserWorkType,
  AllInOnePartnerLevel,
  CompanyType,
  CompanyTypeName,
  PARTNERS_TEMPLATE,
  UserInfo,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import { actions, RootState } from "../../../store";
import { connect } from "react-redux";
import { UIPopupType } from "../../../store/ui/types";
import {
  BoardAttribute,
  BoardContent,
  BoardContentQuiz,
  DailyQuiz,
} from "../../../models/Model.Board";
import {
  getDateStringFromToday,
  getDateTimeString,
} from "../../../utils/TimeUtil";
import StringUtil from "../../../utils/StringUtil";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import { JobOfferName } from "../../../models/Model.JobPost";
import Button from "../../../components/atoms/Button";
import * as API from "../../../API.json";
import parse from "html-react-parser";
import ReactDOMServer from "react-dom/server";
import { loadImageBase64, loadImageUrlBase64 } from "../../../utils/ImageUtil";
import ImageViewNSelect, { ImageSize } from "../ImageViewNSelect";
import HtmlComposer from "../../../components/HtmlComposer";
import Textarea from "react-textarea-autosize";
import BoardSummary from "../../../components/templates/BoardSummary";
import { Flex, Static } from "../../../components/atoms/Layout";
import { MY_ADDRESS } from "../../../config.json";
import Text from "../../../components/atoms/Text";
import { COLOR_SYSTEM } from "../../../components/design/design-system";
import Separator from "../../../components/atoms/Separator";
import ToggleButton from "../../../components/atoms/ToggleButton/ToggleButton";
import Viewer from "../../../pages/Viewer";
import BoardQuizView from "../../../components/templates/BoardQuizView";
import Input from "../../../components/atoms/Input";
import { on } from "cluster";
import { getDate } from "date-fns";
import Icon from "../../../components/atoms/Icon";

const AdminAPI = {
  DAILY_QUIZ_POST: {
    method: "POST",
    path: "/admin/quiz/daily",
    contentType: "application/json",
  },
  DAILY_QUIZ_PUT: {
    method: "PUT",
    path: "/admin/quiz/daily",
    contentType: "application/json",
  },
  DAILY_QUIZ_GET: {
    method: "GET",
    path: "/admin/quiz/daily",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: (dailyQuiz: DailyQuiz) => void;
    onSelect: (dailyQuiz: DailyQuiz) => void;
    onSelectQuiz: (quiz: BoardContentQuiz) => void;
    onCancel: () => void;
    dailyQuiz: DailyQuiz;
    me: UserInfo;
    setOnAdd: (onAdd: (quiz: BoardContentQuiz) => void) => void;
  };

type State = DailyQuiz & {
  showPreview: boolean;
  dateWarn: boolean;
  dateChanged: boolean;
};

class DailyQuizDetail extends Component<Props, State> {
  state: State = {
    showPreview: false,
    dateWarn: false,
    dateChanged: false,
    id: 0,
    date: "",
    title: "",
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): void {
    if (this.props.setOnAdd) {
      this.props.setOnAdd(this.onAddQuiz);
    }

    if (
      this.props.dailyQuiz &&
      this.props.dailyQuiz.id != this.state.id &&
      this.props.dailyQuiz.date
    ) {
      this.fetchData(this.props.dailyQuiz.date);
    }
  }

  componentWillUnmount(): void {
    if (this.props.setOnAdd) {
      this.props.setOnAdd(null);
    }
  }

  componentDidUpdate(prevProps, nextState) {
    if (
      this.props.dailyQuiz &&
      (!prevProps ||
        !prevProps.dailyQuiz ||
        this.props.dailyQuiz.id != prevProps.dailyQuiz.id) &&
      this.props.dailyQuiz.id != this.state.id &&
      this.props.dailyQuiz.date
    ) {
      this.fetchData(this.props.dailyQuiz.date);
    }
  }

  onAddQuiz = (quiz: BoardContentQuiz) => {
    let quizzes = [...this.state.quizzes];
    quizzes.push(quiz);
    this.setState({ quizzes });
  };

  onDeleteQuiz = (index: number) => {
    let quizzes = [...this.state.quizzes];
    quizzes.splice(index, 1);
    this.setState({ quizzes });
  };

  onCreateByChangeClick = () => () => {
    if (this.props.dailyQuiz.createdBy == this.props.me.id) return;

    this.props.confirmPopup.show({
      title: (
        <div className="common-container">
          <span className="common-bold">
            생성인을 {this.props.me.name}으로 변경하시겠습니까?
          </span>
        </div>
      ),
      body: <div className="common-container">원복시키기 어렵습니다.</div>,
      onDone: () =>
        this.setState({
          createdBy: this.props.me.id,
          createdByUser: this.props.me,
        }),
      doneText: "네",
    });
  };

  onSave = async () => {
    let dailyQuiz = { ...this.props.dailyQuiz, ...this.state };
    const date = dailyQuiz.date;
    dailyQuiz.quizzes = dailyQuiz.quizzes.map((quiz) => {
      return { id: quiz.id };
    });
    log(LogLevel.UI_ACTION, "DailyQuizDetail:onSave", dailyQuiz);

    this.props.progressPopup.show();
    let api;
    if (!this.state.id) {
      api = AdminAPI.DAILY_QUIZ_POST;
    } else {
      api = AdminAPI.DAILY_QUIZ_PUT;
    }

    if (!api) return;

    try {
      await fetchAPI(api, "", null, dailyQuiz, getGlobal(GlobalKey.TOKEN));
    } catch (e) {
      this.props.toastPopup.show(`저장에 실패했습니다. ${e.message}`);
    }

    let newDailyQuiz = await this.fetchData(date);
    log(LogLevel.UI_ACTION, "DailyQuizDetail:onSave updatedResult", dailyQuiz);

    this.props.onDone(newDailyQuiz);

    this.props.progressPopup.hide();
  };

  onChangeDate = (e) => {
    e.preventDefault();
    let dateString = e.target.value;
    let dateWarn = false;
    let dateChanged =
      dateString != (this.props.dailyQuiz && this.props.dailyQuiz.date);

    try {
      let date = Date.parse(dateString);
    } catch (e) {
      dateWarn = true;
    }

    this.setState({
      date: dateString,
      dateWarn,
      dateChanged,
    });
  };

  onEnterDate = () => {
    if (this.state.dateWarn) {
      return;
    }
    this.fetchData(this.state.date);
  };

  fetchData = async (date: string) => {
    this.props.progressPopup.show();
    let result = await fetchAPI(
      AdminAPI.DAILY_QUIZ_GET,
      "",
      {
        date: date,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    log(LogLevel.UI_EVENT, "DailyQuizDetail fetchData", result, this.state);

    if (result && !result.error && result.data) {
      this.props.onSelect(result.data);
      this.setState({
        ...result.data,
        date: getDateStringFromToday({ date: result.data.date }),
        dateChanged: false,
      });
    } else {
      this.setState({
        id: 0,
        quizzes: [],
        dateChanged: false,
        title: "",
      });
    }

    this.props.progressPopup.hide();

    return result.data;
  };

  render() {
    log(
      LogLevel.UI_ACTION,
      "DailyQuizDetail render",
      this.props.dailyQuiz,
      this.state
    );

    return (
      <div className="admin-container">
        {this.props.onCancel && (
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start" class="common-container">
                <IonButton
                  class="common-header-toolbar-button"
                  onClick={this.props.onCancel}
                >
                  <IonIcon name="arrow-back" mode="ios" />
                </IonButton>
                <IonLabel class="common-header-title common-flex-grow">
                  데일리 퀴즈 상세보기
                </IonLabel>
                <ToggleButton
                  onClick={() =>
                    this.setState({ showPreview: !this.state.showPreview })
                  }
                  size={"Small"}
                  type={"Text"}
                  variant={"Tinted"}
                  color={"Black"}
                  style={{ marginRight: "10px" }}
                  active={this.state.showPreview}
                  // disabled={this.state.mode == "view"}
                  // disabledColor={this.state.mode == "view"}
                >
                  미리보기
                </ToggleButton>
                <Button
                  onClick={this.onSave}
                  size={"Small"}
                  type={"Text"}
                  variant={"Outlined"}
                  color={"Primary"}
                  disabled={
                    this.state.dateWarn ||
                    this.state.dateChanged ||
                    !this.state.date
                  }
                  disabledColor={
                    this.state.dateWarn ||
                    this.state.dateChanged ||
                    !this.state.date
                  }
                >
                  저장하기
                </Button>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}

        <Static customStyle={{ padding: "0px 20px" }}>
          <Text textType="Body1SemiBold" color={COLOR_SYSTEM.get("Gray")[900]}>
            Id: {this.state.id} <br />
          </Text>
          <Flex>
            <Text
              textType="Body1SemiBold"
              color={COLOR_SYSTEM.get("Gray")[900]}
            >
              날짜:
            </Text>
            <Input
              style={{ flexGrow: 1 }}
              value={this.state.date || ""}
              onChange={this.onChangeDate}
              onEnter={this.onEnterDate}
            />
          </Flex>
          <Flex>
            <Text
              textType="Body1SemiBold"
              color={COLOR_SYSTEM.get("Gray")[900]}
            >
              제목:
            </Text>
            <Input
              style={{ flexGrow: 1 }}
              value={this.state.title || ""}
              onChange={(e) => this.setState({ title: e.target.value })}
            />
          </Flex>
          Quiz:
          {this.state.quizzes &&
            this.state.quizzes.length > 0 &&
            this.state.quizzes.map((quiz, index) => {
              return (
                <Flex
                  key={index.toString()}
                  alignItems="center"
                  customStyle={{
                    border: `solid 1px ${
                      quiz.deletedAt
                        ? COLOR_SYSTEM.get("Red")[400]
                        : COLOR_SYSTEM.get("Gray")[50]
                    }`,
                    backgroundColor: quiz.deletedAt
                      ? COLOR_SYSTEM.get("Red")[10]
                      : "transparent",
                    borderRadius: "10px",
                    padding: "5px 10px",
                    marginBottom: "5px",
                  }}
                  onClick={() => {
                    this.props.onSelectQuiz(quiz);
                  }}
                >
                  <Static customStyle={{ flexGrow: 1 }}>
                    <Text
                      textType="Body2"
                      color={COLOR_SYSTEM.get("Gray")[900]}
                    >
                      {quiz.id} / {quiz.question}
                    </Text>
                    <Flex gap="8px">
                      <Flex
                        gap="2px"
                        alignItems="center"
                        title={`${quiz.usedCnt}회 사용`}
                      >
                        <Icon
                          name="Document"
                          size={12}
                          color={COLOR_SYSTEM.get("Blue")[500]}
                        />
                        <Text
                          textType="Caption"
                          color={COLOR_SYSTEM.get("Gray")[600]}
                        >
                          {quiz.usedCnt}
                        </Text>
                      </Flex>
                      <Flex
                        gap="2px"
                        alignItems="center"
                        title={`${quiz.tryCnt}명 도전, ${
                          quiz.successCnt
                        }명 성공(${Math.floor(
                          (quiz.successCnt / quiz.tryCnt) * 100
                        )}%)`}
                      >
                        <Icon
                          name="Pencil"
                          size={12}
                          color={COLOR_SYSTEM.get("Blue")[500]}
                        />
                        <Text
                          textType="Caption"
                          color={COLOR_SYSTEM.get("Gray")[600]}
                        >
                          <Text color={COLOR_SYSTEM.get("Green")[600]}>
                            {quiz.successCnt}
                          </Text>
                          /{quiz.tryCnt}
                        </Text>
                      </Flex>
                      <Flex gap="2px" alignItems="center">
                        <Icon
                          name="Thumbs Up"
                          size={12}
                          color={COLOR_SYSTEM.get("Blue")[500]}
                        />
                        <Text
                          textType="Caption"
                          color={COLOR_SYSTEM.get("Gray")[600]}
                        >
                          {quiz.likeCnt}
                        </Text>
                      </Flex>
                      <Flex gap="2px" alignItems="center">
                        <Icon name="Thumbs Down" size={12} color="Red" />
                        <Text
                          textType="Caption"
                          color={COLOR_SYSTEM.get("Gray")[600]}
                        >
                          {quiz.dislikeCnt}
                        </Text>
                      </Flex>
                    </Flex>
                  </Static>
                  <Button
                    type="Icon"
                    size={"Small"}
                    variant={"Tinted"}
                    color={"Negative"}
                    icon="Minus"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.onDeleteQuiz(index);
                    }}
                  />
                  <Flex direction="column">
                    {index > 0 && (
                      <Button
                        type="Icon"
                        size={"XSmall"}
                        variant={"Ghost"}
                        color={"Secondary"}
                        icon="CaretUp"
                        onClick={(e) => {
                          // move index up
                          e.stopPropagation();
                          let quizzes = [...this.state.quizzes];
                          let temp = quizzes[index];
                          quizzes[index] = quizzes[index - 1];
                          quizzes[index - 1] = temp;
                          this.setState({ quizzes });
                        }}
                      />
                    )}
                    {index < this.state.quizzes.length - 1 && (
                      <Button
                        type="Icon"
                        size={"XSmall"}
                        variant={"Ghost"}
                        color={"Secondary"}
                        icon="CaretDown"
                        onClick={(e) => {
                          // move index down
                          e.stopPropagation();
                          let quizzes = [...this.state.quizzes];
                          let temp = quizzes[index];
                          quizzes[index] = quizzes[index + 1];
                          quizzes[index + 1] = temp;
                          this.setState({ quizzes });
                        }}
                      />
                    )}
                  </Flex>
                </Flex>
              );
            })}
        </Static>
      </div>
    );

    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.partner.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps)(DailyQuizDetail);
