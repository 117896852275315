import React, { Component } from "react";
import "./Profile.css";
import {
  IonPopover,
  IonTitle,
  IonLoading,
  IonToast,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
  IonSpinner,
} from "@ionic/react";
import { log, LogLevel } from "../utils/LogUtil";
import { UserInfo } from "../models/Model.User";
import ProfileImage from "./ProfileImage";
import { PremiumType } from "../models/Model.User.Premium";
import Icon from "./atoms/Icon";
import { COLOR_SYSTEM } from "./design/design-system";

type Props = {
  user: UserInfo;
  onImageSelected?: (e) => void;
  showInviteCnt?: boolean;
  premiumType?: PremiumType;
};

const Profile: React.SFC<Props> = ({
  user,
  onImageSelected,
  showInviteCnt,
}) => {
  log(LogLevel.NONE, "Profile.render: ", user, onImageSelected);
  if (!user) {
    return (
      <div className="profile-container">
        <IonSpinner name="crescent" />
      </div>
    );
  }
  let imageChangeButton;
  if (onImageSelected) {
    imageChangeButton = (
      <div className="profile-profile-upload-button-container">
        <div className="profile-profile-upload-button-inner">
          <IonButton color="profile-profile-upload-button">
            <IonIcon class="profile-profile-upload-button-icon" name="camera" />
          </IonButton>
          <input
            className="profile-profile-upload-file"
            type="file"
            accept="image/*"
            onChange={onImageSelected}
          />
        </div>
      </div>
    );
  }

  let inviteCnt;
  if (showInviteCnt) {
    inviteCnt = (
      <div className="profile-count-container">
        <div className="profile-count">
          {user.inviteCnt ? user.inviteCnt : 0}
        </div>
        <div className="profile-count-type">친구초대</div>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <div className="profile-profile-container">
        <ProfileImage
          className="profile-profile-image"
          profileUrl={user.profileUrl}
        />
        {imageChangeButton}
      </div>
      <div className="profile-nickname-container">
        <div className="profile-nickname">
          {user.nickname}
          {user.premiumType && (
            <img
              src="/images/sprout2.png"
              style={{
                width: "16px",
                height: "20px",
              }}
            />
          )}
          {user.blocked ? (
            <span className="profile-nickname-blocked">(차단)</span>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="profile-description">{user.description}</div>
      <div className="profile-counts-container">
        <div className="profile-count-container profile-count-border">
          <div className="profile-count">{user.askCnt ? user.askCnt : 0}</div>
          <div className="profile-count-type">질문글</div>
        </div>
        <div
          className={
            "profile-count-container" +
            (showInviteCnt ? " profile-count-border" : "")
          }
        >
          <div className="profile-count">
            {user.answerCnt ? user.answerCnt : 0}
          </div>
          <div className="profile-count-type">답변글</div>
        </div>
        {inviteCnt}
      </div>
    </div>
  );
};

export default Profile;
