import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonModal,
  IonLoading,
  IonToast,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
  IonFooter,
} from "@ionic/react";
import "./ProductSaleViewer.css";
import { ProductInfo, ProductStatus } from "../models/Model.Sale";
import { log, LogLevel } from "../utils/LogUtil";
import { UIPopupType, UIServiceType } from "../store/ui/types";
import SwipeableViews from "react-swipeable-views";
import ReactHtmlParser from "react-html-parser";
import StringUtil from "../utils/StringUtil";
import { KeywordType } from "../models/Model.Medicine";
import ComponentGenerator from "../ivcomponent/ComponentGenerator";
import LinkMeta from "../components/LinkMeta";
import drugIcon from "./../assets/icon/drug.svg";
import kakaoShareIcon from "./../assets/icon/kakao_share.png";
import { MY_ADDRESS } from "./../config.json";
import { Reference } from "../models/Model.Board";
import { getGlobal, GlobalKey } from "../utils/GlobalUtil";
// import { testProducts } from './ProductSaleOrder';
import AnalyticsUtil from "../utils/AnalyticsUtil";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {};

type State = {
  cardviewIndex: number;
  expandDetail: boolean;
};
const windowAny: any = window;
const queryString = require("query-string");

const styleObject = (styleString: string) => {
  if (!styleString) return {};
  return styleString.split(";").reduce((acc, prop) => {
    const [key, value] = prop.split(":");
    if (key) {
      const formattedKey = key
        .trim()
        .replace(/-./g, (match) => match.charAt(1).toUpperCase());
      acc[formattedKey] = value.trim();
    }
    return acc;
  }, {});
};

class ProductSaleViewer extends Component<Props, State> {
  product: ProductInfo = null;
  productId: number = 1;

  onProgress: boolean = false;
  contentScroll = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      cardviewIndex: 0,
      expandDetail: false,
    };
    try {
      windowAny.IMP.init("imp68909306");
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, "ProductSaleViewer.init error", e);
    }

    let qs = queryString.parse(this.props.location.search);
    try {
      this.productId = parseInt(qs.id);
    } catch (err) {}

    if (!this.props.products[this.productId]) this.props.waitingPopup.show();
    else
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "SALE_ENTER",
        "제품상세페이지 진입",
        {
          제품id: this.productId,
          code: this.props.products[this.productId].advertiserCode,
          productCode: this.props.products[this.productId]
            .advertiserProductCode,
        }
      );

    this.props.loadProduct(this.productId);
  }

  componentDidMount() {
    if (this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.goBack);

    // else{
    //   this.props.loadProduct[this.productId];
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    let qs = queryString.parse(this.props.location.search);
    let newId = this.productId;
    try {
      newId = parseInt(qs.id);
      if (!newId) newId = 0;
    } catch (err) {
      newId = 0;
    }

    //console.log(LogLevel.UI_LIFECYCLE, "Viewer:compoenetDidUpdate new ID ", newId);
    if (this.productId != newId) {
      log(
        LogLevel.UI_LIFECYCLE,
        "Viewer:compoenetDidUpdate new ID DETECTED",
        newId,
        this.productId
      );
      this.productId = newId;
      this.scrollToTop();
      this.props.loadProduct(this.productId);
    }

    if (!prevProps.backKeyControl && this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.goBack);

    if (
      !prevProps.products[this.productId] &&
      this.props.products[this.productId]
    ) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "SALE_ENTER",
        "제품상세페이지 진입",
        {
          제품id: this.productId,
          code: this.props.products[this.productId].advertiserCode,
          productCode: this.props.products[this.productId]
            .advertiserProductCode,
        }
      );
      this.props.waitingPopup.hide();
    }
  }

  scrollToTop = () => {
    setTimeout(() => {
      log(
        LogLevel.UI_LIFECYCLE,
        "Viewer:compoenetDidUpdate scroll up",
        this.contentScroll
      );
      if (this.contentScroll) this.contentScroll.scrollTo(0, 0);
      else this.scrollToTop();
    }, 10);
  };

  goBack = (e) => {
    log(LogLevel.UI_ACTION, "ProductSaleViewer:goBack");
    this.props.history.goBack();
  };

  onClickLink = (url: string) => {
    log(
      LogLevel.UI_ACTION,
      "onClickLink",
      url,
      url.substring(MY_ADDRESS.length)
    );

    if (url.startsWith("&&&")) {
      if (getGlobal(GlobalKey.OS) != "browser") {
        this.props.history.push(url.substring(3));
      } else {
        window.open(
          url.replace("&&&", MY_ADDRESS),
          getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
        );
      }
    } else
      window.open(
        url,
        getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
      );
  };

  handleChangeIndex = (index: number) => {
    if (index == this.state.cardviewIndex) return;
    if (index < 0 || index >= this.product.content.cards.length) return;
    this.setState({ cardviewIndex: index });
  };

  transformLastMention = false;
  transform = (node, index) => {
    log(LogLevel.UI_DATA_LOAD, "transform", node);
    let children: any = "";
    if (node.type == "text") {
      let str = node.data;
      if (this.transformLastMention && str.startsWith(" ")) {
        str = str.substring(1);
        this.transformLastMention = false;
      }
      let paragraphs = str.split("\n");
      log(LogLevel.UI_DATA_LOAD, "transform.paragraphs", paragraphs);
      let rvalue = paragraphs[0];
      for (let i = 1; i < paragraphs.length; i++) {
        rvalue += <br />;
        rvalue += paragraphs[i];
      }
      return rvalue;
    } else if (node.type == "tag" && node.name == "br") {
      this.transformLastMention = false;
      return <br key={`${index}`} />;
    } else if (
      node.type == "tag" &&
      node.name == "span" &&
      node.attribs.class &&
      node.attribs.class == "keywords"
    ) {
      return node.children[0].data;
    } else if (
      node.type == "tag" &&
      node.name == "span" &&
      node.attribs.class &&
      node.attribs.class == "components"
    ) {
      log(
        LogLevel.UI_DATA_LOAD,
        "transform.components",
        node,
        this,
        this.props,
        node.attribs
      );
      return ComponentGenerator.generate(node.attribs);
    } else if (
      node.type == "tag" &&
      node.name == "span" &&
      node.attribs.class &&
      node.attribs.class == "mention"
    ) {
      log(
        LogLevel.UI_DATA_LOAD,
        "transform.keyword mention",
        node,
        this,
        this.props
      );
      let text = "";
      if (
        node.attribs["data-type"] == KeywordType.MEDICINE ||
        node.attribs["data-type"] == KeywordType.INGRADIENT ||
        node.attribs["data-type"] == KeywordType.ANIMAL_MEDICINE
      ) {
        // this.props.fetchKeyword(parseInt(node.attribs["data-type"]), parseInt(node.attribs["data-id"]));
        if (node.children.length > 1 && node.children[1].name == "span") {
          if (node.children[1].children) {
            for (let i = 0; i < node.children[1].children.length; i++) {
              if (node.children[1].children[i].type == "text") {
                text = node.children[1].children[i].data;
                // log(LogLevel.UI_DATA_LOAD, "transform.keyword mention 2", node.children[1].children[i], text );
                break;
              }
            }
          }
        }
        this.transformLastMention = true;
        return (
          <span key={`${index}`} className="viewerbody-keywords">
            {text}
            <img className="viewerbody-keywords-icon" src={drugIcon} />
          </span>
        );
      } else {
        if (node.children.length > 1 && node.children[1].name == "span") {
          if (node.children[1].children) {
            for (let i = 0; i < node.children[1].children.length; i++) {
              if (node.children[1].children[i].type == "text") {
                this.transformLastMention = true;
                return node.children[1].children[i].data;
              }
            }
          }
        }
      }
      return "";
    } else if (node.type == "tag" && node.name == "img") {
      log(LogLevel.UI_DATA_LOAD, "transform.img", node, this, this.props);
      // this.props.fetchKeyword(parseInt(node.attribs.keywordtype), parseInt(node.attribs.keywordid));
      let attrib: any = {};
      if (node.attribs.src) {
        attrib.src = StringUtil.convertFilePath(node.attribs.src);
        return <img key={`${index}`} {...attrib} />;
      }
      return "";
    } else if (node.type == "tag" && node.name == "a") {
      let url = node.attribs.href;

      log(
        LogLevel.UI_DATA_LOAD,
        "ViewerBody:transform: a tag",
        children,
        url,
        node
      );

      if (!children || children == url) {
        if (url.startsWith("&&&")) url = url.replace("&&&", MY_ADDRESS);

        if (
          node.attribs.description ||
          node.attribs.title ||
          node.attribs.images
        ) {
          log(LogLevel.UI_DATA_LOAD, "ViewerBody:transform: a tag", node);
          return (
            <div
              className="viewerbody-links-container"
              key={`${index}`}
              onClick={() => this.onClickLink(node.attribs.href)}
            >
              {/* <div className="viewerbody-links">
                {url}
              </div> */}
              <LinkMeta
                link={node.attribs.href}
                title={node.attribs.title}
                description={node.attribs.description}
                image={node.attribs.images}
                onClick={this.onClickLink}
              />
            </div>
          );
        } else if (node.attribs.href && this.props.links[node.attribs.href]) {
          let reference: Reference = this.props.links[node.attribs.href];
          log(LogLevel.UI_DATA_LOAD, "ViewerBody:transform: a tag", reference);
          if (reference.description || reference.title || reference.image) {
            return (
              <div
                className="viewerbody-links-container"
                key={`${index}`}
                onClick={() => this.onClickLink(reference.url)}
              >
                {/* <div className="viewerbody-links">
                  {url}
                </div> */}
                <LinkMeta
                  link={reference.url}
                  title={reference.title}
                  description={reference.description}
                  image={reference.image}
                  onClick={this.onClickLink}
                />
              </div>
            );
          } else {
            return (
              <span
                className="viewerbody-links-container"
                key={`${index}`}
                onClick={() => this.onClickLink(reference.url)}
                style={{ ...styleObject(node.attribs.style) }}
              >
                {children ? children : url}
              </span>
            );
          }
        }
      } else {
        this.props.loadLink(node.attribs.href, this.product.content.id);
        return (
          <div
            key={`${index}`}
            onClick={() => this.onClickLink(node.attribs.href)}
          >
            {url}
          </div>
        );
      }
    }

    log(LogLevel.UI_EXCEPTION, "ViewerBody:transform: unknown", node);
    return undefined;
  };

  onShare = async () => {};

  onExpandDetail = () => {
    this.setState({ expandDetail: true });
  };

  onOrder = async () => {
    log(LogLevel.UI_ACTION, "ProductSaleViewer:onOrder");
    this.props.history.push("/sale/order?id=" + this.productId);
  };

  render() {
    if (this.props.products && this.props.products[this.productId]) {
      this.product = this.props.products[this.productId];
    }
    log(
      LogLevel.UI_LIFECYCLE,
      "ProductSaleViewer.render",
      this.props,
      this.productId,
      this.product
    );

    let saleElement;
    if (
      this.product &&
      this.product.priceOriginal &&
      this.product.priceOriginal != this.product.price
    ) {
      let sale = Math.ceil(
        ((this.product.priceOriginal - this.product.price) * 100) /
          this.product.priceOriginal
      );
      saleElement = (
        <div className="sales-product-viewer-information-discount">
          {sale}%{" "}
          <span className="sales-product-viewer-information-discount-original">
            {this.product.priceOriginal.toLocaleString()}원
          </span>
        </div>
      );
    }
    return (
      <>
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar">
            <IonButtons slot="start">
              <IonButton onClick={this.goBack}>
                <IonIcon name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel class="common-header-title" onClick={this.goBack}>
                제품 상세보기
              </IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div
          className="sales-product-viewer-content"
          ref={(ref) => (this.contentScroll = ref)}
        >
          {this.product &&
            this.product.content &&
            this.product.content.cards &&
            this.product.content.cards.length && (
              <div className="common-container">
                <div className="sales-product-viewer-cardview-container common-position-relative">
                  <div className="sales-product-viewer-cardview-square-container common-position-relative">
                    <SwipeableViews
                      className="sales-product-viewer-cardview-swipe"
                      enableMouseEvents
                      index={this.state.cardviewIndex}
                      onChangeIndex={this.handleChangeIndex}
                    >
                      {this.product.content.cards.map((item, index) => {
                        return (
                          <div
                            key={index.toString()}
                            className="common-content"
                          >
                            <img
                              className="sales-product-viewer-cardview-image"
                              src={StringUtil.convertFilePath(item.url)}
                            />
                          </div>
                        );
                      })}
                    </SwipeableViews>
                  </div>
                  <div className="common-container-column common-flex-align-center sales-product-viewer-index-container">
                    <div className="common-flex-row common-flex-align-center">
                      {this.product &&
                        this.product.content &&
                        this.product.content.cards.map((item, index) => {
                          return (
                            <div
                              key={index.toString()}
                              className={
                                this.state.cardviewIndex == index
                                  ? "sales-product-viewer-page-selected"
                                  : "sales-product-viewer-page"
                              }
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="sales-product-viewer-information-container">
            <div className="sales-product-viewer-information-title">
              <span className="common-bold">
                {" "}
                {this.product && this.product.name}
              </span>{" "}
              {this.product && this.product.description
                ? " - " + this.product && this.product.description
                : ""}
            </div>
            {saleElement}
            <div className="sales-product-viewer-information-price">
              {this.product &&
                this.product.price &&
                this.product.price.toLocaleString()}
              원
            </div>
          </div>
          <div className="sales-product-viewer-detail-title">상품설명</div>
          <div
            className={
              "sales-product-viewer-detail-container" +
              (this.state.expandDetail ? "-expanded" : "")
            }
          >
            {ReactHtmlParser(
              this.product && this.product.content
                ? this.product.content.bodyHtml
                : "",
              { transform: this.transform }
            )}
            {!this.state.expandDetail && (
              <div className="sales-product-viewer-detail-more-button-container">
                <IonButton
                  color="common-button"
                  class="sales-product-viewer-detail-more-button"
                  onClick={this.onExpandDetail}
                >
                  설명 더 보기
                </IonButton>
              </div>
            )}
          </div>
        </div>
        <IonFooter class="sales-product-viewer-footer" mode="ios">
          <img
            src={kakaoShareIcon}
            className="sales-product-viewer-share-button"
            onClick={this.onShare}
          />
          <IonButton
            color="common-button"
            class="sales-product-viewer-buy-button"
            onClick={this.onOrder}
          >
            주문하기
          </IonButton>
        </IonFooter>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  links: state.board.links,
  products: state.sale.products,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  loadLink: (url: string, contentId: number) =>
    actions.board.loadLink(url, contentId),
  loadProduct: (id: number) => actions.sale.getProduct(id),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductSaleViewer)
);
