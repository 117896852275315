import React, { Component } from "react";
import "./../../Admin.scss";
import "./../../../Common.css";
import "./../../Sale.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonCheckbox,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "./../../../utils/APIUtil";
import { timeout } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  UserInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import ProfileImage from "../../../components/ProfileImage";
import { ProductInfo, ProductStatus } from "../../../models/Model.Sale";
import ReactQuill from "react-quill";
import ComponentComposePopup from "../../../ivcomponent/ComponentComposePopup";
import { loadImageBase64 } from "../../../utils/ImageUtil";
import { RootState } from "../../../store";
import { connect } from "react-redux";
import ProductUtil from "./ProductUtil";
import { UIPopupType } from "../../../store/ui/types";
import { BoardType } from "../../../store/board/types";
import { Attachment, BoardContent } from "../../../models/Model.Board";
import StringUtil from "../../../utils/StringUtil";
import HtmlComposer from "../../../components/HtmlComposer";
import Textarea from "react-textarea-autosize";
import Button from "../../../components/atoms/Button";
import CheckBox from "../../../components/atoms/CheckBox/CheckBox";

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: (product?: ProductInfo) => void;
    product: ProductInfo;
    onCancel?: () => void;
  };

type State = {
  title: string;
  body: string;
  cardnews: Attachment[];
  product: ProductInfo;
  preview: boolean;
  conditionStr: string;
};

class ProductEdit extends Component<Props, State> {
  reactQuillRef = null;
  ivComponentComposerPopup = null;
  currentIndex = 0;
  doneProcessing = false;

  state = {
    title: "",
    body: "",
    product: null,
    preview: false,
    cardnews: [],
    conditionStr: "",
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.product) {
      log(LogLevel.UI_DATA_LOAD, "componentDidMount : ", this.props.product);
      this.initialize(this.props.product);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.product &&
      (!this.props.product || this.props.product.id != nextProps.product.id)
    ) {
      log(LogLevel.UI_DATA_LOAD, "componentWillUpdate : ", nextProps.product);
      this.initialize(nextProps.product);
    }
  }

  initialize = (product: ProductInfo) => {
    let title = "";
    let body = "",
      conditionStr = "";
    let cardnews = [];
    if (product.content) {
      if (product.content.subject) title = product.content.subject;
      else title = product.name;
      if (product.content.bodyHtml) {
        body = product.content.bodyHtml;
        body = StringUtil.convertFilePathFull(body);
      }
      if (product.content.cards) cardnews = product.content.cards;
      if (product.orderAmountLimitCondition)
        conditionStr = JSON.stringify(
          product.orderAmountLimitCondition,
          null,
          "\t"
        );
    }
    this.setState({ product: { ...product }, title, body, cardnews });
  };

  onDone = async () => {
    if (this.doneProcessing) return true;
    this.doneProcessing = true;

    log(LogLevel.UI_ACTION, "ProductEdit:onDone", this.state.product);
    let showPopup = true;

    if (showPopup && this.props.progressPopup) this.props.progressPopup.show();

    let product: ProductInfo = this.state.product;
    if (!product.content) {
      product.content = {
        groupParent: 0,
        boardsCategoriesId: BoardType.PRODUCT, // 저장될 게시판 id
        isAnonymous: false, // 익명 게시 여부
      };
    }

    product.content.subject = this.state.title;
    product.content.bodyHtml = this.state.body;
    product.content.cards = this.state.cardnews;

    if (this.state.conditionStr)
      product.orderAmountLimitCondition = JSON.parse(this.state.conditionStr);

    try {
      let result = await ProductUtil.fetchReplaceProduct(
        product,
        showPopup ? this.props.progressPopup : null
      );
      log(LogLevel.UI_DATA_LOAD, "ProductEdit:onDone : ", result);

      this.setState({ product: result });

      this.props.onDone(product);
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, e);
      this.props.toast.show(`저장에 실패하였습니다. ${e.message}`);
    }
    if (showPopup && this.props.progressPopup) this.props.progressPopup.hide();
    this.doneProcessing = false;
  };

  onPreview = () => {
    if (this.state.product && this.state.product.id)
      window.open("/sale/product?id=" + this.state.product.id, "_blank");
  };

  onTitleChange = (event: CustomEvent) => {
    // console.dir(event);
    this.setState({ title: event.detail.value });
  };

  handleChange = (value, delta, source, editor) => {
    this.setState({ body: value });
  };

  onImageSelected = (e) => {
    // console.dir(e);
    console.dir(e.target.files[0]);
    console.dir(e.target);
    if (e.target.files && e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        loadImageBase64(e.target.files[i]).then((base64) => {
          this.onImageLoad(base64);
        });
      }
      e.target.value = null;
    }
  };

  handleChangeSelection = (range, source, editor) => {
    let str = "";
    if (range) {
      this.currentIndex = range.index;
    }
  };

  onImageLoad = (base64) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onImageLoad", base64.length);
    this.reactQuillRef
      .getEditor()
      .insertEmbed(this.currentIndex, "image", base64);
    this.reactQuillRef.getEditor().setSelection(this.currentIndex + 1);
  };

  onAddComponent = (data) => {
    log(LogLevel.UI_ACTION, "HtmlComposer:onAddComponent", data);
    // this.reactQuillRef.getEditor().insertEmbed(range.index, 'keywords', {text:text, id:item.id, type:item.type});

    this.reactQuillRef
      .getEditor()
      .insertEmbed(this.currentIndex, "components", data);
    this.reactQuillRef.getEditor().insertText(this.currentIndex + 1, " ");
    this.reactQuillRef.getEditor().setSelection(this.currentIndex + 2);
  };

  showComponentComposer = () => {
    if (this.ivComponentComposerPopup) this.ivComponentComposerPopup.show();
  };

  onBannerImageSelected = (e) => {
    let loadOption = {
      orientation: true,
      canvas: true,
      maxWidth: 1200,
      maxHeight: 800,
      cover: true,
    };
    if (e.target.files && e.target.files[0]) {
      loadImageBase64(e.target.files[0]).then((base64) => {
        let product: ProductInfo = { ...this.state.product };
        product.bannerImageBase64 = base64;
        this.setState({ product });
      });
    }
  };

  onBannerImageClear = () => {
    let product: ProductInfo = { ...this.props.product };
    product.bannerImage = "";
    product.bannerImageBase64 = "";
    this.setState({ product });
  };

  render() {
    if (!this.state.product) return <div />;

    let bannerImage;
    if (this.state.product.bannerImageBase64) {
      bannerImage = (
        <img
          className="admin-product-manage-banner-image"
          src={this.state.product.bannerImageBase64}
        />
      );
    } else if (this.state.product.bannerImage) {
      bannerImage = (
        <img
          className="admin-product-manage-banner-image"
          src={StringUtil.convertFilePath(this.state.product.bannerImage)}
        />
      );
    } else {
      bannerImage = (
        <div className="admin-product-manage-banner-image">Banner Image</div>
      );
    }

    return (
      <div className="admin-container">
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-toolbar">
            <IonButtons slot="start" class="common-container">
              <IonButton
                class="common-header-toolbar-button"
                onClick={this.props.onCancel}
              >
                <IonIcon name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel class="common-header-title common-flex-grow">
                제품 상세정보
              </IonLabel>
            </IonButtons>
            <IonButtons slot="end">
              <Button
                size={"Medium"}
                type={"Text"}
                variant={"Ghost"}
                color={"Primary"}
                onClick={this.onPreview}
              >
                미리보기
              </Button>
              <Button
                size={"Medium"}
                type={"Text"}
                variant={"Ghost"}
                color={"Primary"}
                onClick={this.onDone}
              >
                저장
              </Button>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent class="common-container common-scroll">
          <div className="admin-title">ID : {this.state.product.id}</div>

          <div className="common-container-row-wrap admin-margin-bottom">
            <div>상품판매상태</div>
            <div
              className={
                "admin-toggle" +
                (this.state.product.status == ProductStatus.PREPARING
                  ? " admin-toggle-selected"
                  : "")
              }
              onClick={() =>
                this.setState({
                  product: {
                    ...this.state.product,
                    status: ProductStatus.PREPARING,
                  },
                })
              }
            >
              준비중
            </div>
            <div
              className={
                "admin-toggle" +
                (this.state.product.status == ProductStatus.ONGOING
                  ? " admin-toggle-selected"
                  : "")
              }
              onClick={() =>
                this.setState({
                  product: {
                    ...this.state.product,
                    status: ProductStatus.ONGOING,
                  },
                })
              }
            >
              진행중
            </div>
            <div
              className={
                "admin-toggle" +
                (this.state.product.status == ProductStatus.SHORT
                  ? " admin-toggle-selected"
                  : "")
              }
              onClick={() =>
                this.setState({
                  product: {
                    ...this.state.product,
                    status: ProductStatus.SHORT,
                  },
                })
              }
            >
              품절
            </div>
            <div
              className={
                "admin-toggle" +
                (this.state.product.status == ProductStatus.CLOSED
                  ? " admin-toggle-selected"
                  : "")
              }
              onClick={() =>
                this.setState({
                  product: {
                    ...this.state.product,
                    status: ProductStatus.CLOSED,
                  },
                })
              }
            >
              판매종료
            </div>
          </div>
          <div className="admin-product-manage-body-container">
            <HtmlComposer
              title={this.state.title}
              onTitleChanged={(title) => {
                this.setState({ title });
              }}
              body={this.state.body}
              onBodyChanged={(body) => {
                this.setState({ body });
              }}
              cardnews={this.state.cardnews}
              onCardnewsChanged={(cardnews) => {
                this.setState({ cardnews });
              }}
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">설명 :</div>
            <IonInput
              class="admin-input"
              value={this.state.product.description}
              onIonChange={(e) =>
                this.setState({
                  product: {
                    ...this.state.product,
                    description: e.detail.value,
                  },
                })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">제품코드 :</div>
            <IonInput
              class="admin-input"
              value={this.state.product.code}
              onIonChange={(e) =>
                this.setState({
                  product: {
                    ...this.state.product,
                    code: e.detail.value,
                  },
                })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">회사 :</div>
            <IonInput
              class="admin-input"
              value={this.state.product.company}
              onIonChange={(e) =>
                this.setState({
                  product: { ...this.state.product, company: e.detail.value },
                })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">원래가격 :</div>
            <IonInput
              class="admin-input"
              type="number"
              value={this.state.product.priceOriginal}
              onIonChange={(e) =>
                this.setState({
                  product: {
                    ...this.state.product,
                    priceOriginal: parseInt(e.detail.value),
                  },
                })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">판매가 :</div>
            <IonInput
              class="admin-input"
              type="number"
              value={this.state.product.price}
              onIonChange={(e) =>
                this.setState({
                  product: {
                    ...this.state.product,
                    price: parseInt(e.detail.value),
                  },
                })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">배송비 :</div>
            <IonInput
              class="admin-input"
              type="number"
              value={this.state.product.deliveryFee}
              onIonChange={(e) =>
                this.setState({
                  product: {
                    ...this.state.product,
                    deliveryFee: parseInt(e.detail.value),
                  },
                })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">수량 조건 (Oscar 문의)</div>
          </div>
          <Textarea
            className="admin-text-area"
            minRows={3}
            maxRows={15}
            value={this.state.conditionStr}
            onChange={(e) => {
              this.setState({ conditionStr: e.target.value });
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">DocumentID :</div>
            <IonInput
              class="admin-input"
              value={this.state.product.documentId}
              onIonChange={(e) =>
                this.setState({
                  product: {
                    ...this.state.product,
                    documentId: e.detail.value,
                  },
                })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">DocumentTab :</div>
            <IonInput
              class="admin-input"
              value={this.state.product.documentTabName}
              onIonChange={(e) =>
                this.setState({
                  product: {
                    ...this.state.product,
                    documentTabName: e.detail.value,
                  },
                })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">반품DocumentID :</div>
            <IonInput
              class="admin-input"
              value={this.state.product.refundDocumentId}
              onIonChange={(e) =>
                this.setState({
                  product: {
                    ...this.state.product,
                    refundDocumentId: e.detail.value,
                  },
                })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="admin-title">반품DocumentTab :</div>
            <IonInput
              class="admin-input"
              value={this.state.product.refundDocumentTabName}
              onIonChange={(e) =>
                this.setState({
                  product: {
                    ...this.state.product,
                    refundDocumentTabName: e.detail.value,
                  },
                })
              }
            />
          </div>
          <CheckBox
            checked={this.state.product.pharmacyOnly}
            size={"Large"}
            onClick={() => {
              this.setState({
                product: {
                  ...this.state.product,
                  pharmacyOnly: !this.state.product.pharmacyOnly,
                },
              });
            }}
          >
            약국 전용 상품
          </CheckBox>
          <div className="admin-margin-height" />
        </IonContent>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
  // filePath : state.board.filePath,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductEdit);
