import React, { Component } from "react";
import "./RegisterStage2.css";
import "./RegisterStage.css";
import {
  IonButton,
  IonInput,
  IonIcon,
  IonToast,
  IonContent,
} from "@ionic/react";
import DynamicChecker, { CheckStatus } from "./../DynamicChecker";
import { fetchAPI } from "./../../utils/APIUtil";
import * as API from "./../../API.json";
import { UserInfo, UserWorkType, UserSNSType } from "../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "./../../utils/GlobalUtil";
import { log, LogLevel } from "../../utils/LogUtil";
// import * as loadImage from 'blueimp-load-image';
import {
  timerStart,
  TimerId,
  timerStop,
  getDateStringFromToday,
} from "../../utils/TimeUtil";
import { loadImageBase64 } from "../../utils/ImageUtil";
import { writeFile } from "fs";
import { RootState, actions } from "../../store";
import { connect } from "react-redux";
import { UIPopupType } from "../../store/ui/types";
import DynamicCheckerInput from "./../DynamicCheckerInput";
import kakaoIcon from "./../../assets/icon/question_small_yellow_mobile_3X.png";
import AnalyticsUtil from "./../../utils/AnalyticsUtil";
import { ErrorCode } from "../../models/ErrorCode";
import { RouteComponentProps, withRouter } from "react-router";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    private: boolean;
    user: UserInfo;
    onDone: (data: any) => void;
    onAddressSearch: (data: any) => void;
    onBack: (data: any) => void;
  };

type State = {
  emailCheckStatus: CheckStatus;
  workPlaceCheckStatus: CheckStatus;
  emailWarning: string;
  workPlaceWarning: string;
  email: string;
  workType: number;

  licenseCheckStatus: CheckStatus;
  licenseWarning: string;
  license: string;
};
const windowAny: any = window;

class RegisterStage2 extends Component<Props, State> {
  contentScroll = null;

  constructor(props) {
    super(props);
    this.state = {
      emailCheckStatus: CheckStatus.UNCHECKED,
      workPlaceCheckStatus: this.props.user.workPlaceAddress
        ? CheckStatus.CHECKED
        : CheckStatus.UNCHECKED,
      emailWarning: "",
      workPlaceWarning: "",
      licenseCheckStatus: CheckStatus.UNCHECKED,
      licenseWarning: "",
      license: this.props.user.license,
      email: this.props.user.email,
      workType: this.props.user.workType ? this.props.user.workType : 2,
    };
    this.canPass = this.canPass.bind(this);

    if (this.state.email) this.validateEmail(this.state.email);

    if (this.state.license) this.validateLicense(this.state.license);
  }

  canPass() {
    return (
      this.state.licenseCheckStatus != CheckStatus.UNCHECKED &&
      this.state.emailCheckStatus == CheckStatus.CHECKED &&
      this.state.workType &&
      this.state.workPlaceCheckStatus == CheckStatus.CHECKED
    );
  }

  onDone = () => {
    if (this.state.licenseCheckStatus != CheckStatus.CHECKED) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "REGISTRATION_4_FAIL",
        "회원가입_4_개인정보입력실패",
        { 이유: "면허번호이상" }
      );
      // this.props.toast.show("면허번호을 확인해주세요.");
      // return;
    }
    if (!this.state.license.trim() || this.state.license.trim().length < 4) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "REGISTRATION_4_FAIL",
        "회원가입_4_개인정보입력실패",
        { 이유: "면허번호미입력" }
      );
      this.props.toast.show("면허번호을 확인해주세요.");
      return;
    }
    if (this.state.emailCheckStatus != CheckStatus.CHECKED) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "REGISTRATION_4_FAIL",
        "회원가입_4_개인정보입력실패",
        { 이유: "이메일이상" }
      );
      this.props.toast.show("이메일을 확인해주세요.");
      return;
    }
    if (
      this.state.workType != UserWorkType.LEAVE_OF_ABSENCE &&
      this.state.workPlaceCheckStatus != CheckStatus.CHECKED
    ) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "REGISTRATION_4_FAIL",
        "회원가입_4_개인정보입력실패",
        { 이유: "근무지이상" }
      );
      this.props.toast.show("근무지를 확인해주세요.");
      return;
    }
    if (this.state.workType == 0) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "REGISTRATION_4_FAIL",
        "회원가입_4_개인정보입력실패",
        { 이유: "근무타입이상" }
      );
      this.props.toast.show("근무타입을 확인해주세요.");
      return;
    }

    let rvalue: UserInfo = {
      license: this.state.license,
      email: this.state.email,
      workType: this.state.workType,
      managerMessage:
        this.state.licenseCheckStatus == CheckStatus.WARNING
          ? "이미 가입된 면허번호입니다."
          : "",
      memo:
        this.state.workPlaceCheckStatus == CheckStatus.WARNING
          ? `+add:[${getDateStringFromToday()}] 면허번호 중복확인 필요(${
              this.state.license
            })`
          : "",
    };
    this.props.onDone(rvalue);
  };

  onBack = () => {
    let rvalue = {
      license: this.state.license,
      email: this.state.email,
      workType: this.state.workType,
    };
    this.props.onBack(rvalue);
  };

  validateEmail = (email: string) => {
    log(
      LogLevel.UI_DATA_LOAD,
      "RegisterStage2:validateEmail",
      this.state.email,
      this.state.emailCheckStatus
    );
    if (!email) {
      this.setState({
        emailCheckStatus: CheckStatus.UNCHECKED,
        emailWarning: "",
      });
      return;
    } else if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      this.setState({
        emailCheckStatus: CheckStatus.CHECKING,
        emailWarning: "",
      });
      if (this.props.private) {
        fetchAPI(
          API.USER_FIELD_VALIDATE,
          "email",
          null,
          { value: email },
          getGlobal(GlobalKey.TOKEN)
        )
          .then((result) => this.onEmailChecked(result))
          .catch((e) => {
            log(LogLevel.UI_EXCEPTION, e);
          });
      } else {
        fetchAPI(API.USER_FIELD_VALIDATE_OPEN, "email", null, { value: email })
          .then((result) => this.onEmailChecked(result))
          .catch((e) => {
            log(LogLevel.UI_EXCEPTION, e);
          });
      }
    } else {
      if (email.length > 10) {
        this.setState({ emailWarning: "이메일주소가 유효하지 않습니다." });
      }
      this.setState({ emailCheckStatus: CheckStatus.WARNING });
    }
  };

  validateLicense = (license: string) => {
    if (!license) {
      this.setState({ licenseCheckStatus: CheckStatus.UNCHECKED });
      return;
    }

    this.setState({ licenseCheckStatus: CheckStatus.CHECKING });

    if (this.props.private) {
      fetchAPI(
        API.USER_FIELD_VALIDATE,
        "license",
        null,
        { value: license },
        getGlobal(GlobalKey.TOKEN)
      )
        .then((result) => this.onLicenseChecked(result))
        .catch((e) => {
          log(LogLevel.UI_EXCEPTION, e);
        });
    } else {
      fetchAPI(API.USER_FIELD_VALIDATE_OPEN, "license", null, {
        value: license,
      })
        .then((result) => this.onLicenseChecked(result))
        .catch((e) => {
          log(LogLevel.UI_EXCEPTION, e);
        });
    }
  };

  onLicenseChecked = (result) => {
    if (result && !result.error && result.data.value == this.state.license) {
      this.setState({
        licenseCheckStatus: CheckStatus.CHECKED,
        licenseWarning: "",
      });
    } else if (
      result &&
      result.error &&
      result.data.value == this.state.license
    ) {
      this.setState({
        licenseCheckStatus: CheckStatus.WARNING,
        // licenseWarning: "이미 가입된 면허번호입니다.",
      });
    } else {
      log(LogLevel.NONE, result);
    }
  };

  onLicenseChange = (e) => {
    log(LogLevel.NONE, e);
    let license = e.target.value;
    this.setState({ license });
    this.validateLicense(license);
  };

  onEmailChecked = (result) => {
    if (result && !result.error && result.data.value == this.state.email) {
      this.setState({
        emailCheckStatus: CheckStatus.CHECKED,
        emailWarning: "",
      });
    } else if (
      result &&
      result.error &&
      result.data.value == this.state.email
    ) {
      this.setState({
        emailCheckStatus: CheckStatus.WARNING,
        emailWarning: "이미 사용중인 이메일주소입니다.",
      });
    } else {
      log(LogLevel.UI_DATA_LOAD, result);
    }
    log(
      LogLevel.UI_DATA_LOAD,
      "RegisterStage2:onEmailChecked",
      result,
      this.state.email,
      this.state.emailCheckStatus
    );
  };

  onEmailChange = (e) => {
    let email = e.target.value;
    this.setState({ email });
    log(
      LogLevel.UI_ACTION,
      "RegisterStage2:onEmailChange",
      email,
      this.state.email
    );
    this.validateEmail(email);
  };

  sameUserId = 0;
  checkSameUser = () => {
    if (
      !this.props.private &&
      this.state.licenseCheckStatus == CheckStatus.WARNING
    ) {
      fetchAPI(API.USER_CHECK_REGISTRATION, "", null, {
        name: this.props.user.name,
        phone: this.props.user.phone,
        license: this.state.license,
        email: this.state.email,
      }).then((result) => {
        log(LogLevel.UI_ACTION, "RegisterStage2:checkSameUser", result);
        if (
          result &&
          result.error == ErrorCode.ERROR_DUPLICATED &&
          result.data
        ) {
          this.sameUserId = result.data;
          this.props.confirmPopup.show({
            title: (
              <div className="common-container">
                <span className="common-bold">가입 정보가 있습니다.</span>
              </div>
            ),
            body: (
              <div className="common-container">
                기존에 사용하던 계정으로 로그인하시겠습니까?
              </div>
            ),
            onDone: async () => {
              this.props.waitingPopup.show();
              let newUser: UserInfo = {
                id: this.sameUserId,
                phone: this.props.user.phone,
                email: this.state.email,
                snsType: this.props.user.snsType,
                snsId: this.props.user.snsId,
              };
              await fetchAPI(API.USER_ADD_AUTH, "", null, newUser);

              let login: any = {};
              if (this.props.user.snsType == UserSNSType.KAKAO)
                login.kakaoId = this.props.user.snsId;
              else if (this.props.user.snsType == UserSNSType.APPLE)
                login.appleId = this.props.user.snsId;
              let result: any = await fetchAPI(
                API.LOGIN_REQUEST,
                "",
                null,
                login
              );
              if (result && result.message && result.message == "success") {
                let token = result.data.token;
                setGlobal(GlobalKey.TOKEN, token, true);
                this.props.setToken(token);

                result = await fetchAPI(
                  API.USER_ME_REQUEST,
                  "",
                  null,
                  null,
                  token
                );
                if (result && !result.error) {
                  log(
                    LogLevel.UI_DATA_LOAD,
                    "RegisterStage2:checkSameUser:onDone yc login",
                    result
                  );
                  try {
                    this.props.setMe(result.data);
                    windowAny.amplitude
                      .getInstance()
                      .setUserId(result.data.id.toString());
                    windowAny.amplitude.getInstance().setUserProperties({
                      nickname: result.data.nickname,
                      level: result.data.level,
                    });
                    AnalyticsUtil.event(
                      AnalyticsUtil.TYPE_ALL,
                      "LOGIN",
                      "로그인"
                    );
                    this.props.history.replace("/");
                    // this.setState({redirection:`/`});
                  } catch (e) {
                    log(LogLevel.UI_EXCEPTION, "RegisterStage2", e);
                  }
                } else {
                  log(
                    LogLevel.UI_DATA_LOAD,
                    "RegisterStage2: yc login: no me data"
                  );
                }
              }
              this.props.waitingPopup.hide();
            },
            doneText: "로그인하기",
          });
        }
      });
    }
  };

  onClickWorkPlace = (e) => {
    log(LogLevel.NONE, e);
    // this.setState({email: e.detail.value});
    let option = "manual";
    if (
      this.state.workType == UserWorkType.PHARMACY_OWNER ||
      this.state.workType == UserWorkType.PHARMACY_EMPLOYED
    )
      option = "pharmacy";
    timerStart(TimerId.TIMER_SUB);
    let rvalue = {
      license: this.state.license,
      email: this.state.email,
      workType: this.state.workType,
    };
    this.props.onAddressSearch(rvalue);
    // this.popupPharmacySelect.show(option);
  };

  onPressType = (type: number) => {
    this.setState({ workType: type });
  };

  onChat = async () => {
    let os = getGlobal(GlobalKey.OS);
    if (!os || os == "browser") {
      try {
        windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
      try {
        windowAny.Kakao.PlusFriend.chat({
          plusFriendId: "_Vxjhxgj", // 플러스친구 홈 URL에 명시된 id로 설정합니다.
        });
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
    } else {
      let plusFriendTemplate = {
        plusFriendId: "_Vxjhxgj",
      };
      try {
        await windowAny.KakaoCordovaSDK.chatPlusFriend(plusFriendTemplate);
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
    }
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "RegisterStage2:render", this.props.user);
    let attachmenet;
    let os = getGlobal(GlobalKey.OS);

    let workPlace: string = "";
    if (this.props.user.workPlaceRegion)
      workPlace = this.props.user.workPlaceRegion + " ";
    if (this.props.user.workPlaceName)
      workPlace += this.props.user.workPlaceName;

    let workPlaceInput;
    if (this.state.workType != UserWorkType.LEAVE_OF_ABSENCE)
      workPlaceInput = (
        <DynamicCheckerInput
          title="근무지"
          status={this.state.workPlaceCheckStatus}
          warnings={this.state.workPlaceWarning}
          input={workPlace}
          onClick={this.onClickWorkPlace}
          type="text"
        />
      );

    return (
      <div
        className="register2-container"
        ref={(ref) => {
          this.contentScroll = ref;
        }}
      >
        <div className="register-progress-container">
          <div className="register-progress-done">1</div>
          <div className="register-progress-done">2</div>
          <div className="register-progress-done">3</div>
          <div className="register-progress-undone">4</div>
          <div className="register-progress-undone">5</div>
        </div>
        <div className="register2-service-description">
          회원정보는 약사인증, 맞춤형 정보제공을 위한 정보이며,
          <br /> 타회원에게 공개되지 않습니다.
        </div>
        <div className="register-chat-guide">
          <img
            className="register-chat-kakao"
            src={kakaoIcon}
            onClick={this.onChat}
          />
        </div>
        <div className="register2-body-container">
          <div className="register-title">회원정보</div>
          <div className="register2-item-container">
            <div className="register2-item-name">이&nbsp;&nbsp;름</div>
            <div className="register2-item-value-diabled">
              {this.props.user.name}
            </div>
          </div>
          <div className="register2-item-container">
            <div className="register2-item-name">연락처</div>
            <div className="register2-item-value-diabled">
              {this.props.user.phone}
            </div>
          </div>
          <DynamicCheckerInput
            title="면허번호"
            messages="면허번호는 암호화되어 식별불가하게 보관됩니다."
            status={CheckStatus.CHECKED}
            warnings={""}
            input={this.state.license}
            onChange={this.onLicenseChange}
            type="number"
            onBlur={this.checkSameUser}
            withoutChecker={true}
          />
          <DynamicCheckerInput
            title="이메일"
            status={this.state.emailCheckStatus}
            warnings={this.state.emailWarning}
            input={this.state.email}
            onChange={this.onEmailChange}
            type="email"
            onBlur={this.checkSameUser}
          />
        </div>
        <div className="register2-body-container">
          <div className="register-title">근무정보</div>
          <div className="register2-title-description">
            약사회 공지 등{" "}
            <span className="register2-title-description-highlight">
              맞춤형 정보제공
            </span>
            에 사용됩니다.
          </div>
          <div className="register2-work-type-container">
            <div
              className={
                this.state.workType == 1
                  ? "register2-work-type-selected"
                  : "register2-work-type-unselected"
              }
              onClick={() => this.onPressType(UserWorkType.PHARMACY_OWNER)}
            >
              개국약사
            </div>
            <div
              className={
                this.state.workType == 2
                  ? "register2-work-type-selected"
                  : "register2-work-type-unselected"
              }
              onClick={() => this.onPressType(UserWorkType.PHARMACY_EMPLOYED)}
            >
              근무약사
            </div>
            <div
              className={
                this.state.workType == 3
                  ? "register2-work-type-selected"
                  : "register2-work-type-unselected"
              }
              onClick={() => this.onPressType(UserWorkType.HOSPITAL)}
            >
              병원근무
            </div>
            <div
              className={
                this.state.workType == 4
                  ? "register2-work-type-selected"
                  : "register2-work-type-unselected"
              }
              onClick={() =>
                this.onPressType(UserWorkType.PHARMACEUTICAL_COMPANY)
              }
            >
              제약사
            </div>
            <div
              className={
                this.state.workType == 5
                  ? "register2-work-type-selected"
                  : "register2-work-type-unselected"
              }
              onClick={() => this.onPressType(UserWorkType.GOVERMENT)}
            >
              정부기관
            </div>
            <div
              className={
                this.state.workType == 6
                  ? "register2-work-type-selected"
                  : "register2-work-type-unselected"
              }
              onClick={() => this.onPressType(UserWorkType.DISTRIBUTOR)}
            >
              유통사
            </div>
            <div
              className={
                this.state.workType == 7
                  ? "register2-work-type-selected"
                  : "register2-work-type-unselected"
              }
              onClick={() => this.onPressType(UserWorkType.LEAVE_OF_ABSENCE)}
            >
              휴직
            </div>
            <div
              className={
                this.state.workType == 10
                  ? "register2-work-type-selected"
                  : "register2-work-type-unselected"
              }
              onClick={() => this.onPressType(UserWorkType.GRADUATE)}
            >
              대학원 재학
            </div>
            <div
              className={
                this.state.workType == 8
                  ? "register2-work-type-selected"
                  : "register2-work-type-unselected"
              }
              onClick={() => this.onPressType(UserWorkType.ETC)}
            >
              기타
            </div>
          </div>
          {workPlaceInput}
        </div>
        <div className="register-navigate-container">
          <IonButton color="register-navigate-inactive" onClick={this.onBack}>
            &lt; 이전 단계로
          </IonButton>
          <div className="register-navigate-space" />
          <IonButton
            color={
              this.canPass()
                ? "register-navigate-active"
                : "register-navigate-inactive"
            }
            onClick={this.onDone}
          >
            다음 단계로 &gt;
          </IonButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
  setMe: (user: UserInfo) => actions.user.setMe(user),
  setToken: (token: string) => actions.user.setToken(token),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterStage2)
);
