import React, { Component } from "react";
import "./../../../Common.css";
import "./../../Admin.scss";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonCheckbox,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import axios from "axios";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout, async } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  AllInOnePartnerInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  UserPermanentType,
  UserWorkType,
  AllInOnePartnerLevel,
  CompanyType,
  CompanyTypeName,
  PARTNERS_TEMPLATE,
  UserInfo,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import { actions, RootState } from "../../../store";
import { connect } from "react-redux";
import { UIPopupType } from "../../../store/ui/types";
import {
  BoardAttribute,
  BoardContent,
  BoardContentQuiz,
  DailyQuiz,
} from "../../../models/Model.Board";
import {
  getDateStringFromToday,
  getDateTimeString,
} from "../../../utils/TimeUtil";
import StringUtil from "../../../utils/StringUtil";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import { JobOfferName } from "../../../models/Model.JobPost";
import Button from "../../../components/atoms/Button";
import * as API from "../../../API.json";
import parse from "html-react-parser";
import ReactDOMServer from "react-dom/server";
import { loadImageBase64, loadImageUrlBase64 } from "../../../utils/ImageUtil";
import ImageViewNSelect, { ImageSize } from "../ImageViewNSelect";
import HtmlComposer from "../../../components/HtmlComposer";
import Textarea from "react-textarea-autosize";
import BoardSummary from "../../../components/templates/BoardSummary";
import { Flex, Static } from "../../../components/atoms/Layout";
import { MY_ADDRESS } from "../../../config.json";
import Text from "../../../components/atoms/Text";
import { COLOR_SYSTEM } from "../../../components/design/design-system";
import Separator from "../../../components/atoms/Separator";
import ToggleButton from "../../../components/atoms/ToggleButton/ToggleButton";
import Viewer from "../../../pages/Viewer";
import BoardQuizView from "../../../components/templates/BoardQuizView";
import Input from "../../../components/atoms/Input";
import { on } from "cluster";
import { getDate } from "date-fns";
import Icon from "../../../components/atoms/Icon";
import {
  MessageType,
  ScheduleMessageInfo,
  ScheduleState,
} from "../../../models/Model.User.Message";
import EventSource from "eventsource";

const AdminAPI = {
  MESSAGE_POST: {
    method: "POST",
    path: "/admin/notification/message",
    contentType: "application/json",
  },
  MESSAGE_PUT: {
    method: "PUT",
    path: "/admin/notification/message",
    contentType: "application/json",
  },
  MESSAGE_GET: {
    method: "GET",
    path: "/admin/notification/message",
    contentType: "application/json",
  },
  USER_CNT_GET: {
    method: "POST",
    path: "/admin/statistic/user",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: (email: ScheduleMessageInfo) => void;
    email: ScheduleMessageInfo;
    onCancel: () => void;
    onPreview: (email: ScheduleMessageInfo) => void;
    onChangeBody: (email: ScheduleMessageInfo) => void;
  };

type State = ScheduleMessageInfo & {
  scheduledAtWarning: boolean;
  eventSource: EventSource;
};

const fields = [
  "id",
  "region1",
  "region2",
  "workType",
  "licensedAt",
  "birthyear",
  "gender",
  "agreeMarketing",
];

const conditions = [
  "EQ",
  "NEQ",
  "IN",
  "NIN",
  "GT",
  "GTE",
  "LT",
  "LTE",
  "LIKE",
  "NULL",
  "NOTNULL",
];

const BIZ_EMAIL_SERVER = "http://localhost:3002";
class EmailDetail extends Component<Props, State> {
  state: State = { scheduledAtWarning: false, eventSource: null };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): void {
    if (this.props.email && this.props.email.id != this.state.id) {
      this.setState({
        ...this.props.email,
        scheduledAt: this.props.email.scheduledAt
          ? getDateStringFromToday({ date: this.props.email.scheduledAt })
          : "",
        scheduledAtWarning: false,
      });
    }
  }

  componentWillUnmount(): void {}

  componentDidUpdate(prevProps, nextState) {
    if (
      this.props.email &&
      (this.props.email.id != this.state.id ||
        !prevProps ||
        !prevProps.email ||
        prevProps.email != this.props.email ||
        this.props.email.id != prevProps.email.id)
    ) {
      log(
        LogLevel.UI_ACTION,
        "EmailDetail:componentDidUpdate",
        this.props.email
      );

      this.setState({
        ...this.props.email,
        scheduledAt: this.props.email.scheduledAt
          ? getDateStringFromToday({ date: this.props.email.scheduledAt })
          : "",
        scheduledAtWarning: false,
      });
    }
  }

  onCreateSchedule = async () => {
    let email = {
      ...this.props.email,
      ...this.state,
      type: MessageType.EMAIL_MESSAGE,
      id: 0,
    };

    log(LogLevel.UI_ACTION, "EmailDetail:onSend", email);
    this.props.onDone(email);
  };

  watchProgress = async () => {
    log(
      LogLevel.UI_ACTION,
      "EmailDetail:watchProgress",
      this.state.eventSource
    );
    if (!this.state.eventSource) {
      let headers = {
        Authorization: "Bearer " + getGlobal(GlobalKey.TOKEN),
      };

      let eventSource = new EventSource(
        `${BIZ_EMAIL_SERVER}/mailer/schedule/email/status/${this.state.id}`,
        { headers: headers }
      );

      eventSource.onmessage = (event) => {
        log(LogLevel.UI_EVENT, "EmailDetail:eventSource.onmessage", event.data);
        if (event.data == undefined) {
          return;
        } else if (event.data == "###NOT_FOUND###") {
          eventSource.close();
          this.setState({ state: ScheduleState.STOPPED, eventSource: null });
        } else if (event.data == "###FINISH###") {
          eventSource.close();
          this.setState({ eventSource: null });
          this.props.onDone(this.state);
        } else {
          let data = JSON.parse(event.data);
          this.setState({
            ...data,
          });
        }
      };

      eventSource.addEventListener("error", (event) => {
        log(LogLevel.UI_EVENT, "EmailDetail:eventSource.onmessage", event.data);
        eventSource.close();
        this.setState({ eventSource: null });
      });
      this.setState({ eventSource });
    }
  };

  stopWatchProgress = () => {
    if (this.state.eventSource) {
      this.state.eventSource.close();
      this.setState({ eventSource: null });
    }
  };

  onCopy = async () => {
    let email = { ...this.props.email, ...this.state };
    email.id = 0;
    email.state = ScheduleState.NONE;
    email.memo = `${getDateStringFromToday()}: ${this.props.me.name}이 ${
      this.props.me.id
    } 이메일 복사`;
    this.props.onDone(email);
  };

  onStop = async () => {
    if (!this.state.id) return;
    await axios.delete(
      `${BIZ_EMAIL_SERVER}/mailer/schedule/email/stop/${this.state.id}`,
      {
        headers: {
          Accept: "application/json",
          //   'Content-Type': api.contentType,
          Origin: "",
          Authorization: "Bearer " + getGlobal(GlobalKey.TOKEN, true),
        },
      }
    );
    let email = { ...this.props.email, ...this.state };
    email.memo =
      email.memo +
      `${email.memo ? "\n" : ""}${getDateStringFromToday({
        showTime: true,
      })}: ${this.props.me.name}이 정지`;
    email.state = ScheduleState.STOPPED;
    let result = await fetchAPI(
      AdminAPI.MESSAGE_PUT,
      "",
      null,
      email,
      getGlobal(GlobalKey.TOKEN)
    );
    this.props.onDone(email);
  };

  onSend = async () => {
    if (!this.state.id) return;

    let email = { ...this.props.email, ...this.state };
    log(LogLevel.UI_ACTION, "EmailDetail:onSend", email);
    email.memo =
      email.memo +
      `${email.memo ? "\n" : ""}${getDateStringFromToday({
        showTime: true,
      })}: ${this.props.me.name}이 즉시발송`;
    try {
      let result = await fetchAPI(
        AdminAPI.MESSAGE_PUT,
        "",
        null,
        email,
        getGlobal(GlobalKey.TOKEN)
      );

      await axios.post(
        `${BIZ_EMAIL_SERVER}/mailer/schedule/email/start/${email.id}`,
        email,
        {
          headers: {
            Accept: "application/json",
            //   'Content-Type': api.contentType,
            Origin: "",
            Authorization: "Bearer " + getGlobal(GlobalKey.TOKEN, true),
          },
        }
      );

      this.watchProgress();
      // if (result && !result.error && result.data) email.id = result.data;

      // result = await fetchAPI(
      //   AdminAPI.MESSAGE_GET,
      //   "",
      //   { id: email.id },
      //   null,
      //   getGlobal(GlobalKey.TOKEN)
      // );
      // if (result && !result.error && result.data)
      //   this.props.onDone(result.data);
    } catch (e) {
      this.props.toastPopup.show(`저장에 실패했습니다. ${e.message}`);
    }
  };

  onSave = async () => {
    let email = { ...this.props.email, ...this.state };
    log(LogLevel.UI_ACTION, "EmailDetail:onSave", email);

    email.memo =
      email.memo +
      `${email.memo ? "\n" : ""}${getDateStringFromToday()}: ${
        this.props.me.name
      }이 저장`;
    this.props.progressPopup.show();
    let api;
    if (!this.state.id) {
      api = AdminAPI.MESSAGE_POST;
    } else {
      api = AdminAPI.MESSAGE_PUT;
    }

    if (!api) return;

    try {
      let result = await fetchAPI(
        api,
        "",
        null,
        email,
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && !result.error && result.data) email.id = result.data;

      result = await fetchAPI(
        AdminAPI.MESSAGE_GET,
        "",
        { id: email.id },
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && !result.error && result.data)
        this.props.onDone(result.data);
    } catch (e) {
      this.props.toastPopup.show(`저장에 실패했습니다. ${e.message}`);
      return false;
    }

    log(LogLevel.UI_ACTION, "EmailDetail:onSave updatedResult", email);

    this.props.progressPopup.hide();
    return true;
  };

  previewUpdateTimer = null;

  render() {
    log(LogLevel.UI_ACTION, "EmailDetail render", this.props.email, this.state);

    return (
      <div className="admin-container">
        {this.props.onCancel && (
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start" class="common-container">
                <IonButton
                  class="common-header-toolbar-button"
                  onClick={this.props.onCancel}
                >
                  <IonIcon name="arrow-back" mode="ios" />
                </IonButton>
                <IonLabel class="common-header-title common-flex-grow">
                  이메일
                  {this.props.email.type == MessageType.EMAIL_TEMPLATE
                    ? " 템플릿 "
                    : " "}
                  상세보기
                </IonLabel>
                <ToggleButton
                  onClick={() => {
                    this.previewUpdateTimer = setTimeout(() => {
                      this.props.onPreview(this.state);
                      this.previewUpdateTimer = null;
                    }, 10);
                  }}
                  size={"Small"}
                  type={"Text"}
                  variant={"Tinted"}
                  color={"Black"}
                  style={{ marginRight: "10px" }}
                  active={!!this.props.onChangeBody}
                >
                  미리보기
                </ToggleButton>
                {}
                <Button
                  onClick={this.onSave}
                  size={"Small"}
                  type={"Text"}
                  variant={"Outlined"}
                  color={"Primary"}
                  disabled={
                    this.state.state == ScheduleState.SENDING ||
                    this.state.state == ScheduleState.FINISHED ||
                    this.state.state == ScheduleState.STOPPED
                  }
                  disabledColor={
                    this.state.state == ScheduleState.SENDING ||
                    this.state.state == ScheduleState.FINISHED ||
                    this.state.state == ScheduleState.STOPPED
                  }
                >
                  저장하기
                </Button>
                {this.props.email.id > 0 &&
                  this.props.email.type == MessageType.EMAIL_TEMPLATE && (
                    <Button
                      onClick={this.onCreateSchedule}
                      size={"Small"}
                      type={"Text"}
                      variant={"Outlined"}
                      color={"Primary"}
                    >
                      발송하기
                    </Button>
                  )}
                {this.props.email.id > 0 &&
                  this.state.type == MessageType.EMAIL_MESSAGE &&
                  this.state.state != ScheduleState.SENDING &&
                  this.state.state != ScheduleState.FINISHED && (
                    <Button
                      onClick={this.onSend}
                      size={"Small"}
                      type={"Text"}
                      variant={"Outlined"}
                      color={"Primary"}
                    >
                      즉시발송
                    </Button>
                  )}
                {this.props.email.id > 0 && (
                  <Button
                    onClick={this.onCopy}
                    size={"Small"}
                    type={"Text"}
                    variant={"Outlined"}
                    color={"Secondary"}
                  >
                    복사
                  </Button>
                )}
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <Static>
          <Text textType="Body1SemiBold" color={COLOR_SYSTEM.get("Gray")[900]}>
            Id: {this.state.id} <br />
          </Text>
        </Static>
        {this.props.email.type == MessageType.EMAIL_MESSAGE && (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            customStyle={{
              marginBottom: "10px",
              padding: "10px",
              backgroundColor: COLOR_SYSTEM.get("Red")[10],
              borderRadius: "10px",
            }}
          >
            <Static>
              <Text
                textType="Body1SemiBold"
                color={COLOR_SYSTEM.get("Gray")[900]}
              >
                상태 : {ScheduleState[this.state.state]} <br />
              </Text>
              {(this.state.state == ScheduleState.SENDING ||
                this.state.state == ScheduleState.FINISHED ||
                this.state.state == ScheduleState.STOPPED) && (
                <Flex align="center" gap="8px">
                  <Text
                    textType="Body1SemiBold"
                    color={COLOR_SYSTEM.get("Gray")[900]}
                  >
                    전송 상태 : {this.state.sentUserCnt} /{" "}
                    {this.state.estimatedUserCnt}
                  </Text>
                  <ToggleButton
                    size={"Small"}
                    type="Icon"
                    variant="Ghost"
                    color="Blue"
                    icon="Eye"
                    active={!!this.state.eventSource}
                    onClick={() => {
                      if (this.state.eventSource) {
                        this.stopWatchProgress();
                      } else {
                        this.watchProgress();
                      }
                    }}
                  />
                </Flex>
              )}
              {!!this.state.createdAt && (
                <Text
                  textType="Body1SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[900]}
                >
                  생성 :{" "}
                  {getDateStringFromToday({
                    date: this.state.createdAt,
                    showTime: true,
                  })}{" "}
                  <br />
                </Text>
              )}
              {!!this.state.startedAt && (
                <Text
                  textType="Body1SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[900]}
                >
                  시작 :{" "}
                  {getDateStringFromToday({
                    date: this.state.startedAt,
                    showTime: true,
                  })}{" "}
                  <br />
                </Text>
              )}
              {!!this.state.endedAt && (
                <Text
                  textType="Body1SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[900]}
                >
                  완료 :{" "}
                  {getDateStringFromToday({
                    date: this.state.endedAt,
                    showTime: true,
                  })}{" "}
                  <br />
                </Text>
              )}
              {this.state.stoppedAt && (
                <Text
                  textType="Body1SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[900]}
                >
                  정지 :{" "}
                  {getDateStringFromToday({
                    date: this.state.stoppedAt,
                    showTime: true,
                  })}{" "}
                  <br />
                </Text>
              )}
            </Static>
            {this.state.state == ScheduleState.SENDING && (
              <Button
                size={"Medium"}
                type={"Text"}
                variant={"Outlined"}
                color={"Negative"}
                onClick={this.onStop}
              >
                중단하기
              </Button>
            )}
          </Flex>
        )}
        {this.props.email.type == MessageType.EMAIL_MESSAGE && (
          <Static
            customStyle={{
              padding: "10px",
              backgroundColor: COLOR_SYSTEM.get("Green")[10],
              borderRadius: "10px",
            }}
          >
            <Input
              label="예약발송일정(시간단위)"
              value={this.state.scheduledAt}
              placeholder={getDateStringFromToday({ showTime: true })}
              onChange={(e) => {
                let scheduledAtWarning = false;
                try {
                  const date = new Date(e.target.value);
                  if (isNaN(date.getTime())) {
                    scheduledAtWarning = true;
                  }
                } catch (e) {
                  scheduledAtWarning = true;
                }
                this.setState({
                  scheduledAt: e.target.value,
                  scheduledAtWarning,
                });
                this.setState({ scheduledAt: e.target.value });
              }}
              error={this.state.scheduledAtWarning}
            />
            <div className="common-container-row common-flex-align-center admin-margin-bottom">
              <div>Where Option</div>
              <Button
                size={"Small"}
                type={"Icon"}
                variant={"Contained"}
                color={"Primary"}
                icon="Plus"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  let condition = [...(this.state.condition || [])];
                  condition.push({
                    field: fields[0],
                    condition: conditions[0],
                    value: "",
                  });
                  this.setState({ condition });
                }}
              />
            </div>
            {this.state.condition &&
              this.state.condition.length > 0 &&
              this.state.condition.map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    className="common-container-row admin-margin-bottom"
                  >
                    <select
                      onChange={(e) => {
                        let condition = this.state.condition;
                        condition[index] = {
                          ...condition[index],
                          field: e.target.value,
                        };
                        this.setState({ condition });
                      }}
                      defaultValue={item.field}
                    >
                      {fields.map((item2, index2) => (
                        <option
                          key={index2.toString()}
                          value={item2}
                          selected={item2 == item.field}
                        >
                          {item2}
                        </option>
                      ))}
                    </select>
                    <select
                      onChange={(e) => {
                        let condition = this.state.condition;
                        condition[index] = {
                          ...condition[index],
                          condition: e.target.value,
                        };
                        this.setState({ condition });
                      }}
                      defaultValue={item.condition}
                    >
                      {conditions.map((item2, index2) => (
                        <option
                          key={index2.toString()}
                          value={item2}
                          selected={item2 == item.condition}
                        >
                          {item2}
                        </option>
                      ))}
                    </select>
                    <input
                      value={this.state.condition[index].value}
                      onChange={(e) => {
                        let condition = this.state.condition;
                        condition[index] = {
                          ...condition[index],
                          value: e.target.value,
                        };
                        this.setState({ condition });
                      }}
                    />
                    <Button
                      size={"Small"}
                      type={"Icon"}
                      variant={"Contained"}
                      color={"Primary"}
                      icon="Minus"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        let condition = [...this.state.condition];
                        condition.splice(index, 1);
                        this.setState({ condition });
                      }}
                    />
                  </div>
                );
              })}
            <Flex align="center" gap="8px">
              <Text
                textType="Body1SemiBold"
                color={COLOR_SYSTEM.get("Gray")[900]}
              >
                예상 발송 인원:{" "}
                <Text
                  color={
                    COLOR_SYSTEM.get(
                      this.state.estimatedUserCnt === 0 ? "Red" : "Blue"
                    )[500]
                  }
                >
                  {this.state.estimatedUserCnt > 0
                    ? this.state.estimatedUserCnt
                    : this.state.estimatedUserCnt === 0
                    ? "0"
                    : "-"}
                </Text>
              </Text>
              <Button
                size={"Small"}
                type={"Icon"}
                variant={"Ghost"}
                color={"Primary"}
                icon="Refresh"
                onClick={async () => {
                  let result = await fetchAPI(
                    AdminAPI.USER_CNT_GET,
                    "",
                    null,
                    { where: this.state.condition, groupBy: [] },
                    getGlobal(GlobalKey.TOKEN)
                  );
                  if (
                    result &&
                    !result.error &&
                    result.data &&
                    result.data.length > 0
                  ) {
                    this.setState({ estimatedUserCnt: result.data[0].value });
                  }
                }}
              />
            </Flex>
          </Static>
        )}
        <Flex
          direction="column"
          gap="8px"
          customStyle={{ padding: "0px 20px" }}
        >
          <Text textType="Body1SemiBold" color={COLOR_SYSTEM.get("Gray")[900]}>
            Id: {this.state.id} <br />
          </Text>
          <Input
            label="이름"
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
          <Input
            label="제목"
            value={this.state.title}
            onChange={(e) => {
              this.setState({ title: e.target.value });
              if (!this.previewUpdateTimer && this.props.onChangeBody)
                this.previewUpdateTimer = setTimeout(() => {
                  this.props.onChangeBody &&
                    this.props.onChangeBody(this.state);
                  this.previewUpdateTimer = null;
                }, 500);
            }}
          />
          <Text textType="Body1SemiBold" color={COLOR_SYSTEM.get("Gray")[900]}>
            본문
          </Text>
          <Textarea
            style={{ width: "100%", maxHeight: "500px" }}
            value={this.state.body}
            onChange={(e) => {
              this.setState({ body: e.target.value });
              if (!this.previewUpdateTimer && this.props.onChangeBody)
                this.previewUpdateTimer = setTimeout(() => {
                  this.props.onChangeBody &&
                    this.props.onChangeBody(this.state);
                  this.previewUpdateTimer = null;
                }, 500);
            }}
          />

          <Input
            label="메모"
            value={this.state.memo}
            multiLine={true}
            onChange={(e) => this.setState({ memo: e.target.value })}
          />
        </Flex>
      </div>
    );

    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  me: state.user.me,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.partner.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps)(EmailDetail);
