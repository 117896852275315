import React, { ReactNode } from "react";
import { ButtonList, SearchBar, TopAppBarWrapper } from "./style";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import Button from "../../atoms/Button";
import TabBar, { Item } from "../TabBar";

type TitleType = "H1" | "H1Tabs" | "H4" | "Search" | "OFF";
type Mode = "inverse" | "default";

export interface TopAppBarProps {
  /** 제목 */
  title?: ReactNode;
  /** 제목 타입 */
  titleType?: TitleType;
  /** 왼쪽 버튼 리스트 */
  leftButtons?: React.ReactNode[];
  /** 오른쪽 버튼 리스트 */
  rightButtons?: React.ReactNode[];
  /** text 및 icon 색 모드 */
  mode?: Mode;

  /** titleType === "H1Tabs" 일 때 각 Tab 항목 */
  tabs?: Item[];

  /** 배경색 투명여부 */
  transparent?: boolean;
  /** titleType === "Search" 일 때 input 태그의 placeholder 속성 */
  searchPlaceholder?: string;
  /** titleType === "Search" || "H1Tabs" 일 때 input 태그의 value 속성 */
  value?: string;
  /** titleType === "Search" 일 때 input 태그의 change 이벤트 핸들러 */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  /** titleType === "Search" 일 때 value 값 clear 버튼 클릭 이벤트 핸들러 */
  onClear?: () => void;
  /** titleType === "Search" 일 때 폼 전송 이벤트 헨들러 */
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  justifyContent?: string;
}

const TopAppBar: React.FC<TopAppBarProps> = ({
  title,
  titleType,
  leftButtons = [],
  rightButtons = [],
  mode = "default",
  tabs,
  transparent = false,
  searchPlaceholder,
  value,
  justifyContent,
  onChange,
  onClear,
  onSubmit,
  onClick,
}) => {
  const render = () => {
    switch (titleType) {
      case "H1":
        return (
          <>
            {leftButtons.length > 0 && (
              <ButtonList>
                {leftButtons.map((leftButton, index) => (
                  <li key={index}>{leftButton}</li>
                ))}
              </ButtonList>
            )}
            <Text
              element="h1"
              textType="H1"
              color={COLOR_SYSTEM.get("Gray")[mode === "default" ? 700 : 0]}
              style={{ whiteSpace: "nowrap" }}
            >
              {title}
            </Text>
            {rightButtons.length > 0 && (
              <ButtonList>
                {rightButtons.map((rightButton, index) => (
                  <li key={index}>{rightButton}</li>
                ))}
              </ButtonList>
            )}
          </>
        );
      case "H1Tabs":
        if (!tabs || tabs.length === 0) return null;
        return (
          <>
            {leftButtons.length > 0 && (
              <ButtonList>
                {leftButtons.map((leftButton, index) => (
                  <li key={index}>{leftButton}</li>
                ))}
              </ButtonList>
            )}
            <TabBar
              size={"Small"}
              type={"Underlined"}
              items={tabs}
              scrollable={false}
            ></TabBar>
            {rightButtons.length > 0 && (
              <ButtonList>
                {rightButtons.map((rightButton, index) => (
                  <li key={index}>{rightButton}</li>
                ))}
              </ButtonList>
            )}
          </>
        );
      case "H4":
        return (
          <>
            {leftButtons.length > 0 && (
              <ButtonList>
                {leftButtons.map((leftButton, index) => (
                  <li key={index}>{leftButton}</li>
                ))}
              </ButtonList>
            )}
            <Text
              element="h4"
              textType="H4"
              color={COLOR_SYSTEM.get("Gray")[mode === "default" ? 700 : 0]}
              className="title"
              style={{ whiteSpace: "nowrap" }}
            >
              {title}
            </Text>
            {rightButtons.length > 0 && (
              <ButtonList>
                {rightButtons.map((rightButton, index) => (
                  <li key={index}>{rightButton}</li>
                ))}
              </ButtonList>
            )}
          </>
        );
      case "OFF":
        return (
          <>
            {leftButtons.length > 0 && (
              <ButtonList>
                {leftButtons.map((leftButton, index) => (
                  <li key={index}>{leftButton}</li>
                ))}
              </ButtonList>
            )}
            {rightButtons.length > 0 && (
              <ButtonList>
                {rightButtons.map((rightButton, index) => (
                  <li key={index}>{rightButton}</li>
                ))}
              </ButtonList>
            )}
          </>
        );
      case "Search":
        return (
          <>
            {leftButtons.length > 0 && (
              <ButtonList>
                {leftButtons.map((leftButton, index) => (
                  <li key={index}>{leftButton}</li>
                ))}
              </ButtonList>
            )}
            <SearchBar onSubmit={onSubmit}>
              <input
                type="text"
                placeholder={searchPlaceholder}
                value={value}
                onChange={onChange}
                onClick={onClick}
                className="search__input"
              />
              {value && (
                <Button
                  color="Quaternary"
                  icon="XFilled"
                  onClick={onClear}
                  size="Medium"
                  type="Icon"
                  variant="Ghost"
                  className="clear__button"
                />
              )}
            </SearchBar>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <TopAppBarWrapper
      titleType={titleType}
      mode={mode}
      transparent={transparent}
      justifyContent={justifyContent}
    >
      {render()}
    </TopAppBarWrapper>
  );
};

export default TopAppBar;
