import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { RootState } from "../store";
import { AuthStatus } from "./../store/user/types";
import { log, LogLevel } from "../utils/LogUtil";
import { UserLevel, UserWorkType } from "../models/Model.User";
import AnalyticsUtil from "./../utils/AnalyticsUtil";
import ABTestUtil, { ABTestFeature } from "./ABTestUtil";
import { displayTabbar } from "./JobPostUtil";
import { UIPopupType } from "../store/ui/types";
import Button from "../components/atoms/Button";

const windowAny: any = window;

type Props = RouteComponentProps &
  ReturnType<typeof mapStateToProps> & {
    component: any; //React.ComponentType<any>;
    path?: string | string[];
    location?: any;
    options?;
    test?: ABTestFeature;
    key?: string;
    // page that undergraduate user can not access
    notForUndergraduate?: boolean;
  };

class PRoute extends Component<Props> {
  render() {
    const Component = this.props.component;
    if (this.props.test && !ABTestUtil.isTest(this.props.test)) {
      log(
        LogLevel.UI_LIFECYCLE,
        "Private Routing : render not test",
        this.props.test,
        ABTestUtil.isTest(this.props.test)
      );
      return null;
    }
    const routeRender = (props: any) => {
      log(
        LogLevel.UI_LIFECYCLE,
        "Private Routing : routeRender",
        props,
        this.props.test,
        ABTestUtil.isTest(this.props.test)
      );
      switch (props.location.pathname) {
        case "/main/home":
          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "HOME_START", "홈진입");
          break;
        case "/main/boards":
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "BOARD_START",
            "게시판진입"
          );
          break;
        case "/main/search":
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "SEARCH_START",
            "검색진입"
          );
          break;
        case "/main/user":
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "PROFILE_START",
            "프로필진입"
          );
          break;
        case "/main/jobpost":
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "JOBPOST_START",
            "구인구직진입"
          );
          break;
        case "/main/allinone":
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "ALLINONE_START",
            "약사파트너스진입"
          );
          break;
      }

      if (this.props.user.authStatus == AuthStatus.AUTHENTICATED) {
        log(LogLevel.NONE, "Private Routing : logged in", props);
        if (
          this.props.notForUndergraduate &&
          this.props.user.me &&
          this.props.user.me.workType == UserWorkType.UNDERGRADUATE
        ) {
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "UNDERGRADUATES_FORBIDDEN",
            "대학생오접근",
            { pathname: props.location.pathname, search: props.location.search }
          );
          this.props.confirmPopup.show({
            iconImage: null,
            title: "약사회원만 이용할 수 있는 페이지입니다.",
            body: <span>이용하시기 위해서는 약사인증을 해주세요.</span>,
            buttons: [
              <Button
                color="Primary"
                size="Large"
                variant="Tinted"
                type="Text"
                style={{ flex: 1 }}
                onClick={() => {
                  this.props.confirmPopup.hide();
                  this.props.history.push("/license/check");
                }}
              >
                약사인증
              </Button>,
              <Button
                color="Tertiary"
                size="Large"
                variant="Contained"
                type="Text"
                style={{ flex: 1 }}
                onClick={this.props.confirmPopup.hide}
              >
                확인
              </Button>,
            ],
          });
          return (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }
        return React.createElement(Component, {
          ...props,
          ...this.props.options,
        });
      } else if (this.props.user.authStatus == AuthStatus.REGISTERING) {
        log(LogLevel.NONE, "Private Routing : registering ", props);
        return (
          <Redirect
            to={{
              pathname: "/registering",
            }}
          />
        );
      }
      log(LogLevel.NONE, "Private Routing : not logged in", this.props);
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              from:
                this.props.location.pathname +
                (this.props.location.search ? this.props.location.search : ""),
            },
          }}
        />
      );
    };
    return <Route render={routeRender.bind(this)} />;
  }
}

class RRoute extends Component<Props> {
  render() {
    const Component = this.props.component;
    const routeRender = (props: any) => {
      log(LogLevel.UI_LIFECYCLE, "Registering Routing : routeRender", props);

      if (this.props.user.authStatus == AuthStatus.REGISTERING) {
        log(LogLevel.NONE, "Registering Routing : REGISTERING", props);
        return React.createElement(Component, {
          ...props,
          ...this.props.options,
        });
      }
      log(LogLevel.NONE, "Registering Routing : not logged in", this.props);
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    };
    return <Route render={routeRender.bind(this)} />;
  }
}

class MRoute extends Component<Props> {
  render() {
    const Component = this.props.component;
    const routeRender = (props: any) => {
      log(LogLevel.UI_DATA_LOAD, "Manager Routing : routeRender", this.props);

      if (
        this.props.user.authStatus == AuthStatus.AUTHENTICATED &&
        this.props.user.me &&
        this.props.user.me.level >= UserLevel.MANAGER
      ) {
        log(LogLevel.UI_DATA_LOAD, "Manager : logged in", props);
        return React.createElement(Component, props);
      }
      log(
        LogLevel.UI_DATA_LOAD,
        "Manager : not logged in or not manager",
        this.props
      );
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              from: this.props.location.pathname,
              search: this.props.location.search,
            },
          }}
        />
      );
    };
    return <Route render={routeRender.bind(this)} />;
  }
}

class ARoute extends Component<Props> {
  render() {
    const Component = this.props.component;
    const routeRender = (props: any) => {
      log(LogLevel.NONE, "Admin Routing : routeRender", this.props);

      if (
        this.props.user.authStatus == AuthStatus.AUTHENTICATED &&
        this.props.user.me &&
        this.props.user.me.level >= UserLevel.ADMIN
      ) {
        log(LogLevel.NONE, "Admin  : logged in", props);
        return React.createElement(Component, props);
      }

      log(LogLevel.NONE, "Admin  : not logged in or not admin", this.props);
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              from: this.props.location.pathname,
              search: this.props.location.search,
            },
          }}
        />
      );
    };
    return <Route render={routeRender.bind(this)} />;
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
});

export const PrivateRoute = withRouter(connect(mapStateToProps)(PRoute));
export const ManagerRoute = withRouter(connect(mapStateToProps)(MRoute));
export const RegisteringRoute = withRouter(connect(mapStateToProps)(RRoute));
