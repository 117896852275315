import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";
import NoSearchedPost from "../components/organisms/Search/NoSearchedPost";
import NoSearchedMedicine from "../components/organisms/Search/NoSearchedMedicine";
import SearchedPostList from "../components/organisms/Search/SearchedPostList";
import SearchedMedicineList from "../components/organisms/Search/SearchedMedicineList";
import Spinner from "../components/atoms/Spinner";
import FloatingButton from "../components/atoms/FloatingButton";
import { RootState } from "../store";
import { BoardType } from "../store/board/types";
import Text from "../components/atoms/Text";
import { COLOR_SYSTEM } from "../components/design/design-system";
import SearchBar from "../components/organisms/Search/SearchBar";
import SearchTabBar from "../components/organisms/Search/SearchTabBar";
import { fetchAPI } from "../utils/APIUtil";
import API from "../API.json";
import { SearchKeywordType } from "../models/Model.Search";
import { getGlobal, GlobalKey } from "../utils/GlobalUtil";
import {
  fetchPost,
  onChangeKeyword,
  SearchType,
  setCategory,
  setCurrentSearchedKeyword,
  setHotKeywords,
  setSuggestedKeywords,
} from "../store/search";
import Tag from "../components/atoms/Tag";
import KeywordList from "../components/organisms/Search/KeywordList";
import Keyword from "../components/organisms/Search/Keyword";
import HotKeywordList from "../components/organisms/Search/HotKeywordList";
import AnalyticsUtil from "../utils/AnalyticsUtil";
import { UserWorkType } from "../models/Model.User";

const SearchPageLoadingLayout = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 20px);
`;

const SearchPageBottomFixedButtonBox = styled.div`
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

const HotKeywordListTemplateLayout = styled.div`
  height: calc(100% - 108px);
  padding: 40px 20px 0;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  overflow-y: auto;

  & > * + * {
    margin-top: 60px;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;

const SearchHeader = styled.header`
  width: 100%;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
`;

const Search: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const searchType: SearchType = useSelector(
    (state: RootState) => state.newSearch.searchType
  );
  const postSearchedList = useSelector(
    (state: RootState) => state.newSearch.post.searchedList
  );
  const medicineSearchedList = useSelector(
    (state: RootState) => state.newSearch.medicine.searchedList
  );
  const postSearchedKeyword = useSelector(
    (state: RootState) => state.newSearch.post.searchedKeyword
  );
  const suggestedKeywords = useSelector(
    (state: RootState) => state.newSearch.post.suggestedKeywords
  );
  const hotKeywords = useSelector(
    (state: RootState) => state.newSearch.post.hotKeywords
  );
  const isLoading = useSelector(
    (state: RootState) => state.newSearch.isLoading
  );

  const me = useSelector((state: RootState) => state.user.me);
  const handleClickRegister = useCallback(() => {
    history.push(`/new?category=${BoardType.QNA}`, {
      title: postSearchedKeyword,
    });
  }, [postSearchedKeyword]);

  const fetchHotKeywords = async () => {
    const hotKeywords = [
      {
        id: 1,
        keyword: "피임약",
      },
      {
        id: 2,
        keyword: "임산부",
      },
      {
        id: 3,
        keyword: "통풍",
      },
      {
        id: 4,
        keyword: "화상",
      },
      {
        id: 5,
        keyword: "유산균",
      },
      {
        id: 6,
        keyword: "아스피린",
      },
      {
        id: 7,
        keyword: "편두통",
      },
      {
        id: 8,
        keyword: "철분",
      },
      {
        id: 9,
        keyword: "비타민d",
      },
      {
        id: 10,
        keyword: "파스",
      },
      {
        id: 11,
        keyword: "ppi",
      },
      {
        id: 12,
        keyword: "다래끼",
      },
    ];

    try {
      const res = await fetchAPI(
        API.SEACH_KEYWORD_GET,
        "",
        { type: SearchKeywordType.HOT },
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      const { error } = res;
      dispatch(setHotKeywords(error ? hotKeywords : res.data));
    } catch (err) {
      dispatch(setHotKeywords(hotKeywords));
    }
  };

  const fetchSuggestedKeywords = async () => {
    try {
      const res = await fetchAPI(
        API.SEACH_KEYWORD_GET,
        "",
        { type: SearchKeywordType.RECOMMENDED_RESULTS },
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      const { error } = res;
      dispatch(setSuggestedKeywords(error ? [] : res.data));
    } catch (err) {
      dispatch(setSuggestedKeywords([]));
    }
  };

  const handleClickKeyword = (suggestedKeyword: any) => () => {
    try {
      if (suggestedKeyword && suggestedKeyword.advertiserCode) {
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "ADVERTISE_CLICK",
          "광고클릭",
          {
            type: "추천키워드",
            code: suggestedKeyword.advertiserCode,
            productCode: suggestedKeyword.advertiserProductCode,
            contentType: "KEYWORD",
            contentId: suggestedKeyword.id,
            contentTitle: suggestedKeyword.keyword,
          }
        );
      }
      dispatch(onChangeKeyword(suggestedKeyword.keyword));
      dispatch(setCurrentSearchedKeyword(suggestedKeyword.keyword));
      dispatch(fetchPost(true));
    } catch (err) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "Search_Error",
        "검색통합에러",
        {
          page: "Search",
          where: "Search/handleClickKeyword",
          errorMessage: err,
        }
      );
    }
  };

  useEffect(() => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "SEARCH_BOARD_START",
      "게시판검색진입",
      {}
    );
    fetchHotKeywords();
    fetchSuggestedKeywords();
  }, []);

  useEffect(() => {
    if (me && me.workType == UserWorkType.UNDERGRADUATE)
      dispatch(setCategory("학술"));
  }, [me]);

  if (isLoading) {
    return (
      <>
        <SearchHeader>
          <SearchBar />
          <SearchTabBar />
        </SearchHeader>
        <SearchPageLoadingLayout>
          <Spinner />
        </SearchPageLoadingLayout>
      </>
    );
  }

  return (
    <>
      <SearchHeader>
        <SearchBar />
        <SearchTabBar />
      </SearchHeader>

      {/* 게시물 검색 */}
      {searchType === "Post" &&
        (postSearchedList === null ? (
          <HotKeywordListTemplateLayout>
            {suggestedKeywords && !!suggestedKeywords.length && (
              <div>
                <div className="title">
                  <Text
                    textType="Body1SemiBold"
                    color={COLOR_SYSTEM.get("Gray")[900]}
                  >
                    추천 검색어
                  </Text>
                  <Tag variant="Contained" color="Black">
                    AD
                  </Tag>
                </div>
                <KeywordList>
                  {suggestedKeywords.map((suggestedKeyword) => (
                    <Keyword
                      key={suggestedKeyword.id}
                      {...suggestedKeyword}
                      onClick={handleClickKeyword(suggestedKeyword)}
                    />
                  ))}
                </KeywordList>
              </div>
            )}
            {hotKeywords && !!hotKeywords.length && <HotKeywordList />}
          </HotKeywordListTemplateLayout>
        ) : (
          <>
            {postSearchedList.length ? (
              <SearchedPostList />
            ) : (
              <NoSearchedPost />
            )}
            <SearchPageBottomFixedButtonBox>
              <FloatingButton
                color="Primary"
                size="Medium"
                type="Text"
                onClick={handleClickRegister}
              >
                찾는 내용이 없다면, 질문 등록
              </FloatingButton>
            </SearchPageBottomFixedButtonBox>
          </>
        ))}

      {/* 의약품 검색 */}
      {searchType === "Medicine" &&
        (medicineSearchedList === null ? (
          <NoSearchedMedicine />
        ) : !!medicineSearchedList.length ? (
          <SearchedMedicineList />
        ) : (
          <NoSearchedMedicine />
        ))}
    </>
  );
};

export default withRouter(Search);
