import { BoardComment, BoardContent } from "./Model.Board";
import { PremiumType, UserPremiumInfo } from "./Model.User.Premium";

export const UserInterests = [
  { name: "신경계", code: "N" },
  { name: "심혈관계", code: "C" },
  { name: "혈액 및 조혈계", code: "B" },
  { name: "호흡기계", code: "R" },
  { name: "소화기계", code: "A" },
  { name: "항감염제", code: "JP" },
  { name: "근골격계", code: "M" },
  { name: "내분비계", code: "H" },
  { name: "비뇨생식기계", code: "G" },
  { name: "항암제&면역계", code: "L" },
  { name: "피부", code: "D" },
  { name: "감각기계", code: "S" },
  { name: "기타", code: "V" },
];

export enum UserLevel {
  WAITING_USER = 0,
  DENIED_USER = 1,
  // RESTRICTED_USER = 2,
  NORMAL_USER = 3,
  // PREMIUM_USER = 4,
  // WAITING_BUSINESS_PARTNER = 100,
  // DENIED_BUSINESS_PARTNER = 101,
  // RESTRICTED_BUSINESS_PARTNER = 102,
  // NORMAL_BUSINESS_PARTNER = 103,
  // PREMIUM_BUSINESS_PARTNER = 104,

  MANAGER = 1000,
  ADMIN = 9999,
  DELETED = -1,
  ALL = -100,
  NOT_USER = -101,
  USER = -102,
  NOT_PARTNER = -103,
  PARTNER = -104,
  GOD = -105,
  USER_PHARMACIST = -106,
  USER_UNDERGRADUATE = -107,

  LICENSE_CHECK = -108,
  RESTRICTED = -109,

  IPHONE_USER = -201,
  ANDROID_USER = -202,

  LAZY_2WEEKS_NO_READ_USER = -301,
  DUPLICATED_USER = -401,
}

export enum UserState {
  NONE = 0,
  LICENSE_CHECK = 1, // 약사인증 회원
  RESTRICTED = 2, // 이용제한 회원
  BANNED = 3, // 영구정지 회원
}

export const UserLevelName = {
  [UserLevel.ALL]: "모든회원",
  [UserLevel.DELETED]: "탈퇴회원",
  [UserLevel.WAITING_USER]: "가입대기회원",
  [UserLevel.DENIED_USER]: "가입반려회원",
  [UserLevel.NORMAL_USER]: "일반사용자",
  [UserLevel.USER_PHARMACIST]: "약사회원",
  [UserLevel.USER_UNDERGRADUATE]: "약대생회원",
  // [UserLevel.PREMIUM_USER]: "유료회원",
  [UserLevel.RESTRICTED]: "제한회원",
  [UserLevel.LICENSE_CHECK]: "면허인증회원",
  [UserLevel.MANAGER]: "운영자",
  [UserLevel.ADMIN]: "관리자",
};

/* GUIDE - User Info & State
  - level : WAITING_USER 0 => 가입대기 회원 (약문약답 사용제한)
      state : LICENSE_CHECK 0x0001 => 면허확인중

  - level : DENIED_USER 1 => 가입반려 회원 (약문약답 사용제한)
      state : LICENSE_CHECK 0x0001 => 면허재확인 필요 
        workType = UNDERGRADUATE 인 경우, 약사인증 or 학생증 인증 선택 가능
        그 외에는 약사인증만 가능
      state : RESCTRICTED, BANNED 0x0004 => 이용정지 회원, 탈퇴기능 미지원, 상담사 연결 가능

  - level : NORMAL_USER 3 => 일반사용자 (약문약답 사용가능)
      state : LICENSE_CHECK 0x0001 && Worktype = UNDERGRADUATE => 약사면허따서 약사인증을 한 경우
        인증이 되면, State NONE 에 WorkType 휴직으로 변경
*/

export enum UserType {
  ALL = 0,
  YMYD = 1,
  BIZ_JOBPOST = 2,
  BIZ_ALLINONE = 3,
  BIZ_CC = 4,
}

export const UserTypeName = [
  "모든회원",
  "일반회원",
  "구인구직회원",
  "파트너스회원",
  "다제약물매니저",
];

export const UserWorkTypeName = [
  "없음",
  "개국약사",
  "근무약사",
  "병원근무",
  "제약회사",
  "정부기관",
  "도매상",
  "휴직",
  "기타",
  "약대생",
  "대학원생",
  "파트너(임시)",
];

export enum UserWorkType {
  PHARMACY_OWNER = 1,
  PHARMACY_EMPLOYED = 2,
  HOSPITAL = 3,
  PHARMACEUTICAL_COMPANY = 4,
  GOVERMENT = 5,
  DISTRIBUTOR = 6,
  LEAVE_OF_ABSENCE = 7,
  ETC = 8,
  UNDERGRADUATE = 9,
  GRADUATE = 10,
  TEMPORORY_PARTNER = 11,
}

export enum NotiSetting {
  REPLY_ON_MY_POST = "0",
  REPLY_ON_SCRAP = "1",
  REPLY_ON_LIKE = "2",
}

export enum NotiOption {
  NONE = "-",
  ALL = "ABCDEIJKLMPQ", //"ABCDEFGHIJKLMOPQ" - "FGHO",
  MY_POST = "ABCDE",
  MY_POST_REPLY = "A",
  MY_POST_COMMENT = "B",
  MY_POST_LIKE = "C",
  MY_POST_SHARE = "D",
  MY_POST_SCRAP = "E",
  MY_INTEREST_POST = "F",
  MY_LIKE = "GH",
  MY_LIKE_REPLY = "G",
  MY_LIKE_COMMENT = "H",
  MY_SCRAP = "IJ",
  MY_SCRAP_REPLY = "I",
  MY_SCRAP_COMMENT = "J",
  ALL_QNA = "KLMOP",
  ALL_QUESTION = "K",
  ALL_ANSWER = "L",
  ALL_POSTING = "M",
  MY_COMMENT = "O",
  MY_COMMENT_REPLY = "O",
  ALL_CONSULT_QUESTION = "P",
  ALL_CONSULT_ANSWER = "Q",
}

export enum RegisterRoute {
  NONE = "-", //
  EXPO = "A", // 팜엑스포
  NEWS = "B", // 온라인 뉴스 (약사공론 등)
  CONTENT = "C", // 카톡으로 공유받은 콘텐츠
  INVITE = "D", // 지인 초대/추천
  FACEBOOK = "F", // 페이스북
  KAKAOTALK = "K", // 노원구 부작용 카톡방 / 홈트리 BAND
  YSGL = "Y", // 약사공론 약문약답 콘텐츠
  DP_RECRUIT = "R", // 데일리팜 팜리크루트
  BAROPHARM = "P", // 바로팜
  ETC = "E", // 기타
}

export const REGISTER_ROUTE_NAME = {
  A: "팜엑스포",
  B: "온라인뉴스",
  C: "카톡공유콘텐츠",
  D: "지인초대",
  E: "기타",
  F: "페이스북",
  K: "부작용카톡방",
  Y: "약사공론약문약답",
  R: "팜리크루트",
  P: "바로팜",
};

export enum UserNudgeState {
  NOT_NUDGED = 0,
  NUDGED_1TIME = 1,
  // NUDGED_2TIME = 2,
  NUDGED_IGNORED = 2,
  NUDGED_ACCEPED = 10000,
}

export enum UserSNSType {
  KAKAO = 0,
  APPLE = 1,
}

export enum UserPermanentType {
  UNKNOWN = 0,
  NORMAL = 1,
  PERMANENT = 2,
}

export enum OrderContentType {
  PARNTERS = "partners",
  JOBPOST = "jobpost",
}

export const UserNudgeStages = [2, 6];

export interface UserInfo {
  id?: number;
  state?: UserState;
  level?: UserLevel;
  snsType?: UserSNSType;
  snsId?: string;
  email?: string;
  name?: string;
  nickname?: string;
  phone?: string;
  birthGender?: string;
  birth?: string;
  birthday?: string;
  gender?: string;
  workType?: number;
  license?: string;
  licensedAt?: any;
  // location?: number,
  profileUrl?: string;
  profileImageBase64?: any;

  workPlaceAddress?: string;
  workPlaceRegion?: string;
  workPlaceName?: string;
  workPlacePhone?: string;
  workPlacePosition?: any;
  workPlaceRegistration?: string;
  workPlaceRegistrationImageBase64?: any;
  workPlaceRegistrationUrl?: string;

  jobpostStatus?: string;
  jobpostDistance?: number; // 단위 : m
  homeRegion?: string;
  homeAddress?: string;
  homePosition?: any;

  description?: string;
  licenseImageBase64?: any;
  licenseUrl?: string;
  askCnt?: number;
  answerCnt?: number;
  scrappedCnt?: number;
  commentCnt?: number;
  viewCnt?: number;
  notiPush?: string;
  notiSocial?: string;
  managerMessage?: string;
  interest?: string;
  createdAt?: string;
  expiredAt?: string;
  lastJobPostAt?: string;
  agreeMarketingAt?: any;
  guided?: number;

  contractVersion?: string;

  permenent?: UserPermanentType; // 0:Unknown 1:normal 2:permanent

  isAccomplished?: boolean;
  registerRoute?: string;
  registerRouteEtc?: string;
  registerInvitor?: number;
  inviteCnt?: number;
  invitees?: UserInfo[];

  nudgeState?: UserNudgeState;
  likeCnt?: number;
  scrapCnt?: number;
  blocked?: boolean;

  wantToGo?: string;

  recievedComments?: BoardComment[];
  workTypeEditedAt?: string;

  uniqueKey?: string;

  premiumType?: PremiumType;

  premium?: UserPremiumInfo;

  memo?: string; // 관리자 메모
}

export enum AllInOnePartnerLevel {
  WAITING = 0,
  DENIED = 1,
  BASIC = 2,
  PRO = 3,
  PREMIUM = 4,
}

export enum CompanyType {
  ALL = -1,
  PHARMACY = 0,
  HOSPITAL = 1,
  PHARMACEUTICAL_COMPANY = 2,
  DISTRIBUTOR = 3,
  CONSULT = 4,
  FINANCE = 5,
  TAX = 6,
  FRENCHISE = 7,
  INTERIOR = 8,
  LAW = 9,
  LABOR = 10,
  EMR = 11,
  AUTOMATION = 12,
  INSURANCE = 13,
  SCHOLAR = 14,
  ETC = 15,
  HEALTH_FUNTIONAL_FOOD_COMPANY = 16,
  REAL_ASSET = 17,
  QUASI_DRUG = 18,
  EDUCATION = 19,
  LIFE = 20,
}

export const CompanyTypeName = [
  "약국",
  "병원",
  "제약사",
  "도매/몰",
  "개국컨설팅",
  "대출",
  "세무",
  "약국체인",
  "인테리어",
  "법률",
  "노무",
  "IT서비스",
  "자동화기기",
  "보험",
  "학회/기관",
  "기타",
  "건강기능식품",
  "부동산",
  "의약외품",
  "교육/판매방법",
  "라이프",
];

export interface AllInOnePartnerInfo {
  // id?: string;        // ID
  // userId?: number;    // 임시 글 작성자 ID
  // level?: AllInOnePartnerLevel;    // 회원 레벨(권한)
  // email?: string;     // email
  // name?: string;      // 회사 이름
  // phone?: string;     // 회사 전화번호
  // type?: CompanyType;      // 회사 종류
  // logoUrl?: string;

  // address?: string;
  // region?: string;
  // position?: any;

  // homepage?: string; // [NEW][법인] 회사 홈페이지
  // introductionText?: string; // [NEW][법인] 회사 소개
  // introductionHTML?: string;

  // posts: BoardContent[];

  oldId?: string; // ID
  id?: string; // ID

  usersId?: number; // 임시 글 작성자 ID
  // 자동으로 하나 생성해야함 ->
  level?: AllInOnePartnerLevel; // 회원 레벨(권한)

  userType?: UserType;
  email?: string; // email
  division?: string;

  // name?: string;      // 회사 이름
  companyName?: string;
  companyProfileName?: string;
  companyPhone?: string; // 회사 전화번호
  userName?: string; // 담당장 이름
  phone?: string; // 대표전화
  // type?: companyType;      // 회사 종류
  companyType?: CompanyType; //회사 종류

  // logoUrl?: string;
  logoImageUrl?: string; //로고 url
  logoImageBase64?: any;

  companyAddress?: string;
  companyRegion?: string;
  companyPosition?: any;
  companyRegiDoc?: any;
  companyRegiNum?: string;

  homepage?: string; // [NEW][법인] 회사 홈페이지
  introductionText?: string; // [NEW][법인] 회사 소개
  introductionHTML?: string;

  createdAt?: string;
  expireAt?: string;
  registerGrantedAt?: string;

  posts?: BoardContent[];

  phoneNumbers?: string;
}

export const PARTNERS_TEMPLATE: AllInOnePartnerInfo = {
  id: "", // ID

  usersId: 0, // 임시 글 작성자 ID
  // 자동으로 하나 생성해야함 ->
  level: AllInOnePartnerLevel.BASIC, // 회원 레벨(권한)

  userType: UserType.BIZ_ALLINONE,
  email: "", // email
  division: "",

  // name: "",      // 회사 이름
  companyName: "",
  companyPhone: "", // 회사 전화번호
  userName: "", // 담당장 이름
  phone: "", // 담당장 전화번호
  phoneNumbers: "",
  // type: companyType,      // 회사 종류
  companyType: CompanyType.AUTOMATION, //회사 종류

  // logoUrl: "",
  logoImageUrl: "", //로고 url
  logoImageBase64: "",

  companyAddress: "",
  companyRegion: "",
  companyPosition: { x: 0, y: 0 },

  homepage: "", // [NEW][법인] 회사 홈페이지
  introductionText: "", // [NEW][법인] 회사 소개
  introductionHTML: "",

  companyRegiDoc: "",
  companyRegiNum: "",

  createdAt: "",
  expireAt: "",

  posts: null,
};
