import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";

import { setGlobal, GlobalKey, getGlobal } from "../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import {
  UserLevel,
  UserWorkType,
  UserInfo,
  UserWorkTypeName,
  UserLevelName,
  AllInOnePartnerInfo,
} from "../models/Model.User";
import { fetchAPI } from "../utils/APIUtil";
import AllInOnePartnerManageProfile from "./component/allinone/AllInOnePartnerManageProfile";
import ProfileImage from "../components/ProfileImage";
import { threadId } from "worker_threads";
import AllInOnePartnerSelect from "./component/allinone/AllInOnePartnerSelect";
import ToggleSwitch from "../component/atom/ToggleSwitch";
import PremiumUsersSelect from "./component/premium/PremiumUsersSelect";
import PremiumUsersEdit from "./component/premium/PremiumUsersEdit";
import EmailSelect from "./component/message/EmailSelect";
import ToggleButton from "../components/atoms/ToggleButton/ToggleButton";
import { get } from "lodash";
import { MessageType, ScheduleMessageInfo } from "../models/Model.User.Message";
import EmailDetail from "./component/message/EmailDetail";
import EmailPreview from "./component/message/EmailPreview";

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  selected: ScheduleMessageInfo;
  previewEmail: ScheduleMessageInfo;
  showTemplates: boolean;
  showSchedules: boolean;
  showEdit: boolean;
  showPreview: boolean;
};

enum AdminEmailMenu {
  TEMPLATES = 0x01,
  SCHEDULES = 0x02,
  EDIT = 0x04,
  PREVIEW = 0x08,
}

class EmailManage extends Component<Props, State> {
  popup = null;
  list = null;

  state = {
    selected: null,
    previewEmail: null,
    showTemplates:
      (getGlobal("intAdminEmailMenu", true) & AdminEmailMenu.TEMPLATES) != 0,
    showSchedules:
      (getGlobal("intAdminEmailMenu", true) & AdminEmailMenu.SCHEDULES) != 0,
    showEdit: (getGlobal("intAdminEmailMenu", true) & AdminEmailMenu.EDIT) != 0,
    showPreview:
      (getGlobal("intAdminEmailMenu", true) & AdminEmailMenu.PREVIEW) != 0,
  };

  constructor(props: Props) {
    super(props);
    this.props.loadFilePath();
    document.title = "회원용 이메일 관리";
  }

  componentDidMount() {}

  onSelect = (email: ScheduleMessageInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", email);
    this.setState({ selected: email, showEdit: true });
  };

  onDone = (email: ScheduleMessageInfo) => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selected);
    if (this.list) this.list.refresh(email);
    this.setState({ selected: email });
  };

  onCancel = () => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selected);
    this.setState({ selected: null });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "EmailManage.render", this.props);

    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">회원용 이메일 관리</IonText>
          <IonButtons slot="end">
            <ToggleButton
              onClick={() => {
                this.setState({
                  showTemplates: !this.state.showTemplates,
                });
                setGlobal(
                  "intAdminEmailMenu",
                  this.state.showTemplates
                    ? getGlobal("intAdminEmailMenu", true) &
                        ~AdminEmailMenu.TEMPLATES
                    : getGlobal("intAdminEmailMenu", true) |
                        AdminEmailMenu.TEMPLATES,
                  true
                );
              }}
              size={"Small"}
              type={"Text"}
              variant={"Tinted"}
              color={"Skyblue"}
              style={{ marginRight: "10px" }}
              active={this.state.showTemplates}
            >
              이메일템플릿목록
            </ToggleButton>
            <ToggleButton
              onClick={() => {
                this.setState({
                  showSchedules: !this.state.showSchedules,
                });
                setGlobal(
                  "intAdminEmailMenu",
                  this.state.showSchedules
                    ? getGlobal("intAdminEmailMenu", true) &
                        ~AdminEmailMenu.SCHEDULES
                    : getGlobal("intAdminEmailMenu", true) |
                        AdminEmailMenu.SCHEDULES,
                  true
                );
              }}
              size={"Small"}
              type={"Text"}
              variant={"Tinted"}
              color={"Skyblue"}
              style={{ marginRight: "10px" }}
              active={this.state.showSchedules}
            >
              예약이메일목록
            </ToggleButton>
            <ToggleButton
              onClick={() => {
                this.setState({
                  showEdit: !this.state.showEdit,
                });
                setGlobal(
                  "intAdminEmailMenu",
                  this.state.showEdit
                    ? getGlobal("intAdminEmailMenu", true) &
                        ~AdminEmailMenu.EDIT
                    : getGlobal("intAdminEmailMenu", true) |
                        AdminEmailMenu.EDIT,
                  true
                );
              }}
              size={"Small"}
              type={"Text"}
              variant={"Tinted"}
              color={"Skyblue"}
              style={{ marginRight: "10px" }}
              active={this.state.showEdit}
              disabled={this.state.selected == null}
              disabledColor={this.state.selected == null}
            >
              이메일 상세
            </ToggleButton>
            <ToggleButton
              onClick={() => {
                this.setState({
                  showPreview: !this.state.showPreview,
                });
                setGlobal(
                  "intAdminEmailMenu",
                  this.state.showPreview
                    ? getGlobal("intAdminEmailMenu", true) &
                        ~AdminEmailMenu.PREVIEW
                    : getGlobal("intAdminEmailMenu", true) |
                        AdminEmailMenu.PREVIEW,
                  true
                );
              }}
              size={"Small"}
              type={"Text"}
              variant={"Tinted"}
              color={"Skyblue"}
              style={{ marginRight: "10px" }}
              active={this.state.showPreview}
              disabled={this.state.previewEmail == null}
              disabledColor={this.state.previewEmail == null}
            >
              이메일미리보기
            </ToggleButton>
          </IonButtons>
        </IonHeader>
        <IonContent>
          <div className="admin-main-container">
            {this.state.showTemplates && (
              <div className="admin-container">
                <EmailSelect
                  type={MessageType.EMAIL_TEMPLATE}
                  onSelect={this.onSelect}
                  onClose={() => {
                    this.setState({ showTemplates: false });
                  }}
                  email={this.state.selected}
                  ref={(ref) => {
                    this.list = ref;
                  }}
                />
              </div>
            )}
            {this.state.showSchedules && (
              <div className="admin-container">
                <EmailSelect
                  type={MessageType.EMAIL_MESSAGE}
                  onSelect={this.onSelect}
                  onClose={() => {
                    this.setState({ showSchedules: false });
                  }}
                  email={this.state.selected}
                  ref={(ref) => {
                    this.list = ref;
                  }}
                />
              </div>
            )}
            {this.state.selected && this.state.showEdit && (
              <div className="admin-container">
                <EmailDetail
                  email={this.state.selected}
                  onDone={this.onDone}
                  onCancel={() => {
                    this.setState({
                      selected: null,
                      previewEmail: null,
                      showEdit: false,
                      showPreview: false,
                    });
                  }}
                  onChangeBody={
                    this.state.previewEmail
                      ? (previewEmail) => {
                          this.setState({ previewEmail });
                        }
                      : null
                  }
                  onPreview={(previewEmail) => {
                    if (this.state.previewEmail)
                      this.setState({ previewEmail: null, showPreview: false });
                    else this.setState({ previewEmail, showPreview: true });
                  }}
                />
              </div>
            )}
            {this.state.previewEmail != null && this.state.showPreview && (
              <div className="admin-container">
                <EmailPreview
                  onCancel={() => {
                    this.setState({ previewEmail: null, showPreview: false });
                  }}
                  email={this.state.previewEmail}
                  me={this.props.user.me}
                />
              </div>
            )}
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadFilePath: () => actions.board.getFilePath(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmailManage)
);
