import React, { Component } from "react";
import { func } from "prop-types";
import { log, LogLevel } from "../utils/LogUtil";
import { RootState } from "../store";
import { connect } from "react-redux";
import { Reference } from "../models/Model.Board";
import { MY_ADDRESS } from "./../config.json";
import { withRouter, RouteComponentProps } from "react-router";
import { getGlobal, GlobalKey } from "../utils/GlobalUtil";
import { IonButton } from "@ionic/react";
import { timingSafeEqual } from "crypto";
import AnalyticsUtil from "./../utils/AnalyticsUtil";
import { sendKakaoInviteMessage } from "../utils/KakaoUtil";
import StringUtil from "../utils/StringUtil";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    src?: string;
  };

const windowAny: any = window;

class ComponentInvite extends Component<Props> {
  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "ComponentInvite: constructor ", props);
  }

  onInvite = () => {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "INVITE", "초대하기", {
      위치: "게시글",
    });
    sendKakaoInviteMessage(this.props.me);
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "ComponentInvite: render ", this.props);

    if (this.props.src) {
      return (
        <div className="common-container" onClick={this.onInvite}>
          <img
            className="component-banner-img"
            src={StringUtil.convertFilePath(this.props.src)}
          />
        </div>
      );
    }
    return <IonButton onClick={this.onInvite}>친구 초대</IonButton>;
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
});

const mapDispatchToProps = {};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ComponentInvite)
);
