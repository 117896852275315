import { CompanyType, UserInfo, UserType } from "./Model.User";
import SalaryUtil from "../utils/SalaryUtil";
import { log, LogLevel } from "../utils/LogUtil";
import { Attachment } from "./Model.Board";
import { BizUserInfo, OrderDetail } from "./Mode.Biz";

// dev
// export const JobOfferAlimFreeMax = 2; // 100;
// export const JobOfferAlimPrice = 100; // 20;
// export const JobOfferAlimMin = 1; // 5;

// rel
export type PostSort = "raised" | "distance";

export const welfareTagsName = [
  "식비 지원",
  "주차 가능",
  "성과급",
  "상여금",
  "사택 제공",
  "경조사 지원",
  "유류비 지원",
];
export const softwareNames = ["팜IT 3000", "PM+20", "유팜", "비즈팜", "기타"];
export const ATCNames = ["JVM", "유팜오토팩", "없음"];
export const machineNames = [
  "자동정제반절기",
  "산제자동포장기",
  "PTP제포기",
  "계수기",
  "기타",
];

export enum JobOfferResult {
  Success,
  OtherService,
  Fail,
}

export enum ApplyMethod {
  Chat = "C",
  Message = "M",
  ALL = "CM",
  None = "",
}

export const applyMethodCoupler = {
  [ApplyMethod.ALL]: "모두지원가능",
  [ApplyMethod.None]: "모두지원가능",
  [ApplyMethod.Chat]: "채팅",
  [ApplyMethod.Message]: "문자",
};

export enum Day {
  Monday,
  TuesDay,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

export enum JobOfferStatus {
  NONE = 0,
  SAVED = 1,
  POSTED = 2,
  ENDED = 3,
}

export const JobOfferStatusName = ["-", "임시저장", "공고중", "공고종료"];

export enum JobOfferType {
  NONE = "-",
  FULL_TIME = "+A1",
  PART_TIME = "+A2",
  ARBEIT = "+B1",
  URGENT = "+B2",
  EMPLOYED = "+A",
  TEMPORARY = "+B",
  ALL = "+",
  INVALID = "",
}

export const JobOfferName = {
  "-": "없음",
  "+A1": "풀타임",
  "+A2": "파트타임",
  "+B1": "단기알바",
  "+B2": "긴급구인",
  "+A": "풀타임/파트타임",
  "+B": "단기/긴급알바",
  "+": "모든 근무 유형",
  "": "미입력",
};
export const BizJobOfferName = {
  "+A": "정규직",
  "+B": "계약직",
};

export enum JobOfferSalaryType {
  NONE = -1,
  MONTHLY = 0,
  ANUAL = 1,
  HOURLY = 2,
  WEEKLY = 3,
  DAILY = 4,
}

export enum JobOfferTaxType {
  NONE = -1,
  PRE_TAX = 0,
  AFTER_TAX = 1,
}

export enum JobOfferLicenseType {
  UNNECESSARY = 0,
  NECESSARY = 1,
  INVALID = -1,
}

export const JobOfferLicenseName = {
  "-1": "미입력",
  "0": "불필요",
  "1": "심평원 등록필요",
};

interface Position {
  x: number;
  y: number;
}

export interface JobPostRegistrationForm {
  workType: JobOfferType; // 구인공고 타입(풀타임,파트타임)
  title: string; // 구인공고 제목
  workDayOfWeek: boolean[]; // 근무요일
  canNegoWorkDayOfWeek: boolean; // 근무요일 협의가능 여부
  workTime: string; // 근무 기간 및 시간 상세
  canNegoWorkTime: boolean; // 근무 기간 및 시간 상세 협의가능 여부
  salaryType: JobOfferSalaryType; // 급여 타입 (연봉, 월급, 시급)
  taxType: JobOfferTaxType; // 세전/세후 여부
  salaryRangeLow: string; // 급여 하한
  salaryRangeHigh: string; // 급여 상한
  canNegoSalary: boolean; // 급여 협의가능 여부
  needLicense: JobOfferLicenseType; // 면허 심평원등록 여부
  message: string; // 상세 설명
  pharmacistCnt?: string; // 약사 수
  employeeCnt?: string; // 직원 수
  welfare: boolean[]; // 복리후생 (#퇴직금#사대 보험#식대 지원▒)
  welfareDetail: string; // 복리후생 추가 입력
  sw: string; // 전산 소프트웨어
  swEtc?: string; // 전산 소프트웨어 -> 기타
  atc: string; // 자동 조제기 (ATC)
  machine?: boolean[]; // 기타 자동화기기
  machineEtc?: string; // 자동화기기 기타 입력
  name: string; // 상호명
  phone: string; // 연락처
  region: string; // 근무지 지역
  address: string; // 근무지 주소
  position: Position; // 위치
  applyMethod: ApplyMethod; // 지원 방법
  phonePersonInCharge: string; // 문자 받을 전화번호
}

export interface JobOfferPharmacyInfo {
  name?: string; // 약국 이름
  region?: string; // 약국 지역
  address?: string; // 약국 주소
  position?: any; // 약국 위치 {x:lat, y:lon}
  phone?: string; // 약국 전화번호
  pharmacistCnt?: number; // 근무 약사 수
  employeeCnt?: number; // 근무 직원 수
  sw?: string; // 약국 SW (PIT3000,유팜 등)
  atc?: string; // 약국 ATC 기종
  machine?: string;
  anonymous?: boolean; // 익명 여부
}

export interface JobOfferInfo {
  id?: number; // [공용] 구인공고 ID
  companyType?: CompanyType; // [NEW][공용] 0:약국, 1:병원, 2:제약사, 3:도매상, 99:기타
  ownerType?: UserType; // [NEW][공용] 0:전체, 1:약문약답(약국), 2:법인(병원,제약사,도매상,기타)
  ownerId?: number; // [약국] 구인공고 게시자 User ID
  ownerBizId?: string; // [NEW][법인] 구인공고 게시자 BizUser ID
  title?: string; // [공용] 구인공고 제목(약국) / 직무명(법인)
  status?: JobOfferStatus; // [공용] 구인공고 상태

  pharmacy?: JobOfferPharmacyInfo; /// @TODO - SHOULD DELETE

  companyName?: string; // [CHANGE][공용] 업체 이름 (<= pharmacy.name)
  companyRegion?: string; // [CHANGE][공용] 업체 지역 (<= pharmacy.region)
  companyAddress?: string; // [CHANGE][공용] 업체 주소 (<= pharmacy.address)
  companyAddressDetail?: string; // [NEW][법인] 업체 상세 주소
  companyPosition?: any; // [CHANGE][공용] 업체 위치 {x:lat, y:lon} (<= pharmacy.position)

  employeeCnt?: number; // [CHANGE][공용] 근무 직원 수 (<= pharmacy.employeeCnt)
  pharmacistCnt?: number; // [CHANGE][약국] 근무 약사 수 (<= pharmacy.pharmacistCnt)
  sw?: string; // [CHANGE][약국] 약국 SW (PIT3000,유팜 등)(<= pharmacy.sw)
  atc?: string; // [CHANGE][약국] 약국 ATC 기종(<= pharmacy.atc)
  machine?: string; // [CHANGE][약국] 약국 자동화 기계(<= pharmacy.machine)

  homepage?: string; // [NEW][법인] 회사 홈페이지
  introduction?: string; // [NEW][법인] 회사 소개
  namePersonInCharge?: string; // [NEW][법인] 채용 담당자 이름
  officeInCharge?: string; // [NEW][법인] 채용 부서 이름
  emailPersonInCharge?: string; // [NEW][법인] 채용 담당자 이메일
  phonePersonInCharge?: string; // [NEW][고용] (약국) 연락받을 전화번호 (법인) 채용 담당자 전화번호

  owner?: UserInfo; // [약국] 구인공고 게시자 사용자 정보
  bizOwner?: BizUserInfo;

  workType?: JobOfferType; // [공용] 구인공고 타입(풀타임,파트타임)
  contractPeriod?: number; // [NEW][법인] 수습기간(정규직), 계약기간(계약직) (개월단위)
  workDayOfWeek?: string; // [약국] 근무요일
  canNegoWorkDayOfWeek?: boolean; // [약국] 근무요일 협의가능 여부
  workTime?: string; // [약국] 근무시간
  canNegoWorkTime?: boolean; // [약국] 근무시간 협의가능 여부
  needLicense?: JobOfferLicenseType; // [약국] 면허 심평원등록 여부
  applyMethod?: ApplyMethod; // [공용] 지원 방법 (M:Message, C:Chat, E:Email, P:Post, H:Homepage)
  applyMethodDetailEmail?: string; // [NEW][병원] 지원방법 이메일 주소
  applyMethodDetailHomepage?: string; // [NEW][병원] 지원방법 홈페이지
  applyMethodDetailPost?: string; // [NEW][병원] 지원방법 주소
  applyMethodDetailPostDetail?: string; // [NEW][병원] 지원방법 상세 주소
  salaryType?: JobOfferSalaryType; // [공용] 급여 타입 (연봉, 월급, 시급)
  taxType?: JobOfferTaxType; // [공용] 세전/세후 여부
  salaryRangeLow?: number; // [공용] 급여 하한
  salaryRangeHigh?: number; // [공용] 급여 상한
  canNegoSalary?: boolean; // [약국] 급여 협의가능 여부
  welfare?: string; // [공용] 복지 ex) #퇴직금#사대 보험#식대 지원▒Freetext(약국) or Freetext(법인)
  career?: number; // [약국-미사용] 경력 요건
  preference?: string; // [공용][약국-미사용] 우대 사항
  message?: string; // [공용] 상세 설명(약국), 주요업무(법인)
  submissionDoc?: string; // [NEW][법인] 제출서류
  submissionDocFormUrl?: string[]; // [NEW][법인] 제출서류 양식파일 Url
  submissionDocForms?: Attachment[]; // [NEW][법인] 제출서류 양식파일 Url
  applyStartAt?: string; // [NEW][법인] 지원기간 시작일
  applyEndAt?: string; // [NEW][법인] 지원기간 종료일 (종료일 X: 채용시까지, 시작일X종료일X:상시)
  hireProcess?: string; // [NEW][법인] 채용 절차
  hireNotice?: string; // [NEW][법인] 채용 결과 안내
  qualification?: string; // [NEW][법인] 자격요건

  imagesURL?: string[]; // [NEW][법인] 채용홍보 이미지 Url
  images?: Attachment[];
  logoImageURL?: string; // [NEW][법인] logo image URL
  chats?: any[]; // [약국] 채팅리스트

  liked?: boolean; // [공용] 좋아요 여부

  viewCnt?: number; // [공용] View count
  likeCnt?: number; // [공용] 좋아요 count
  chatCnt?: number; // [약국] 채팅 count
  smsCnt?: number; // [약국] sms count
  inqueryCnt?: number; // [약국] 문의 count
  shareCnt?: number; // [NEW][공용] 공유 count
  passCnt?: number; // [약국] 합격자 수
  initCandidateCnt?: number; // [약국] 등록시점 근처 구직알림 신청자 수

  distance?: number; // [공용] 특정 지점 기준 거리

  editable?: boolean; // [공용] 편집가능 여부
  loading?: boolean; // [공용] 로딩중 여부

  createdAt?: string; // [공용] 생성일시
  startedAt?: string; // [공용] 시작일시 (미사용)
  raisedAt?: string; // [약국] 최근 끌올 일시
  endedAt?: string; // [공용] 종료일시
  modifiedAt?: string; // [공용] 최근 수정일시
  lastJobpostAt?: string; // [약국] 게시자 마지막 구인공고 방문 시점

  etcJson?: string;
  search?: string;

  afterTaxSalaryType?: JobOfferSalaryType; // 세후 급여 타입 (연봉, 월급, 시급)
  afterTaxSalaryRangeLow?: number; // 세후 급여 하한
  afterTaxSalaryRangeHigh?: number; // 세후 급여 상한

  applications?: JobApplyInfo[]; // 지원자

  applied?: boolean; // 지원여부

  applyCnt?: number; // 지원자 수

  order?: OrderDetail[];

  orderPayAmount?: number;
  orderCoupon?: string;
  orderCouponPrice?: number;
  orderName?: string;
}
export const EMPTY_JOBPOST_OFFER: JobOfferInfo = {
  id: 0,
  ownerId: 0,
  title: "",
  status: JobOfferStatus.NONE,
  pharmacy: {
    name: "",
    region: "",
    address: "",
    position: null, //{x, y}
    phone: "",
    pharmacistCnt: 0,
    employeeCnt: 0,
    sw: "",
    atc: "",
    anonymous: false,
  },
  workType: JobOfferType.NONE,
  workDayOfWeek: "",
  canNegoWorkDayOfWeek: false,
  workTime: "",
  canNegoWorkTime: false,

  needLicense: JobOfferLicenseType.INVALID,
  salaryType: JobOfferSalaryType.NONE,
  taxType: JobOfferTaxType.NONE,
  salaryRangeLow: 0,
  salaryRangeHigh: 0,
  afterTaxSalaryType: JobOfferSalaryType.NONE,
  afterTaxSalaryRangeLow: 0,
  afterTaxSalaryRangeHigh: 0,
  welfare: "",

  career: 0,
  preference: "",
  message: "",
};

export enum OrderPayMethod {
  NONE = 0, // 준비중
  CARD = 1, // 카드결재
  BANK = 2, // 계좌이체
  VBANK = 3, // 가상계좌
  PHONE = 4, // 휴대폰소액결재
}

export interface JobOfferNotificationOrderInfo {
  id?: number;
  offerId?: number;
  userId?: number;
  notiCount?: number;
  payAmount?: number;
  includeDuplicate?: boolean;
  distance?: number;

  successCount?: number;
  failCount?: number;
  refundAmount?: number;

  payMethod?: OrderPayMethod; // 결제방법
  paidAmount?: number; // 결제금액
  payStatus?: string; // 결제상태
  payApplyNumber?: string; // 카드사 승인번호
  payRecieptUrl?: string; // 거래매출전표 URL
  payMerchantId?: string; // 아임포트 결제 id
  impUid?: string; // 아임포트 결제 고유번호
  canceledAmount?: number; // 취소금액
  couponId?: number;

  createdAt?: any;
  paidAt?: any;
  users?: number[];
}

export interface JobOfferPremiumOrderInfo {
  id?: number;
  offerId?: number;
  userId?: number;
  days?: number;
  payAmount?: number;

  payMethod?: OrderPayMethod; // 결제방법
  paidAmount?: number; // 결제금액
  payStatus?: string; // 결제상태
  payApplyNumber?: string; // 카드사 승인번호
  payRecieptUrl?: string; // 거래매출전표 URL
  payMerchantId?: string; // 아임포트 결제 id
  impUid?: string; // 아임포트 결제 고유번호
  canceledAmount?: number; // 취소금액
  couponId?: number;

  createdAt?: any;
  paidAt?: any;
  users?: number[];
}

export enum JobApplyStatus {
  // LIKED = -1,
  NONE = 0,
  SAVED = 5,
  CANCELED = 10,
  APPLIED = 30,
  CHATTING = 31,
  CHECKED = 40,
  INTERVIEWING = 50,
  FAILED = 60,
  PASSED = 70,
  PASS_CHECKED = 80,
}

export interface JobApplyCareerInfo {
  id?: number;
  from?: string;
  to?: string;
  months?: number;
  detail?: string;
}

export interface JobApplyInfo {
  id?: number;
  offerId?: number;
  applicantId?: number; // userId
  status?: JobApplyStatus;

  name?: string;
  phone?: string;
  email?: string;
  license?: string;
  profileUrl?: string;
  region?: string;
  position?: any;
  careers?: JobApplyCareerInfo[];
  educations?: JobApplyCareerInfo[];

  introduction?: string;
  inquiry?: string;

  cancelMessage?: string;
  editable?: boolean;
  loading?: boolean;

  offer?: JobOfferInfo;
  distance?: number;

  createdAt?: string;
  likedAt?: string;
  inqueriedAt?: string;
  appliedAt?: string;
  checkedAt?: string;
  interviewedAt?: string;
  endedAt?: string;
  canceledAt?: string;
  modifiedAt?: string;
  lastJobpostAt?: string;
  lastMessagedAt?: string;

  chatUrl?: string;
  actionLiked?: boolean;
  actionInqueried?: boolean;
}

export function getSalaryTypeString(info: JobOfferInfo) {
  let type = "월급 ";
  if (info.salaryType == JobOfferSalaryType.ANUAL) type = "연봉 ";
  if (info.salaryType == JobOfferSalaryType.HOURLY) type = "시급 ";

  let tax = " (세전)";
  if (info.taxType == JobOfferTaxType.AFTER_TAX) tax = " (세후)";

  return type + tax;
}

export function getPayString(info: JobOfferInfo | JobPostRegistrationForm) {
  if (!info || !info.salaryRangeLow || !info.salaryRangeHigh) return "";

  let type = "월급 ";

  if (info.salaryType == JobOfferSalaryType.ANUAL) type = "연봉 ";
  if (info.salaryType == JobOfferSalaryType.HOURLY) type = "시급 ";
  if (info.salaryType == JobOfferSalaryType.WEEKLY) type = "주급 ";
  if (info.salaryType == JobOfferSalaryType.DAILY) type = "일급 ";

  let range;
  if (info.salaryRangeLow == info.salaryRangeHigh)
    range = info.salaryRangeLow.toString() + "만원";
  else
    range =
      info.salaryRangeLow.toString() +
      "~" +
      info.salaryRangeHigh.toString() +
      "만원";

  let tax = " (세전)";
  if (info.taxType == JobOfferTaxType.AFTER_TAX) tax = " (세후)";

  return type + range + tax;
}

export function getAfterTaxPayString(info: JobOfferInfo) {
  if (
    !info.afterTaxSalaryType ||
    info.afterTaxSalaryType == JobOfferSalaryType.NONE
  ) {
    let type = "월급 ";
    if (info.salaryType == JobOfferSalaryType.ANUAL) type = "연봉 ";
    if (info.salaryType == JobOfferSalaryType.HOURLY) type = "시급 ";
    if (info.salaryType == JobOfferSalaryType.WEEKLY) type = "주급 ";
    if (info.salaryType == JobOfferSalaryType.DAILY) type = "일급 ";

    let taxOption: JobOfferTaxType = info.taxType;
    let salaryOption: JobOfferSalaryType = info.salaryType;

    let salaryRangeLow = SalaryUtil.getAfterTaxMonthlySalary({
      taxOption,
      salaryOption,
      salary: info.salaryRangeLow,
    });
    let salaryRangeHigh = SalaryUtil.getAfterTaxMonthlySalary({
      taxOption,
      salaryOption,
      salary: info.salaryRangeHigh,
    });

    let range;
    if (salaryRangeLow == salaryRangeHigh)
      range = salaryRangeLow.toString() + "만원";
    else
      range =
        salaryRangeLow.toString() + "~" + salaryRangeHigh.toString() + "만원";

    let tax = " (세후)";
    if (
      info.salaryType == JobOfferSalaryType.HOURLY &&
      info.taxType == JobOfferTaxType.PRE_TAX
    )
      tax = " (세전)";
    log(
      LogLevel.UI_DATA_LOAD,
      "getAfterTaxPayString: ",
      info,
      type,
      range,
      tax
    );

    return type + range + tax;
  } else {
    let type = "월급 ";
    let tax = " (세후)";
    if (info.afterTaxSalaryType == JobOfferSalaryType.HOURLY) {
      type = "시급 ";
      if (info.taxType == JobOfferTaxType.PRE_TAX) tax = " (세전)";
    }

    let range;
    if (info.afterTaxSalaryRangeLow == info.afterTaxSalaryRangeHigh)
      range = info.afterTaxSalaryRangeLow.toString() + "만원";
    else
      range =
        info.afterTaxSalaryRangeLow.toString() +
        "~" +
        info.afterTaxSalaryRangeHigh.toString() +
        "만원";

    return type + range + tax;
  }
}

export function getAfterTaxPaySimpleString(info: JobOfferInfo) {
  if (info.afterTaxSalaryType == JobOfferSalaryType.NONE) {
    let taxOption: JobOfferTaxType = info.taxType;
    let salaryOption: JobOfferSalaryType = info.salaryType;

    let salaryRangeLow = SalaryUtil.getAfterTaxMonthlySalary({
      taxOption,
      salaryOption,
      salary: info.salaryRangeLow,
    });
    let salaryRangeHigh = SalaryUtil.getAfterTaxMonthlySalary({
      taxOption,
      salaryOption,
      salary: info.salaryRangeHigh,
    });

    let range;
    if (salaryRangeLow == salaryRangeHigh)
      range = salaryRangeLow.toString() + " 만원";
    else
      range =
        salaryRangeLow.toString() +
        " 만원 ~ " +
        salaryRangeHigh.toString() +
        " 만원";

    return range;
  } else {
    let range;
    if (info.afterTaxSalaryRangeLow == info.afterTaxSalaryRangeHigh)
      range = info.afterTaxSalaryRangeLow.toString() + " 만원";
    else
      range =
        info.afterTaxSalaryRangeLow.toString() +
        " 만원 ~ " +
        info.afterTaxSalaryRangeHigh.toString() +
        " 만원";

    return range;
  }
}
