import { getGlobal, GlobalKey } from "./GlobalUtil";
import { log, LogLevel } from "./LogUtil";
import { MY_ADDRESS } from "./../config.json";

export default class StringUtil {
  static hashcode(str: string): number {
    return str
      .split("")
      .reduce(
        (prevHash, currVal) =>
          ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
        0
      );
  }

  static commafy(num) {
    if (!num) return "0";
    return num.toLocaleString();
  }

  static addressSearchTextValidate(address: string) {
    if (address.length > 1) {
      //특수문자 제거
      var expText = /[%=><]/;
      if (expText.test(address) == true) {
        address = address.split(expText).join("");
        return false;
      }

      //특정문자열(sql예약어의 앞뒤공백포함) 제거
      var sqlArray = new Array(
        //sql 예약어
        "OR",
        "SELECT",
        "INSERT",
        "DELETE",
        "UPDATE",
        "CREATE",
        "DROP",
        "EXEC",
        "UNION",
        "FETCH",
        "DECLARE",
        "TRUNCATE"
      );

      var regex;
      for (var i = 0; i < sqlArray.length; i++) {
        regex = new RegExp(sqlArray[i], "gi");

        if (regex.test(address)) {
          // alert("\"" + sqlArray[i]+"\"와(과) 같은 특정문자로 검색할 수 없습니다.");
          address = address.replace(regex, "");
          return false;
        }
      }

      return true;
    }

    return false;
  }

  static convertFilePath(address: string) {
    log(LogLevel.UI_EVENT, "convertFilePath:", address);

    if (!address) return "";
    if (address.startsWith("&&&"))
      return (address = address.replace("&&&", MY_ADDRESS));

    let filePath = getGlobal(GlobalKey.FILE_PATH, true);
    if (!filePath) return address;
    let keys = Object.keys(filePath);
    for (let i = 0; i < keys.length; i++) {
      if (address.startsWith(keys[i])) {
        address = address.replace(keys[i], filePath[keys[i]]);
        break;
      }
    }

    return address;
  }

  static convertFilePathFull(longText: string) {
    let filePath = getGlobal(GlobalKey.FILE_PATH, true);
    if (!filePath) return longText;
    let keys = Object.keys(filePath);
    for (let i = 0; i < keys.length; i++) {
      longText = longText.replace(new RegExp(keys[i], "gi"), filePath[keys[i]]);
    }

    return longText;
  }

  static encodeFilePathFull(longText: string) {
    let filePath = getGlobal(GlobalKey.FILE_PATH, true);
    if (!filePath) return longText;
    let keys = Object.keys(filePath);
    for (let i = 0; i < keys.length; i++) {
      longText = longText.replace(new RegExp(filePath[keys[i]], "gi"), keys[i]);
    }

    return longText;
  }

  static isEncodeFilePath(address: string) {
    let filePath = getGlobal(GlobalKey.FILE_PATH, true);
    if (!filePath) return false;
    let keys = Object.keys(filePath);
    for (let i = 0; i < keys.length; i++) {
      if (address.startsWith(keys[i])) {
        return true;
      }
    }
  }

  static createStringFromTemplate(
    template,
    variables,
    reg = TEMPLATE_REG_SHARP_BRACKET
  ): string {
    return template.replace(new RegExp(reg, "g"), function (_unused, varName) {
      const varNames = varName.split(".");
      let value = variables;
      for (let i = 0; i < varNames.length; i++) {
        if (value[varNames[i]] || value[varNames[i]] == "") {
          value = value[varNames[i]];
          // console.log("createStringFromTemplate varNames:", varNames, i, value);
        } else break;
      }
      // console.log("createStringFromTemplate final value:", value);

      if (value || value == "") return value;
      return "#{" + varName + "}";
    });
  }

  static isNumericString(str) {
    return /^\d+[\.]?[\d]*$/.test(str);
  }
}

export const TEMPLATE_REG_SHARP_BRACKET = "\\#{([^\\{]+)\\}";
export const TEMPLATE_REG_DOUBLE_BRACKET = "\\{\\{([^\\{]+)\\}\\}";
